import { DatePicker, Popover, Select } from 'antd';
// import moment, { Moment, unitOfTime } from 'moment';
import { useMemo } from 'react';
import styled from 'styled-components';
import { SwapRightOutlined } from '@ant-design/icons';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
// import 'moment-timezone';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

type ValuePiece = Date | null | Dayjs;

export type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  // timezone(timezone: any): unknown;
  custom: boolean;
  startAt: number;
  endAt: number;
  disabled?: boolean;
  onChange: (e: any) => void;
  onChangeCustomTime: (e: Dayjs, type: 'start' | 'end') => void;
  onChangeCustomDateRange: (date: Value) => void;
  // constanta: number;
  // timezone: string;
}

const { RangePicker } = DatePicker;
const dateTimeFormat = 'DD/MM/YYYY HH:mm';
const dateFormat = 'YYYY-MM-DD';
const systemTimezone = (new Date().getTimezoneOffset() / 60) * -1;
const sysOffsetString = String(7 * 60);
const miliseconds = 1000;

const DateRangePicker: React.FC<IProps> = (props) => {
  const hour = useMemo(() => {
    return [...Array(24).keys()].map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  const minute = useMemo(() => {
    return [...Array(60).keys()].map((item) => ({
      label: item,
      value: item,
    }));
  }, []);

  function onChangeTime(
    value: number,
    type: 'start' | 'end',
    timeType: 'hour' | 'minute'
  ) {
    const current = dayjs(
      type === 'start' ? props.startAt * 1000 : props.endAt * 1000
    ).set(timeType, value);
    props.onChangeCustomTime(current, type);
  }

  const range = useMemo(() => {
    const start = {
      dateTimeFormat: dayjs(props.startAt * miliseconds).format(dateTimeFormat),
      dateFormat: dayjs(props.startAt * miliseconds).format(dateFormat),
      unix: props.startAt,
    };
    const end = {
      dateTimeFormat: dayjs(props.endAt * miliseconds).format(dateTimeFormat),
      dateFormat: dayjs(props.endAt * miliseconds).format(dateFormat),
      unix: props.endAt,
    };
    return {
      start,
      end,
    };
  }, [props.startAt, props.endAt]);

  if (!props.custom) {
    return (
      <RangePicker
        style={{ width: '100%' }}
        disabled={props.disabled}
        showTime
        value={[
          dayjs(props.startAt, dateTimeFormat),
          dayjs(props.endAt, dateTimeFormat),
        ]}
        format={dateTimeFormat}
        onChange={props.onChange}
      />
    );
  }

  function onChangeCalendar(value: Value) {
    if (value) {
      props.onChangeCustomDateRange(value);
    }
  }

  const content = () => {
    return (
      <>
        <div style={{ width: 250 }}>
          <Calendar
            className='custom-calendar'
            selectRange
            returnValue='range'
            onChange={onChangeCalendar}
            value={[range.start.dateFormat, range.end.dateFormat]}
          />
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <div style={{ flex: 1 }}>
            <div>Start time</div>
            <WrapperTime>
              <div>
                <Select
                  size='small'
                  options={hour}
                  // value={moment(range.start.raw).tz(props.timezone).hour()}
                  value={dayjs(range.start.unix * miliseconds).hour()}
                  onSelect={(e) => onChangeTime(e, 'start', 'hour')}
                />
              </div>
              <span>:</span>
              <div>
                <Select
                  size='small'
                  options={minute}
                  // value={moment(range.start.raw).tz(props.timezone).minute()}
                  value={dayjs(range.start.unix * miliseconds).minute()}
                  onSelect={(e) => onChangeTime(e, 'start', 'minute')}
                />
              </div>
            </WrapperTime>
          </div>
          <div style={{ flex: 1 }}>
            <div>End time</div>
            <WrapperTime>
              <div>
                <Select
                  size='small'
                  options={hour}
                  // value={moment(range.end.raw).tz(props.timezone).hour()}
                  value={dayjs(range.end.unix * miliseconds).hour()}
                  onSelect={(e) => onChangeTime(e, 'end', 'hour')}
                />
              </div>
              <span>:</span>
              <div>
                <Select
                  size='small'
                  options={minute}
                  // value={moment(range.end.raw).tz(props.timezone).minute()}
                  value={dayjs(range.end.unix * miliseconds).minute()}
                  onSelect={(e) => onChangeTime(e, 'end', 'minute')}
                />
              </div>
            </WrapperTime>
          </div>
        </div>
      </>
    );
  };

  return (
    <ContainerRangePicker>
      <Popover
        popupVisible={props.disabled}
        placement='bottom'
        content={() => content()}
        trigger='click'
      >
        <CalendarInput disabled={props.disabled}>
          {/* {moment(range.start.raw).tz(props.timezone).format(dateTimeFormat)} */}
          {range.start.dateTimeFormat}
          <SwapRightOutlined />
          {/* {moment(range.end.raw).tz(props.timezone).format(dateTimeFormat)} */}
          {range.end.dateTimeFormat}
        </CalendarInput>
      </Popover>
    </ContainerRangePicker>
  );
};

const WrapperTime = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
  gap: 5px;
`;

const ContainerRangePicker = styled.div`
  width: 100%;
`;

interface ICalendarInput {
  disabled?: boolean;
}

const CalendarInput = styled.div<ICalendarInput>`
  border: 1px solid #d9d9d9;
  padding: 0 11px;
  height: 32px;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? 'lightgray' : 'white')};
  color: ${(props) => (props.disabled ? 'gray' : 'black')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
`;

export default DateRangePicker;
