import moment from "moment";

enum VesselStatus {
  ONLINE = "Online",
  OFFLINE = "Offline",
  WAITING_DATA = "Waiting Data",
}

const getVesselStatus = (lastDataGPS: any, dataSource: "GSM" | "SATELLITE") => {
  let dataStatus: VesselStatus = VesselStatus.OFFLINE;

  const currentTime = moment().unix();
  const lastDataTime = lastDataGPS.hasOwnProperty("timestamp")
    ? lastDataGPS.timestamp
    : null;

  if (dataSource === "SATELLITE") {
    if (currentTime - lastDataTime <= 86400) {
      // 86400 s = 24 jam
      if (currentTime - lastDataTime > 7200) {
        // 7200 s = 2 jam
        dataStatus = VesselStatus.WAITING_DATA;
      } else {
        dataStatus = VesselStatus.ONLINE;
      }
    } else {
      dataStatus = VesselStatus.OFFLINE;
    }
  } else {
    if (currentTime - lastDataTime <= 86400) {
      // 86400 s = 24 jam
      if (currentTime - lastDataTime > 600) {
        // 600 s = 10 menit
        dataStatus = VesselStatus.WAITING_DATA;
      } else {
        dataStatus = VesselStatus.ONLINE;
      }
    } else {
      dataStatus = VesselStatus.OFFLINE;
    }
  }

  return dataStatus;
};

const getStatusEngine = (
  currentLastDataRpm: any,
  dataSource: "GSM" | "SATELLITE"
) => {
  let isEngineOn: boolean = false;

  const currentTime = moment().unix();
  const lastDataTime = currentLastDataRpm?.timestamp;
  const isNotMoreThanTwoHours = currentTime - lastDataTime <= 7200;
  const isNotMoreThanTenMinutes = currentTime - lastDataTime <= 600;

  console.log(`lastDataTime: ${lastDataTime}`);

  if (dataSource === "SATELLITE") {
    isEngineOn = isNotMoreThanTwoHours && currentLastDataRpm?.rpm > 0;
  } else {
    isEngineOn = isNotMoreThanTenMinutes && currentLastDataRpm?.rpm > 0;
  }

  return isEngineOn;
};

export { VesselStatus, getVesselStatus, getStatusEngine };
