import { UserProperties } from '../../../services/openapi';
import { AssetProperties } from '../../asset/types/asset.type';
import { CompanyProperties } from '../../company/types/company.type';

//report email template
export type ReportEmailTemplateProperties = {
  templateId?: string;
  templateName: string;
  platform?: string;
  subject?: string;
  externalId?: string;
  htmlContent: any;
  designContent: any;
  status: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
};

export const initialReportEmailTemplate: ReportEmailTemplateProperties = {
  templateId: '',
  templateName: '',
  platform: '',
  subject: '',
  externalId: '',
  htmlContent: '',
  designContent: '',
  status: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export interface ICreateReportTemplate {
  templateId: string;
  templateName: string;
  platform: string;
  subject: string;
  externalId: string;
  htmlContent: string;
  designContent: string;
  status: string;
}

// report email receiver
export type ReportEmailReceiverProperties = {
  reportEmailReceiverId: string;
  userId: string;
  companyId: string;
  status: string;
  createdByUserId: string;
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  user?: UserProperties;
  company?: CompanyProperties;
};

export const initialReportEmailReceiver: ReportEmailReceiverProperties = {
  reportEmailReceiverId: '',
  userId: '',
  companyId: '',
  status: '',
  createdByUserId: '',
};

export interface ICreateReportEmailReceiver {
  userId: string;
  companyId: string;
  status: string;
}

//report email target asset
export type ReportEmailTargetAssetProperties = {
  reportEmailTargetAssetId: string;
  templateId: string;
  companyId: string;
  assetId: string;
  sendingAt: Date;
  timeFrom: string;
  timeTo: string;
  frequency: string;
  dayOfWeek?: string;
  dateOfMonth?: string;
  dataThresholdMin?: string;
  scheduleAt: string;
  cronJobName: string;
  status: string;
  createdByUserId?: string;
  userCreatorName?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  asset?: AssetProperties;
  company?: CompanyProperties;
  reportEmailTarget?: any;
};

export const initialReportEmailTargetAsset: ReportEmailTargetAssetProperties = {
  reportEmailTargetAssetId: '',
  templateId: '',
  companyId: '',
  assetId: '',
  sendingAt: new Date(),
  timeFrom: '',
  timeTo: '',
  frequency: '',
  dayOfWeek: '',
  dateOfMonth: '',
  dataThresholdMin: '',
  scheduleAt: '',
  cronJobName: '',
  status: '',
  createdByUserId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
};

export interface ICreateReportEmailTargetAsset {
  reportEmailTargetAssetId: string;
  templateId: string;
  companyId: string;
  assetId: string;
  sendingAt: Date;
  timeFrom: string;
  timeTo: string;
  frequency: string;
  dayOfWeek?: string;
  dateOfMonth?: string;
  dataThresholdMin?: string;
  status: string;
  createdByUserId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ReportEmailTargetProperties {
  reportEmailTargetId: string;
  reportEmailTargetAssetId: string;
  userId?: string;
  email?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  reportEmailTargetAsset?: ReportEmailTargetAssetProperties;
  user?: UserProperties;
}

export enum EEmailSubjectParams {
  asset_name = 'asset_name',
  report_date_range = 'report_date_range',
  report_date_only = 'report_date_only',
}

export enum EEmailBodyParams {
  general_asset_name = 'general_asset_name',
  general_report_generated = 'general_report_generated',
  general_report_date_range = 'general_report_date_range',
  gps_total_cruise = 'gps_total_cruise',
  gps_average_speed = 'gps_average_speed',
  gps_distance = 'gps_distance',
  total_engine_fuel_cons = 'total_engine_fuel_cons',
  total_ae_fuel_cons = 'total_ae_fuel_cons',
  total_fuel_cons = 'total_fuel_cons',
  ae_1running_time = 'ae_1running_time',
  ae_2running_time = 'ae_2running_time',
  ae_3running_time = 'ae_3running_time',
  ae_1fuel_cons = 'ae_1fuel_cons',
  ae_2fuel_cons = 'ae_2fuel_cons',
  ae_3fuel_cons = 'ae_3fuel_cons',
  engine_port_fuel_cons = 'engine_port_fuel_cons',
  engine_starboard_fuel_cons = 'engine_starboard_fuel_cons',
  engine_port_running_time = 'engine_port_running_time',
  engine_starboard_running_time = 'engine_starboard_running_time',
  engine_port_rpm = 'engine_port_rpm',
  engine_starboard_rpm = 'engine_starboard_rpm',
}
