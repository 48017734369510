import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { ILogReport } from '../../types/report-log.type';
import styled from 'styled-components';
import useReportLog from '../../../../hooks/useReportLog';
import { calculateGpsSummary, latToDMS, longToDMS } from '../helperFunction';
import dayjs from 'dayjs';
import {
  EFormatCurrentDate,
  formatCurrentDate,
} from '../../../../helpers/formatCurrentDate';

interface IProps {}

const VesselTrackingStatic: React.FC<IProps> = () => {
  const { loadingLog, logs } = useReportLog();
  const [mapImages, setMapImages] = useState({
    mapLeft: undefined,
    mapRight: undefined,
  });

  const currentPath = useMemo(() => {
    let res: any[] = [];
    if (logs && logs.gps) {
      Object.values(logs.gps).forEach((item) => {
        if (item.latitude && item.longitude) {
          res.push([item.latitude, item.longitude]);
        }
      });
    }

    return res;
  }, [logs]);

  const summary = useMemo(() => {
    let firstData = {
      position: '-',
      time: '-',
    };
    let lastData = {
      position: '-',
      time: '-',
    };
    if (logs.gps) {
      Object.keys(logs.gps).forEach((key, idx) => {
        if (idx === 0) {
          firstData = {
            position: `${latToDMS(logs.gps![key].latitude)}, ${longToDMS(
              logs.gps![key].longitude
            )}`,
            time: formatCurrentDate(
              Number(key) * 1000,
              EFormatCurrentDate.DEFAULT_SHORT_MONTH
            ),
          };
        }

        if (idx === Object.keys(logs.gps!).length - 1) {
          lastData = {
            position: `${latToDMS(logs.gps![key].latitude)}, ${longToDMS(
              logs.gps![key].longitude
            )}`,
            time: formatCurrentDate(
              Number(key) * 1000,
              EFormatCurrentDate.DEFAULT_SHORT_MONTH
            ),
          };
        }
      });
    }
    let data = {
      firstData,
      lastData,
      totalCruise: '0',
      totalDistance: '0',
      avgSpeed: '0',
    };
    if (logs && logs.gps) {
      const res = calculateGpsSummary(logs);
      data = {
        ...data,
        totalCruise: res.totalCruise as string,
        totalDistance: res.totalDistance as string,
        avgSpeed: res.avgSpeed as string,
      };
    }

    return data;
  }, [logs]);

  async function generateImage(data: {
    withoutPath?: boolean;
    currentPath: any;
  }) {
    try {
      const zoom = data.withoutPath ? 8 : 11;
      const lat = data.currentPath[data.currentPath.length - 1][0];
      const lng = data.currentPath[data.currentPath.length - 1][1];
      const path = `color:0xff0000ff|weight:5|${data.currentPath.join('|')}`;
      const constructUrl = data.withoutPath
        ? `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=535x300&markers=color:red%7C${lat},${lng}&maptype=satellite&key=${process.env.REACT_APP_GMAPS_API_KEY}`
        : `https://maps.googleapis.com/maps/api/staticmap?size=535x300&markers=color:red%7C${lat},${lng}${
            data.currentPath && data.currentPath.length > 0
              ? `&path=color:yellow|weight:3|${path}`
              : `&zoom=${zoom}`
          }&maptype=satellite&key=${
            process.env.REACT_APP_GMAPS_API_KEY
          }&sensor=false`;
      const res = await axios({
        method: 'post',
        url: `https://beta.ramus.id/api-signature/generate-signature`,
        data: {
          url: constructUrl,
          privateKey: process.env.REACT_APP_GMAPS_PRIVATE_KEY,
        },
      });

      return res.data.result;
    } catch (error: any) {
      console.error('failed generate static map', error);
    }
  }

  useEffect(() => {
    const renderMapImage = async () => {
      const mapLeft = await generateImage({ currentPath });
      const mapRight = await generateImage({ currentPath, withoutPath: true });

      setMapImages({
        mapLeft,
        mapRight,
      });
    };

    if (!loadingLog && currentPath.length > 0) {
      renderMapImage();
    }
  }, [currentPath && loadingLog]);

  const tableColumnsHorizontal = ['', 'Position', 'Time'];
  const tableColumnsVertical = [
    'Start',
    'End',
    'Average Speed',
    'Total Cruise',
  ];

  return (
    <>
      <StaticMapContainer>
        <img
          alt='map-left'
          style={{ height: 300 }}
          id='map-image'
          src={mapImages.mapLeft}
        />
        <img
          alt='map-right'
          style={{ height: 300 }}
          id='map-image'
          src={mapImages.mapRight}
        />
      </StaticMapContainer>

      <table className='table-app'>
        <tbody>
          <tr>
            {tableColumnsHorizontal.map((item, idx) => (
              <td key={idx}>{item}</td>
            ))}
          </tr>
          <tr>
            <td>Start</td>
            <td>{summary.firstData.position}</td>
            <td>{summary.firstData.time}</td>
          </tr>
          <tr>
            <td>End</td>
            <td>{summary.lastData.position}</td>
            <td>{summary.lastData.time}</td>
          </tr>
          <tr>
            <td>Average Speed</td>
            <td colSpan={2}>{summary.avgSpeed}</td>
          </tr>
          <tr>
            <td>Total Cruise</td>
            <td colSpan={2}>{summary.totalCruise}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const StaticMapContainer = styled.div`
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
`;

export default VesselTrackingStatic;
