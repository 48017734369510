import { useEffect, useMemo, useState } from 'react';
import {
  convertData,
  generateTimestampData,
  latToDMS,
  longToDMS,
  toHHMMSS,
} from './helperFunction';
import { generateReportLogColumns } from './tableColumn';
import useGroup from '../../../zustand/useGroup';
import useReportLog from '../../../hooks/useReportLog';
import { Button, Col, Divider, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { SettingFilled } from '@ant-design/icons';
import React from 'react';
import TableConfiguration from './tableConfiguration';
import ReportSummary from './summary';
import { EReportDataInterval } from '../types/report-log.type';
import ImportDataReport from './importDataReport';
import ExportDataReport from './exportDataReport';
import usePermission from '../../../hooks/usePermission';
import styled from 'styled-components';

const windowHeight = window.innerHeight;
export interface RowData {
  [key: string]: string | number | null;
}

const TableReportLog = () => {
  const { isUserHasPermission } = usePermission();
  const { filter } = useGroup();
  const {
    setChecklistColumn,
    checklistColumn,
    loadingLog: loading,
    logs,
    setShowReportTableConfig,
  } = useReportLog();
  const [columns, setColumns] = useState<any>([]);

  useEffect(() => {
    if (logs && Object.keys(logs).length) {
      const res = generateReportLogColumns(logs).map((item: any) => {
        if (item.children) {
          return {
            ...item,
            hidden: false,
            children: item.children.map((el: any) => {
              return {
                ...el,
                hidden: false,
              };
            }),
          };
        } else {
          return {
            ...item,
            hidden: false,
          };
        }
      });

      setColumns(res);
    }
  }, [logs]);

  useEffect(() => {
    if (columns && columns.length) {
      const checklist: string[] = [];

      columns.forEach((item: any) => {
        if (item.children) {
          checklist.push(item.key);
          item.children.forEach((child: any) => {
            checklist.push(child.key);
          });
        } else {
          checklist.push(item.key);
        }
      });

      setChecklistColumn(checklist);
    }
  }, [columns]);

  const columnsFiltered = useMemo(() => {
    const res = columns.filter((item: any) => {
      if (checklistColumn.includes(item.key)) {
        return item;
      } else if (item.children) {
        const isParentExist = checklistColumn.find(
          (columnKey) => item.key === columnKey
        );
        if (isParentExist) {
          return {
            ...item,
            children: item.children.filter((child: any) =>
              checklistColumn.includes(child.key)
            ),
          };
        }
      }
    });
    return res;
  }, [columns, checklistColumn]);

  const dataSource = useMemo(() => {
    if (logs && Object.keys(logs).length) {
      let intervalInSecond = 0;

      if (filter.interval === EReportDataInterval.minute) {
        intervalInSecond = 60;
      } else if (filter.interval === EReportDataInterval.hour) {
        intervalInSecond = 60 * 60;
      } else {
        intervalInSecond = 60 * 60 * 24;
      }

      const containerTimestamp = generateTimestampData({
        startTime: filter.startAt,
        endTime: filter.endAt,
        interval: filter.interval,
        intervalInSecond,
      });

      const results = containerTimestamp.map((timestamp) => {
        let fm;
        let rpm;
        let ae;
        let gps;
        if (logs.flowmeter) {
          const foundKey = Object.keys(logs.flowmeter).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            fm = logs.flowmeter[foundKey];
          }
        }
        if (logs.rpm) {
          const foundKey = Object.keys(logs.rpm).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            rpm = logs.rpm[foundKey];
          }
        }
        if (logs.ae) {
          const foundKey = Object.keys(logs.ae).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            ae = logs.ae[foundKey];
          }
        }
        if (logs.gps) {
          const foundKey = Object.keys(logs.gps).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            gps = logs.gps[foundKey];
          }
        }

        return {
          timestamp,
          dateTime: dayjs(timestamp * 1000).format('DD MMM YYYY HH:mm'),
          gps_coordinate:
            gps?.latitude && gps?.longitude
              ? `${latToDMS(gps?.latitude)}, ${longToDMS(gps?.longitude)}`
              : '-',
          gps_latitude: gps?.latitude,
          gps_longitude: gps?.longitude,
          gps_heading: convertData(gps?.heading),
          gps_speed: convertData(gps?.speed),
          gps_distance: convertData(gps?.distance),
          port_Rpm: convertData(rpm?.port?.rpm),
          port_RunningTime: toHHMMSS(rpm?.port?.runningTime),
          port_FuelCons: convertData(fm?.deviceCalculation?.portIn?.fuelCons),
          port_VolumeTotal: convertData(
            fm?.deviceCalculation?.portIn?.volumeFuelCons
          ),
          port_InFlow: convertData(fm?.portIn?.volumeFlowrate),
          port_Temperature: convertData(fm?.portIn?.temperature),
          port_InDensity: convertData(fm?.portIn?.density),
          starboard_Rpm: convertData(rpm?.starboard?.rpm),
          starboard_RunningTime: toHHMMSS(rpm?.starboard?.runningTime),
          starboard_FuelCons: convertData(
            fm?.deviceCalculation?.starboardIn?.fuelCons
          ),
          starboard_VolumeTotal: convertData(
            fm?.deviceCalculation?.starboardIn?.volumeFuelCons
          ),
          starboard_InFlow: convertData(fm?.starboardIn?.volumeFlowrate),
          starboard_Temperature: convertData(fm?.starboardIn?.temperature),
          starboard_InDensity: convertData(fm?.starboardIn?.density),
          ae1_RunningTime: toHHMMSS(ae?.ae1?.runningTime),
          ae1_FuelConsumption: convertData(ae?.ae1?.fuelConsumption),
          ae2_RunningTime: toHHMMSS(ae?.ae2?.runningTime),
          ae2_FuelConsumption: convertData(ae?.ae2?.fuelConsumption),
          ae3_RunningTime: toHHMMSS(ae?.ae3?.runningTime),
          ae3_FuelConsumption: convertData(ae?.ae3?.fuelConsumption),
        };
      });

      return results;
    }
  }, [logs]);

  return (
    <React.Fragment>
      <ReportSummary defaultOpenBody={['body3', 'body4']} />

      <Divider />

      <ContainerFilterTable>
        <Wrapper>
          <TableConfiguration columns={columns} />
        </Wrapper>
        <Wrapper>
          <ExportDataReport
            columnsFiltered={columnsFiltered}
            dataSource={dataSource || []}
            loading={loading}
          />
          {isUserHasPermission(['IMPORT_DATA_LOG' + '.CREATE']) && (
            <ImportDataReport />
          )}
        </Wrapper>
      </ContainerFilterTable>

      <Table
        loading={loading}
        className='table-report-log'
        columns={columnsFiltered}
        dataSource={dataSource}
        size='small'
        bordered
        virtual
        pagination={{
          pageSize: 500,
        }}
        scroll={{ x: windowHeight - 300, y: 600 }}
      />
    </React.Fragment>
  );
};

const ContainerFilterTable = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default TableReportLog;
