import { Card } from 'antd';
import styled from 'styled-components';
import useReportLog from '../hooks/useReportLog';
import { useEffect, useState } from 'react';
import useGroup from '../zustand/useGroup';
import { EReportDataInterval } from '../screens/report/types/report-log.type';
import dayjs, { Dayjs } from 'dayjs';

const InfoDataCompletion = () => {
  const { dataCompletion } = useReportLog();
  const { filter } = useGroup();
  const [data, setData] = useState<any[]>([]);

  const _renderDeviceInfo = (
    device: string,
    valueDevice: any,
    lastDataDevice: any
  ) => {
    return (
      <DeviceInfo key={device}>
        <DeviceInfoLabel>
          {device.toLocaleUpperCase()}: {valueDevice}%
        </DeviceInfoLabel>
        <DeviceInfoDate style={{ color: 'grey', marginTop: -5 }}>
          Last Data: {lastDataDevice}
        </DeviceInfoDate>
      </DeviceInfo>
    );
  };

  useEffect(() => {
    if (dataCompletion && dataCompletion.length) {
      let interval = 0;
      const startAt = filter.startAt;
      const endAt = filter.endAt;

      if (filter.interval === EReportDataInterval.minute) {
        interval = Math.floor((endAt - startAt) / 60);
      } else if (filter.interval === EReportDataInterval.hour) {
        interval = Math.floor((endAt - startAt) / 3600);
      } else {
        interval = Math.ceil((endAt - startAt) / 86400);
        // if (moment(filter.range.startAt).hours() === 0) {
        //   dataLength = dataLength - 1;
        // }
      }
      const res = dataCompletion.map((item) => {
        const percentage = (item.count / interval) * 100;
        return {
          ...item,
          lastTimestamp: dayjs(item.lastTimestamp * 1000).format(
            'DD MMM YYYY HH:mm'
          ),
          percentage: percentage > 100 ? 100 : percentage,
        };
      });
      setData(res);
    }
  }, [dataCompletion, filter]);

  return (
    <InfoPercentage>
      {data.map((item) => {
        return _renderDeviceInfo(
          item.type,
          item.percentage,
          item.lastTimestamp
        );
      })}
    </InfoPercentage>
  );
};

const InfoPercentage = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DeviceInfo = styled.div`
  flex: 1;
  border-right: 1px solid #ebebeb;
  padding-bottom: 5px;

  :last-child {
    border-right: none;
  }
`;

const DeviceInfoLabel = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
`;

const DeviceInfoDate = styled.div`
  font-size: 10px;
  color: var(--gray-1);
`;

export default InfoDataCompletion;
