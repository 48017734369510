import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from '@qlibs/react-components';
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from '../user/components/CustomFormItem';
import { httpRequest } from '../../helpers/api';
import {
  DeviceProperties,
  EDeviceType,
  ICreateDevice,
} from './types/device.type';
import SectionContent from '../../components/SectionContent';
import { AssetProperties } from '../asset/types/asset.type';
import { DeviceTypeProperties } from '../deviceType/types/device.type';

interface ILocation {
  deviceId: string;
}

const DeviceEdit = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [data, setData] = React.useState<DeviceProperties>();
  const [form] = Form.useForm();
  const [assets, setAssets] = React.useState<AssetProperties[]>([]);
  const [deviceTypes, setDeviceTypes] = React.useState<DeviceTypeProperties[]>(
    []
  );
  const [deviceUniqueId, setDeviceUniqueId] = useState<{
    asset: string;
    deviceType: string;
    location: string;
    flowType: string;
  }>({
    asset: '',
    deviceType: '',
    location: '',
    flowType: '',
  });
  const [currentDeviceType, setCurrentDeviceType] = useState<string>();

  async function updateDevice(values: ICreateDevice) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        uniqueId: deviceUniqueIdCombined,
        deviceTypeId: values.deviceType,
        assetId: values.assetId,
        description: values.description,
        createdByUserId: values.createdByUserId,
        status: values.status ? 'active' : 'inactive',
      };

      await httpRequest.patch<BaseResponseProps<DeviceProperties>>(
        '/device/' + deviceId,
        formData
      );

      message.success(`Success add ${values.name}`);
      form.resetFields();
      setData(undefined);
      navigate('/device');
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning('This data has been archived and cannot be edited');
    } else {
      if (deviceUniqueIdCombined) {
        const ids = deviceUniqueIdCombined.split('-');
        if (currentDeviceType === 'flowmeter') {
          if (ids.length < 4) {
            message.warning('Flowmeter device unique id must be valid');
            return;
          }
        } else if (['rpm', 'ae'].includes(currentDeviceType!)) {
          if (ids.length < 3) {
            message.warning(
              `${currentDeviceType!.toLocaleUpperCase()} device ID must be valid`
            );
            return;
          }
        } else {
          if (ids.length < 2) {
            message.warning(
              `${currentDeviceType!.toLocaleUpperCase()} device ID must be valid`
            );
            return;
          }
        }
      }

      updateDevice(values);
    }
  };

  async function getDeviceTypes() {
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<DeviceTypeProperties>
      >('/device-type?filterStatus=active');

      if (res) {
        setDeviceTypes(res.data.payload.results);
      }
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        setIsLoadingAction(true);
        const response = await httpRequest.get<
          BaseResponsePaginationProps<AssetProperties>
        >('/asset');

        if (response.data.payload) {
          setAssets(response.data.payload.results);
        }
      } catch (error) {
        message.error(`Failed get data assets`);
      } finally {
        setIsLoadingAction(false);
      }
    };

    fetchAsset();
    getDeviceTypes();
  }, []);

  const assetMapped = React.useMemo(() => {
    return assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
  }, [assets]);

  const deviceTypeMapped = React.useMemo(() => {
    return Object.values(deviceTypes).map((item) => ({
      label: item.name,
      value: item.deviceTypeId,
    }));
  }, [deviceTypes]);

  async function fetchData(deviceId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<DeviceProperties>
      >('/device/' + deviceId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);

        const formData = {
          ...data,
          deviceType: data.deviceTypeId,
          status: data.status === 'active' ? true : false,
        };
        form.setFieldsValue(formData);

        if (
          data.deviceType.name.toLocaleLowerCase() === EDeviceType.FLOWMETER
        ) {
          setCurrentDeviceType(data.deviceType.name);
          const ids = data.uniqueId.split('-');
          if (ids && ids.length) {
            setDeviceUniqueId({
              asset: ids[0],
              deviceType: 'fm',
              location: ids[2],
              flowType: ids[3],
            });
          }
        }
      }
    } catch (error) {
      message.error(`Failed get device data`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  useEffect(() => {
    if (deviceId) {
      fetchData(deviceId);
    }
  }, [deviceId]);

  const deviceUniqueIdCombined = useMemo(() => {
    if (deviceUniqueId) {
      return Object.values(deviceUniqueId)
        .filter((item) => item)
        .join('-');
    }
  }, [deviceUniqueId]);

  const prefix = useMemo(() => {
    return deviceUniqueId?.asset + '-' + deviceUniqueId?.deviceType;
  }, [deviceUniqueId]);

  function handleChangeDeviceType(e: string) {
    const found = deviceTypeMapped.find((item) => item.value === e);
    if (found) {
      setCurrentDeviceType(found.label.toLocaleLowerCase());
      if (found.label.toLocaleLowerCase() === 'flowmeter') {
        setDeviceUniqueId((current) => ({
          ...current,
          deviceType: 'fm',
        }));
      } else if (['rpm', 'ae'].includes(found.label.toLocaleLowerCase())) {
        setDeviceUniqueId((current) => ({
          ...current,
          deviceType: found.label.toLocaleLowerCase(),
          flowType: '',
        }));
      } else {
        setDeviceUniqueId((current) => ({
          ...current,
          deviceType: found.label.toLocaleLowerCase(),
          location: '',
          flowType: '',
        }));
      }
    }
  }

  const renderDeviceUniqueID = () => {
    if (currentDeviceType === EDeviceType.FLOWMETER) {
      return (
        <Row>
          <CustomFormItemPer3 label='Prefix'>
            <Input value={prefix} disabled />
          </CustomFormItemPer3>
          <CustomFormItemPer3 label='Location'>
            <Select
              value={deviceUniqueId?.location}
              placeholder='Choose asset'
              style={{ width: '100%' }}
              onChange={(e) =>
                setDeviceUniqueId((current) => ({ ...current, location: e }))
              }
              options={[
                {
                  label: 'PORT',
                  value: '1',
                },
                {
                  label: 'STARBOARD',
                  value: '2',
                },
                {
                  label: 'CENTER',
                  value: '3',
                },
              ]}
            />
          </CustomFormItemPer3>
          <CustomFormItemPer3 label='Flow type'>
            <Select
              placeholder='Choose asset'
              style={{ width: '100%' }}
              onChange={(e) =>
                setDeviceUniqueId((current) => ({ ...current, flowType: e }))
              }
              value={deviceUniqueId?.flowType}
              options={[
                {
                  label: 'IN',
                  value: '1',
                },
                {
                  label: 'OUT',
                  value: '2',
                },
              ]}
            />
          </CustomFormItemPer3>
        </Row>
      );
    } else if (currentDeviceType && ['rpm', 'ae'].includes(currentDeviceType)) {
      return (
        <Row>
          <CustomFormItemPer3 label='Prefix'>
            <Input value={prefix} disabled />
          </CustomFormItemPer3>
          <CustomFormItemPer3 label='Location'>
            <Select
              value={deviceUniqueId?.location}
              placeholder='Choose asset'
              style={{ width: '100%' }}
              onChange={(e) =>
                setDeviceUniqueId((current) => ({ ...current, location: e }))
              }
              options={[
                {
                  label: 'PORT',
                  value: '1',
                },
                {
                  label: 'STARBOARD',
                  value: '2',
                },
                {
                  label: 'CENTER',
                  value: '3',
                },
              ]}
            />
          </CustomFormItemPer3>
        </Row>
      );
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={'Update Device'}
        subtitle={'Update current device'}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className='mb-5'
            type='warning'
            message='This data has been archived and cannot be edited'
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Information'
            subTitle='Detail information about device'
          >
            <Row>
              <CustomFormItemPer3
                label='Asset'
                name='assetId'
                rules={generateFormRules('Asset', ['required'])}
              >
                <Select
                  showSearch
                  placeholder='Choose asset'
                  style={{ width: '100%' }}
                  options={assetMapped}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label='Device Type'
                name='deviceType'
                rules={generateFormRules('Device Phone Number', ['required'])}
              >
                <Select
                  showSearch
                  placeholder='Choose device type'
                  style={{ width: '100%' }}
                  options={deviceTypeMapped}
                  onChange={handleChangeDeviceType}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label='Device Name'
                name='name'
                rules={generateFormRules('Device Name', ['required'])}
              >
                <Input placeholder='Input device name' />
              </CustomFormItemPer3>
            </Row>
            <CustomFormItemPer1 label='Description' name='description'>
              <Input placeholder='Input device description' />
            </CustomFormItemPer1>
          </SectionContent>

          <SectionContent
            groupTitle='Device Unique ID'
            subTitle='Detail information about device'
          >
            {renderDeviceUniqueID()}
            <Row>
              <CustomFormItemPer1>
                <Alert
                  message={`Result Device Unique ID: ${deviceUniqueIdCombined}`}
                />
              </CustomFormItemPer1>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle='These are device information, you can change anything'
          >
            <CustomFormItemPer1
              label='Status'
              name='status'
              valuePropName='checked'
              initialValue={true}
            >
              <Switch defaultChecked className='custom-switch' />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default DeviceEdit;
