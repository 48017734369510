import { Table } from 'antd';
import styled from 'styled-components';
import { ILogReport } from '../../types/report-log.type';
import { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  title: string;
  logs?: ILogReport;
}

const PageContent: React.FC<IProps> = (props) => {
  return (
    <Wrapper>
      <DataReport pageBreak>
        <TitleReport>{props.title}</TitleReport>
        {props.children}
      </DataReport>
    </Wrapper>
  );
};

export default PageContent;

export const TitleReport = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
`;

const DataReport = styled.div<{ pageBreak?: boolean }>`
  margin-top: 0px;
  ${(props) => props.pageBreak && `page-break-after: always;`}
`;

const Wrapper = styled.div`
  padding: 5px;
  position: relative;
  border-bottom: 1px solid white;
  width: 297mm !important;
  height: 180mm !important;
  /* background-color: red; */
`;
