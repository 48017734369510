import { Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Map from "../../../home/components/Map";
import useGroup from "../../../../zustand/useGroup";
import useReportLog from "../../../../hooks/useReportLog";
import {
  convertData,
  generateTimestampData,
  latToDMS,
  longToDMS,
  toHHMMSS,
} from "../helperFunction";
import dayjs, { Dayjs } from "dayjs";
import { vesselTrackingType } from "../../types/report-log.type";
import { getStatusEngine, getVesselStatus } from "../../../../helpers/vessel";
import { httpRequest } from "../../../../helpers/api";
import { generateQueryString, getErrorMessage } from "@qlibs/react-components";

const VesselTracking = () => {
  const { filter, setFilter, asset, dataSource } = useGroup();
  const {
    setChecklistColumn,
    checklistColumn,
    loadingLog: loading,
    logs,
    setShowReportTableConfig,
  } = useReportLog();

  const [lastDataLog, setLastDataLog] = useState<any>({});
  const [lastDataLogRPM, setLastDataLogRPM] = useState<any>({});
  const [dataSourceVesselTracking, setDataSourceVesselTracking] = useState<
    vesselTrackingType[]
  >([
    {
      key: "1",
      header: "Start",
      position: "",
      time: "",
      speed: "",
    },
    {
      key: "2",
      header: "End",
      position: "",
      time: "",
      speed: "",
    },
    {
      key: "3",
      header: "Average Speed",
      position: "",
      time: "",
      speed: "",
    },
  ]);

  const columnsVesselTracking: any = [
    {
      title: " ",
      dataIndex: "header",
      key: "header",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      onCell: (_: any, index: number) => ({
        colSpan: index === 3 || index === 2 ? 3 : 1,
      }),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      onCell: (_: any, index: number) => ({
        colSpan: index === 3 || index === 2 ? 0 : 1,
      }),
    },
    {
      title: "Speed",
      dataIndex: "speed",
      key: "speed",
      onCell: (_: any, index: number) => ({
        colSpan: index === 3 || index === 2 ? 0 : 1,
      }),
    },
  ];

  const data = useMemo(() => {
    if (logs && Object.keys(logs).length) {
      const containerTimestamp = generateTimestampData({
        startTime: filter.startAt,
        endTime: filter.endAt,
        interval: filter.interval,
      });

      const results = containerTimestamp.map((timestamp) => {
        let fm;
        let rpm;
        let ae;
        let gps;
        if (logs.flowmeter) {
          const foundKey = Object.keys(logs.flowmeter).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            fm = logs.flowmeter[foundKey];
          }
        }
        if (logs.rpm) {
          const foundKey = Object.keys(logs.rpm).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            rpm = logs.rpm[foundKey];
          }
        }
        if (logs.ae) {
          const foundKey = Object.keys(logs.ae).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            ae = logs.ae[foundKey];
          }
        }
        if (logs.gps) {
          const foundKey = Object.keys(logs.gps).find(
            (key) => Number(key) === timestamp
          );
          if (foundKey) {
            gps = logs.gps[foundKey];
          }
        }

        return {
          timestamp,
          dateTime: dayjs(timestamp * 1000).format("DD MMM YYYY HH:mm"),
          gps_coordinate:
            gps?.latitude && gps?.longitude
              ? `${latToDMS(gps?.latitude)}, ${longToDMS(gps?.longitude)}`
              : "-",
          gps_latitude: gps?.latitude,
          gps_longitude: gps?.longitude,
          gps_altitude: gps?.altitude,
          gps_heading: convertData(gps?.heading),
          gps_speed: convertData(gps?.speed),
          gps_distance: convertData(gps?.distance),
          port_Rpm: convertData(rpm?.port?.rpm),
          port_RunningTime: toHHMMSS(rpm?.port?.runningTime),
          port_FuelCons: convertData(fm?.deviceCalculation?.portIn?.fuelCons),
          port_VolumeTotal: convertData(
            fm?.deviceCalculation?.portIn?.volumeFuelCons
          ),
          port_InFlow: convertData(fm?.portIn?.volumeFlowrate),
          port_Temperature: convertData(fm?.portIn?.temperature),
          port_InDensity: convertData(fm?.portIn?.density),
          starboard_Rpm: convertData(rpm?.starboard?.rpm),
          starboard_RunningTime: toHHMMSS(rpm?.starboard?.runningTime),
          starboard_FuelCons: convertData(
            fm?.deviceCalculation?.starboardIn?.fuelCons
          ),
          starboard_VolumeTotal: convertData(
            fm?.deviceCalculation?.starboardIn?.volumeFuelCons
          ),
          starboard_InFlow: convertData(fm?.starboardIn?.volumeFlowrate),
          starboard_Temperature: convertData(fm?.starboardIn?.temperature),
          starboard_InDensity: convertData(fm?.starboardIn?.density),
          ae1_RunningTime: toHHMMSS(ae?.ae1?.runningTime),
          ae1_FuelConsumption: convertData(ae?.ae1?.fuelConsumption),
          ae2_RunningTime: toHHMMSS(ae?.ae2?.runningTime),
          ae2_FuelConsumption: convertData(ae?.ae2?.fuelConsumption),
          ae3_RunningTime: toHHMMSS(ae?.ae3?.runningTime),
          ae3_FuelConsumption: convertData(ae?.ae3?.fuelConsumption),
        };
      });

      return results;
    }
  }, [logs]);

  const getReportLogRPM = async () => {
    try {
      let newFilter = { ...filter };

      newFilter = {
        ...newFilter,
        deviceType: "rpm",
      };

      const res = await httpRequest.get(
        `/report-log/${generateQueryString(newFilter)}`
      );

      const keys = Object.keys(res.data.payload.rpm);
      const lastKey = keys[keys.length - 1];
      const lastDataRPM = res.data.payload.rpm[lastKey];

      // console.log(lastDataRPM);

      setLastDataLogRPM(lastDataRPM);
    } catch (error) {
      console.log(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (data) {
      setLastDataLog(data[data?.length - 1]);

      const dataLength = data.length;
      let sum = 0;

      data.forEach((value) => {
        sum += Number(value.gps_speed);
      });

      const avgSpeed = sum / data.length;

      setDataSourceVesselTracking((prev) =>
        prev.map((item) =>
          item.key === "1"
            ? {
                ...item,
                position: data[0]?.gps_coordinate,
                time: data[0]?.dateTime,
                speed: data[0]?.gps_speed,
              }
            : item.key === "2"
            ? {
                ...item,
                position: data[dataLength - 1]?.gps_coordinate,
                time: data[dataLength - 1]?.dateTime,
                speed: data[dataLength - 1]?.gps_speed,
              }
            : item.key === "3"
            ? {
                ...item,
                position: avgSpeed ? avgSpeed.toFixed(2) : "-",
              }
            : {
                ...item,
                position: "-",
              }
        )
      );

      console.log(data[data?.length - 1]);

      setLastDataLog(data[data?.length - 1]);
      getReportLogRPM();
    }
  }, [data]);

  return (
    <Wrapper>
      <Table
        dataSource={dataSourceVesselTracking}
        columns={columnsVesselTracking}
        pagination={false}
        size="small"
      />
      <div
        style={{
          borderRadius: 10,
          overflow: "hidden",
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <Map
          height="500px"
          asset={asset?.name}
          lastDataLog={lastDataLog}
          dataLogs={data}
          vesselStatus={getVesselStatus(lastDataLog, dataSource)}
          isEngineOn={getStatusEngine(lastDataLogRPM, dataSource)}
          isShowMapSettings
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
`;

const CustomTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: grey;
    color: black;
    text-align: center;
    border-radius: 0px !important;
    border: 1px solid #264284;
    padding: 1.5px !important;
  }

  .ant-table-thead > tr > th:first-child {
    background-color: #264284;
    color: white;
  }

  .ant-table-tbody > tr > td {
    border: 1px solid #264284;
    padding: 10px !important;
    text-align: center;
    padding: 1.5px !important;
  }

  .ant-table-tbody > tr > td:first-child {
    background-color: #264284;
    color: white;
    text-align: center;
  }

  td.ant-table-cell:hover {
    color: #264284 !important;
  }

  .ant-table-cell::before {
    content: none !important;
  }
`;

export default VesselTracking;
