export enum EReportDataType {
  temp = "temp",
  mask = "mask",
}

export interface IGps {
  id: number;
  deviceUniqueId: string;
  timestamp: number;
  datetime: Date;
  heading: number;
  altitude: number;
  latitude: number;
  longitude: number;
  polyline: string;
  speed: number;
  distance: number;
  geoidal: number;
  dataType?: EReportDataType | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILogGPS {
  [key: string]: IGps;
}

export enum EFlowmeterType {
  port = "port",
  starboard = "starboard",
  center = "center",
}

export enum EFlowmeterFlowType {
  in = "in",
  out = "out",
}

export interface IFlowmeter {
  id: number;
  deviceUniqueId: string;
  timestamp: number;
  dateTime: string;
  density: number;
  temperature: number;
  volumeFlowrate: number;
  volumeInventory: number;
  volumeTotal: number;
  massFlowrate: number;
  massInventory: number;
  massTotal: number;
  dataType?: EReportDataType | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IFlowmeterCalculation {
  volumeFlowrate: number;
  massFlowrate: number;
  density: number;
  fuelCons: number;
  volumeFuelCons: number;
  temperature: number;
}

export interface ILogFlowmeter {
  [key: string]: {
    deviceCalculation: {
      [key: string]: IFlowmeterCalculation;
    };
    totalFuelCons: number;
  } & {
    [key: string]: IFlowmeter;
  };
}

export interface IAE {
  id: number;
  deviceUniqueId: string;
  timestamp: number;
  datetime: Date;
  fuelConsumption: number;
  rpm: number;
  runningTime: number;
  type: number;
  dataType?: EReportDataType | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILogAE {
  [key: string]: {
    totalFuelConsumption: number;
    totalRunningTime: number;
  } & {
    [key: string]: IAE;
  };
}

export enum ERPMType {
  port = "port",
  starboard = "starboard",
  center = "center",
}

export interface IRpm {
  id: number;
  deviceUniqueId: string;
  timestamp: number;
  datetime: Date;
  rpm: number;
  runningTime: number;
  type: ERPMType;
  dataType?: EReportDataType | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILogRpm {
  [key: string]: {
    totalRpm: number;
    totalRunningTime: number;
  } & {
    [key: string]: IRpm;
  };
}

export interface IGateway {
  id: number;
  deviceUniqueId: string;
  timestamp: number;
  datetime: Date;
  cpuUsage: number;
  memoryFree: number;
  spaceFree: number;
  dataType?: EReportDataType | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ILogGateway {
  [key: string]: IGateway;
}

export enum EReportDataInterval {
  minute = "minute",
  hour = "hour",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
}

export interface ILogReport {
  gps?: ILogGPS;
  flowmeter?: ILogFlowmeter;
  ae?: ILogAE;
  rpm?: ILogRpm;
  gateway?: ILogGateway;
}

export interface ILogReportFilter {
  deviceType: string;
  dataSource?: "GSM" | "SATELLITE";
  assetId: string;
  interval: any;
  startAt: number;
  endAt: number;
  assetIds?: string[];
  // rangeType: ELogFilterRangeType;
  timezone?: number;
}

export interface IReportLogGPS {}

export enum ELogFilterRangeType {
  "last_30_day" = "last_30_day",
  "last_month" = "last_month",
  "last_7_day" = "last_7_day",
  today = "today",
  "last_day" = "last_day",
  custom = "custom",
}

export interface IDataAE {
  ae1_RunningTime: string;
  ae1_FuelConsumption: string;
  ae2_RunningTime: string;
  ae2_FuelConsumption: string;
  ae3_RunningTime: string;
  ae3_FuelConsumption: string;
  dateTime: "";
}

export const initialIDataAE: IDataAE = {
  ae1_RunningTime: "",
  ae1_FuelConsumption: "",
  ae2_RunningTime: "",
  ae2_FuelConsumption: "",
  ae3_RunningTime: "",
  ae3_FuelConsumption: "",
  dateTime: "",
};
export interface CapturePdfParams {
  assetId: string;
  assetName: string;
  startAt: number;
  endAt: number;
  deviceType?: string;
  interval?: string;
  timezone?: number;
  scheduleReport?: number;
  dataDownloaded?: string[];
}

export type vesselTrackingType = {
  key: string;
  header: string;
  position: string;
  time: string;
  speed: string;
};
