import {
  DownloadOutlined,
  DownOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Upload,
} from 'antd';
import { CustomFormItemPer2 } from '../../user/components/CustomFormItem';
import { useEffect, useMemo, useState } from 'react';
import { httpRequest } from '../../../helpers/api';
import * as XLSX from 'xlsx';
import useMasterData from '../../../hooks/useMasterData';
import useGroup from '../../../zustand/useGroup';
import { RcFile } from 'antd/es/upload';
import styled from 'styled-components';

interface IProps {}

const reportLogTemplates = [
  {
    label: 'Flowmeter',
    value: 'flowmeter',
    columns: [
      'date',
      'time',
      'density',
      'temperature',
      'volumeFlowrate',
      'volumeInventory',
      'volumeTotal',
      'massFlowrate',
      'massInventory',
      'massTotal',
      'dataSource',
    ],
  },
  {
    label: 'GPS',
    value: 'gps',
    columns: [
      'date',
      'time',
      'heading',
      'altitude',
      'latitude',
      'longitude',
      'polyline',
      'speed',
      'distance',
      'dataSource',
    ],
  },
  {
    label: 'RPM',
    value: 'rpm',
    columns: ['date', 'time', 'rpm', 'runningTime', 'dataSource'],
  },
  {
    label: 'AE',
    value: 'ae',
    columns: [
      'date',
      'time',
      'fuelConsumption',
      'voltage',
      'ampere',
      'runningTime',
      'dataSource',
    ],
  },
];

const ImportDataReport: React.FC<IProps> = () => {
  const [modalDownloadTemplate, setModalDownloadTemplate] =
    useState<boolean>(false);
  const [modalImport, setModalImport] = useState<boolean>(false);
  const { assets, getListDevices, devices, loadingDevice } = useMasterData();
  const { asset } = useGroup();

  function handleCloseModal() {
    setModalDownloadTemplate(false);
    setModalImport(false);
    setSelectedTemplate('');
    setDataExcelParsed([]);
    setActiveDeviceUniqueId('');
    setFileName('');
  }

  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  const generateTemplate = () => {
    const wb = XLSX.utils.book_new();
    const currentTemplate = reportLogTemplates.find((item) =>
      selectedTemplate.includes(item.value)
    );

    if (currentTemplate) {
      const templateHeaders = Object.values(currentTemplate.columns);
      let sampleRow = templateHeaders.map((row) => {
        if (row === 'date') {
          return '2025-01-15';
        } else if (row === 'time') {
          return '00:00:00';
        } else {
          return 0;
        }
      });

      const worksheetData = [templateHeaders, sampleRow];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

      const defaultWch = 10;

      worksheet['!cols'] = Array.from(
        { length: templateHeaders.length },
        () => ({
          wch: defaultWch,
        })
      );

      XLSX.utils.book_append_sheet(wb, worksheet, currentTemplate.label);
      XLSX.writeFile(
        wb,
        `${asset.name} Template Report Log - ${currentTemplate.label}.xlsx`
      );
    }

    handleCloseModal();
  };

  function handleAction(e: string) {
    if (e === 'download_template') {
      generateTemplate();
    } else if (e === 'import') {
      handleImportReportLog();
    }
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'download_template') {
      setModalDownloadTemplate(true);
    } else if (e.key === 'import_data') {
      setModalImport(true);
    }
  };

  const [fileName, setFileName] = useState<string>('');
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [dataExcelParsed, setDataExcelParsed] = useState<any>([]);
  const [activeAssetId, setActiveAssetId] = useState<string>('');
  const [activeDeviceUniqueId, setActiveDeviceUniqueId] = useState<string>('');

  useEffect(() => {
    if (asset) {
      setActiveAssetId(asset.assetId);
    }
  }, [asset]);

  const filterAssetOptions = useMemo(() => {
    const data = assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
    return [...data];
  }, [assets]);

  const filterDeviceOptions = useMemo(() => {
    const data = devices.map((item) => ({
      value: item.uniqueId,
      label: item.name,
    }));
    return [...data];
  }, [devices]);

  console.log('filterDeviceOptions', filterDeviceOptions);

  useEffect(() => {
    if (activeAssetId) {
      getListDevices({
        filterAssetIds: activeAssetId,
      });
    }
  }, [activeAssetId]);

  const validateFile = (file: RcFile) => {
    let allow = true;
    const isExcel =
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel';
    if (!isExcel) {
      message.error('You can only upload Excel files (.xlsx or .xls).');
      allow = false;
    }

    return allow;
  };

  const handleFileUpload = async (file: RcFile) => {
    const valid = validateFile(file);

    if (!valid) {
      return;
    }

    try {
      setFileName(file.name);
      setLoadingImport(true);
      const parsedData = await readExcelFile(file);
      setDataExcelParsed(parsedData);
    } catch (error) {
      message.error('Failed read excel');
      console.error('failed read excel', error);
    } finally {
      setLoadingImport(false);
    }
  };

  const handleImportReportLog = async () => {
    try {
      if (!activeAssetId) {
        message.warning(
          'Asset empty. You must choose one of asset from dropdown asset'
        );
        return;
      }

      if (!activeDeviceUniqueId) {
        message.warning(
          'Device empty. You must choose device that corresponden with the data'
        );
        return;
      }

      if (!dataExcelParsed.length) {
        message.warning(
          'Data empty. Data must be filled in at least one row data'
        );
        return;
      }

      setLoadingImport(true);
      const found = devices.find(
        (item) => item.uniqueId === activeDeviceUniqueId
      );
      if (found) {
        const data = {
          deviceUniqueId: activeDeviceUniqueId,
          deviceType: found.deviceType.name.toLocaleLowerCase(),
          data: dataExcelParsed.dataSource,
        };
        await httpRequest.post('/report-log/import', data);
        handleCloseModal();
      } else {
        message.warning('Device type not found');
      }
    } catch (error) {
      console.error('failed read excel', error);
    } finally {
      setLoadingImport(false);
    }
  };

  return (
    <>
      <Tooltip title='Download report log as XLSX'>
        <Dropdown
          menu={{
            items: [
              {
                key: 'download_template',
                label: 'Download Template',
              },
              {
                key: 'import_data',
                label: 'Import Data Report Log',
              },
            ],
            onClick: handleMenuClick,
          }}
        >
          <Button type='primary'>
            <Space>
              <ImportOutlined />
              Import Report Log
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Tooltip>

      <Modal
        title='Download Template for Import Report Log'
        open={modalDownloadTemplate}
        closable
        onOk={() => handleAction('download_template')}
        onCancel={handleCloseModal}
        okText={'Download'}
        okButtonProps={{
          style: {
            background: 'blue',
          },
        }}
      >
        <Text>Choose report template that you want to download</Text>
        <Radio.Group
          value={selectedTemplate}
          defaultValue={[]}
          options={reportLogTemplates}
          onChange={(e) => {
            console.log('active', e);
            setSelectedTemplate(e.target.value);
          }}
        />
      </Modal>

      <Modal
        confirmLoading={loadingImport}
        title='Import Report Log'
        open={modalImport}
        closable
        width={900}
        onOk={() => handleAction('import')}
        onCancel={handleCloseModal}
        okText={'Import Report Log'}
        okButtonProps={{
          style: {
            background: 'blue',
          },
        }}
      >
        <Alert
          style={{ marginBottom: 10 }}
          message={
            <Upload
              beforeUpload={(file) => handleFileUpload(file)}
              accept='.xlsx'
              showUploadList={false}
            >
              <Space>
                <Button type='primary' loading={loadingImport}>
                  Choose file
                </Button>
                {fileName
                  ? `Filename: ${fileName}`
                  : 'Select file that you want to upload first'}
              </Space>
            </Upload>
          }
        />

        {dataExcelParsed &&
          dataExcelParsed.columns &&
          dataExcelParsed.columns.length && (
            <>
              <Row>
                <CustomFormItemPer2 label='Asset Name'>
                  <Select
                    showSearch
                    value={activeAssetId}
                    placeholder='Choose asset'
                    style={{ width: '100%' }}
                    options={filterAssetOptions}
                    onChange={(e) => setActiveAssetId(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </CustomFormItemPer2>
                <CustomFormItemPer2 label='Device'>
                  <Select
                    disabled={activeAssetId ? false : true}
                    loading={loadingDevice}
                    showSearch
                    placeholder='Choose device'
                    style={{ width: '100%' }}
                    onChange={(e) => setActiveDeviceUniqueId(e)}
                    options={filterDeviceOptions}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </CustomFormItemPer2>
              </Row>
              <Table
                size='small'
                bordered
                virtual
                pagination={false}
                scroll={
                  dataExcelParsed.columns.length > 5
                    ? { y: 55 * 5, x: true }
                    : undefined
                }
                columns={dataExcelParsed.columns}
                dataSource={dataExcelParsed.dataSource}
              />
            </>
          )}
      </Modal>
    </>
  );
};

const Text = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const readExcelFile = (file: File): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (!e.target?.result) {
        reject(new Error('Failed to read file.'));
        return;
      }

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      let result: any = null;

      const sheetName = workbook.SheetNames[0];

      if (!sheetName) {
        message.error('Failed parse file XLSX');
        return;
      }

      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
        header: 1,
      });
      const headers: any = jsonData[0];
      const rows = jsonData.slice(1);

      const columns = headers.map((item: any) => ({
        title: item,
        dataIndex: item,
        key: item,
        width: 150,
        align: 'center',
      }));

      const dataSource: any = [];

      rows.forEach((item, idx) => {
        let currDataRow: any = {};
        item.forEach((item2, idx2) => {
          currDataRow[headers[idx2]] = item2;
        });
        dataSource.push(currDataRow);
      });

      result = {
        key: sheetName,
        label: sheetName,
        columns,
        dataSource,
      };
      resolve(result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export default ImportDataReport;
