import {
  HeaderSection,
  capitalizeFirstLetter,
  generateFormRules,
  getErrorMessage,
} from '@qlibs/react-components';
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Switch,
  Tag,
  Tooltip,
  message,
} from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomFormItemPer1 } from '../../user/components/CustomFormItem';
import { httpRequest } from '../../../helpers/api';
import SectionContent from '../../../components/SectionContent';
import {
  EEmailBodyParams,
  EEmailSubjectParams,
  initialReportEmailTemplate,
  ReportEmailTemplateProperties,
} from '../types/report.type';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
import styled from 'styled-components';
import { CopyFilled } from '@ant-design/icons';

interface ILocation {
  templateId: string;
}

const MasterReportEmailTemplateEdit = () => {
  const navigate = useNavigate();
  const { templateId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [form] = Form.useForm();

  const [template, setTemplate] = useState<ReportEmailTemplateProperties>(
    initialReportEmailTemplate
  );
  const [loading, setLoading] = useState<boolean>(false);

  const emailEditorRef = useRef<EditorRef>(null);

  const exportHtmlAndDesign = (): Promise<any> => {
    return new Promise((resolve) => {
      emailEditorRef.current?.editor?.exportHtml((data) => {
        let { design, html } = data;

        resolve({ html, design });
      });
    });
  };

  const onReady: EmailEditorProps['onReady'] = (unlayer: any) => {
    if (template.designContent) {
      unlayer.loadDesign(JSON.parse(template.designContent));
    }
  };

  const handleSubmit = async (values: any) => {
    setIsLoadingAction(true);

    try {
      await form.validateFields();
    } catch (error: any) {
      console.error(error);
      setIsLoadingAction(false);
      return;
    }

    try {
      const { html, design } = await exportHtmlAndDesign();

      if (html && design) {
        const updatedTemplate = {
          templateName: values.templateName,
          subject: values.subject,
          externalId: 'SYSTEM',
          status: values.status,
          platform: 'email',
          htmlContent: html,
          designContent: JSON.stringify(design),
        };
        setTemplate(updatedTemplate);

        let res: any;

        if (template.templateId) {
          res = await httpRequest.patch(
            `/template-messages/${template.templateId}`,
            updatedTemplate
          );
        } else {
          res = await httpRequest.post(`/template-messages`, updatedTemplate);
        }

        if (res) {
          message.success(
            `Success ${templateId ? 'edit' : 'create'} template email`
          );

          navigate('/master-report');

          form.resetFields();
          // setTemplate(initialTemplateMessages);
        }
      } else {
        message.error(`HTML Content is required`);
      }
    } catch (error) {
      console.error(error);
      message.error(getErrorMessage(error));
    } finally {
      setIsLoadingAction(false);
    }
  };

  useEffect(() => {
    if (templateId) {
      const fetchTemplate = async () => {
        try {
          setLoading(true);
          const res: any = await httpRequest.get<any>(
            '/template-messages/' + templateId
          );

          if (res && res.data) {
            const { templateName, subject, externalId, status } =
              res.data.payload;
            setTemplate(res.data.payload);

            const values = {
              templateName,
              subject,
              companyId: externalId,
              status,
            };

            form.setFieldsValue(values);
          } else {
            message.error('Something went wrong');
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchTemplate();
    }
  }, [templateId]);

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.info(`Success copy ${text}`);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  const paramsSubject = useMemo(
    () =>
      Object.values(EEmailSubjectParams).map((item) => `{{params.${item}}}`),
    [EEmailSubjectParams]
  );

  const paramsEmailBody: any = useMemo(() => {
    const groupedData: Record<string, { group: string; params: string[] }> = {};

    Object.values(EEmailBodyParams).forEach((key) => {
      const match = (key as string).match(/^[a-zA-Z]+/);

      if (match) {
        const groupPrefix = match[0];
        if (!groupedData[groupPrefix]) {
          groupedData[groupPrefix] = {
            group: groupPrefix.charAt(0).toUpperCase() + groupPrefix.slice(1),
            params: [],
          };
        }

        groupedData[groupPrefix].params.push(`{{params.${key}}}`);
      }
    });

    return Object.values(groupedData);
  }, [EEmailBodyParams]);

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={`${templateId ? 'Update' : 'Add'} Master Report Email Template`}
        subtitle={`${
          templateId ? 'Update' : 'Add'
        } current master report email template`}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Information'
            subTitle='Detail information about email template'
          >
            <Row>
              <CustomFormItemPer1
                label='Template Name'
                name='templateName'
                rules={generateFormRules('Template Name', ['required'])}
              >
                <Input placeholder='Input template name' />
              </CustomFormItemPer1>

              <CustomFormItemPer1
                label='Email Subject'
                name='subject'
                rules={generateFormRules('Email Subject', ['required'])}
              >
                <Input placeholder='Input email subject' />
              </CustomFormItemPer1>
              <CustomFormItemPer1>
                <Alert
                  style={{ marginTop: 10 }}
                  showIcon
                  description={
                    <>
                      <MessageInfo>Available parameter:</MessageInfo>
                      <WrapperOptionInfo>
                        {paramsSubject.map((item) => (
                          <Tooltip title='Click to copy parameter'>
                            <Tag
                              color='#108ee9'
                              key={item}
                              onClick={() => copyToClipboard(item)}
                              icon={<CopyFilled />}
                              style={{ marginBottom: 5, cursor: 'pointer' }}
                            >
                              {item}
                            </Tag>
                          </Tooltip>
                        ))}
                      </WrapperOptionInfo>
                      <MessageInfo>
                        <strong>Example 1:</strong> <br />
                        Input: Report {`{{params.assetName}}`} -{' '}
                        {`{{params.reportGeneratedAt}}`} <br />
                        Result: Report Citra 81 - 29 Nov 2024
                      </MessageInfo>
                      <MessageInfo>
                        <strong>Example 2:</strong> <br />
                        Input: Report {`{{params.assetName}}`} -{' '}
                        {`{{params.reportDateRange}}`} <br />
                        Result: Report Citra 81 - 29 Nov 2024 00:00 - 29 Nov
                        2024 23:59
                      </MessageInfo>
                    </>
                  }
                />
                <Alert
                  message='If you use parameters other than the parameters specified above, the email template will not work'
                  type='warning'
                  style={{ marginTop: 5 }}
                  showIcon
                />
              </CustomFormItemPer1>

              <CustomFormItemPer1
                label='Status'
                name='status'
                valuePropName='checked'
                initialValue={true}
              >
                <Switch defaultChecked className='custom-switch' />
              </CustomFormItemPer1>
            </Row>
          </SectionContent>

          <Divider />

          <Row style={{ marginTop: 20 }}>
            <CustomFormItemPer1 label='Email Body' required>
              <Alert
                style={{ marginTop: 10 }}
                showIcon
                description={
                  <>
                    <MessageInfo>Available parameter:</MessageInfo>
                    <WrapperOptionInfo>
                      {paramsEmailBody.map((item: any) => (
                        <div>
                          <div>
                            <strong>{item.group}:</strong>
                          </div>
                          {item.params.map((param: string) => (
                            <Tooltip title='Click to copy parameter'>
                              <Tag
                                color='#108ee9'
                                key={param}
                                onClick={() => copyToClipboard(param)}
                                icon={<CopyFilled />}
                                style={{ marginBottom: 5, cursor: 'pointer' }}
                              >
                                {param}
                              </Tag>
                            </Tooltip>
                          ))}
                        </div>
                      ))}
                    </WrapperOptionInfo>
                  </>
                }
              />
              <Alert
                message='If you use parameters other than the parameters specified above, the email template will not work'
                type='warning'
                style={{ marginTop: 5 }}
                showIcon
              />
              {loading ? (
                <Spin size='large'>Loading editor</Spin>
              ) : (
                <EmailEditor ref={emailEditorRef} onReady={onReady} />
              )}
            </CustomFormItemPer1>
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  );
};

const MessageInfo = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

const WrapperOptionInfo = styled.div`
  margin-bottom: 8px;
`;

export default MasterReportEmailTemplateEdit;
