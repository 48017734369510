import { create, StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

type MapSettingProps = {
  showVesselOnline: boolean;
  showVesselOffline: boolean;
  showVesselWaiting: boolean;
  showVesselLabel: boolean;
  showVesselTrail: boolean;
  showDotTrail: boolean;
  trailWidth: any;
  trailOpacity: any;
  trailLength: any;
  mapType: "Satellite" | "Vector";
  setShowVesselOnline: (val: boolean) => void;
  setShowVesselOffline: (val: boolean) => void;
  setShowVesselWaiting: (val: boolean) => void;
  setShowVesselLabel: (val: boolean) => void;
  setShowVesselTrail: (val: boolean) => void;
  setShowDotTrail: (val: boolean) => void;
  setTrailWidth: (val: any) => void;
  setTrailOpacity: (val: any) => void;
  setTrailLength: (val: any) => void;
  setMapType: (val: "Satellite" | "Vector") => void;
};

type MyPersist = (
  config: any,
  options: PersistOptions<MapSettingProps>
) => StateCreator<MapSettingProps>;

const useMapSetting = create<MapSettingProps>(
  (persist as MyPersist)(
    (set: any) => ({
      showVesselOnline: true,
      showVesselOffline: true,
      showVesselWaiting: true,
      showVesselLabel: true,
      showVesselTrail: true,
      showDotTrail: true,
      trailWidth: 5,
      trailOpacity: 1,
      trailLength: 24,
      mapType: "Satellite",
      setShowVesselOnline: (val: boolean) => {
        set({ showVesselOnline: val });
      },
      setShowVesselOffline: (val: boolean) => {
        set({ showVesselOffline: val });
      },
      setShowVesselWaiting: (val: boolean) => {
        set({ showVesselWaiting: val });
      },
      setShowVesselLabel: (val: boolean) => {
        set({ showVesselLabel: val });
      },
      setShowVesselTrail: (val: boolean) => {
        set({ showVesselTrail: val });
      },
      setShowDotTrail: (val: boolean) => {
        set({ showDotTrail: val });
      },
      setTrailWidth: (val: any) => {
        set({ trailWidth: val });
      },
      setTrailOpacity: (val: any) => {
        set({ trailOpacity: val });
      },
      setTrailLength: (val: any) => {
        set({ trailLength: val });
      },
      setMapType: (val: "Satellite" | "Vector") => {
        set({ mapType: val });
      },
    }),
    {
      name: "map-settings",
      getStorage: () => localStorage,
    }
  )
);

export default useMapSetting;
