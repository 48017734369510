import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  getStandardError,
} from "@qlibs/react-components";
import {
  Alert,
  Button,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Upload,
  message,
} from "antd";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
  CustomFormItemPer3,
} from "../user/components/CustomFormItem";
import useAddress from "../../hooks/useAddress";
import { httpRequest, httpRequestMultipart } from "../../helpers/api";
import {
  DeviceProperties,
  EDeviceType,
  ICreateDevice,
} from "./types/device.type";
import SectionContent from "../../components/SectionContent";
import { AssetProperties } from "../asset/types/asset.type";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { DeviceTypeProperties } from "../deviceType/types/device.type";

const DeviceCreate = () => {
  const navigate = useNavigate();
  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [form] = Form.useForm();
  const [assets, setAssets] = React.useState<AssetProperties[]>([]);
  const [deviceTypes, setDeviceTypes] = React.useState<DeviceTypeProperties[]>(
    []
  );
  const [deviceUniqueId, setDeviceUniqueId] = React.useState<{
    asset: string;
    deviceType: string;
    location: string;
    flowType: string;
  }>({
    asset: "",
    deviceType: "",
    location: "",
    flowType: "",
  });
  const [currentDeviceType, setCurrentDeviceType] = React.useState<string>();

  async function getDeviceTypes() {
    try {
      const res = await httpRequest.get<
        BaseResponsePaginationProps<DeviceTypeProperties>
      >("/device-type?filterStatus=active");

      if (res) {
        setDeviceTypes(res.data.payload.results);
      }
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  async function createDevice(values: ICreateDevice) {
    try {
      setIsLoadingAction(true);
      const formData = {
        name: values.name,
        uniqueId: deviceUniqueIdCombined,
        deviceTypeId: values.deviceType,
        assetId: values.assetId,
        description: values.description,
        status: values.status ? "active" : "inactive",
      };

      console.log(formData);
      // return;

      await httpRequest.post<BaseResponseProps<DeviceProperties>>(
        "/device",
        formData
      );

      message.success(`Success add ${values.name}`);
      form.resetFields();
      navigate("/device");
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (deviceUniqueIdCombined) {
      const ids = deviceUniqueIdCombined.split("-");
      if (currentDeviceType === "flowmeter") {
        if (ids.length < 4) {
          message.warning("Flowmeter device unique id must be valid");
          return;
        }
      } else if (["rpm", "ae"].includes(currentDeviceType!)) {
        if (ids.length < 3) {
          message.warning(
            `${currentDeviceType!.toLocaleUpperCase()} device ID must be valid`
          );
          return;
        }
      } else {
        if (ids.length < 2) {
          message.warning(
            `${currentDeviceType!.toLocaleUpperCase()} device ID must be valid`
          );
          return;
        }
      }
    }

    createDevice(values);
  };

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        setIsLoadingAction(true);
        const response = await httpRequest.get<
          BaseResponsePaginationProps<AssetProperties>
        >("/asset");

        if (response.data.payload) {
          setAssets(response.data.payload.results);
        }
      } catch (error) {
        message.error(`Failed get data assets`);
      } finally {
        setIsLoadingAction(false);
      }
    };

    fetchAsset();
    getDeviceTypes();
  }, []);

  const assetMapped = React.useMemo(() => {
    return assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
  }, [assets]);

  const deviceTypeMapped = React.useMemo(() => {
    return Object.values(deviceTypes).map((item) => ({
      label: item.name,
      value: item.deviceTypeId,
    }));
  }, [deviceTypes]);

  const deviceUniqueIdCombined = useMemo(() => {
    if (deviceUniqueId) {
      return Object.values(deviceUniqueId)
        .filter((item) => item)
        .join("-");
    }
  }, [deviceUniqueId]);

  const prefix = useMemo(() => {
    return deviceUniqueId?.asset + "-" + deviceUniqueId?.deviceType;
  }, [deviceUniqueId]);

  function handleChangeAsset(e: string) {
    const found = assetMapped.find((item) => item.value === e);
    if (found) {
      setDeviceUniqueId((current) => ({
        ...current,
        asset: found.label.split(" ").join(""),
      }));
    }
  }

  function handleChangeDeviceType(e: string) {
    const found = deviceTypeMapped.find((item) => item.value === e);
    if (found) {
      setCurrentDeviceType(found.label.toLocaleLowerCase());
      if (found.label.toLocaleLowerCase() === "flowmeter") {
        setDeviceUniqueId((current) => ({
          ...current,
          deviceType: "fm",
        }));
      } else if (["rpm", "ae"].includes(found.label.toLocaleLowerCase())) {
        setDeviceUniqueId((current) => ({
          ...current,
          deviceType: found.label.toLocaleLowerCase(),
          flowType: "",
        }));
      } else {
        setDeviceUniqueId((current) => ({
          ...current,
          deviceType: found.label.toLocaleLowerCase(),
          location: "",
          flowType: "",
        }));
      }
    }
  }

  const renderDeviceUniqueID = () => {
    if (currentDeviceType === EDeviceType.FLOWMETER) {
      return (
        <Row>
          <CustomFormItemPer3 label="Prefix">
            <Input value={prefix} disabled />
          </CustomFormItemPer3>
          <CustomFormItemPer3 label="Location">
            <Select
              value={deviceUniqueId?.location}
              placeholder="Choose asset"
              style={{ width: "100%" }}
              onChange={(e) =>
                setDeviceUniqueId((current) => ({ ...current, location: e }))
              }
              options={[
                {
                  label: "PORT",
                  value: "1",
                },
                {
                  label: "STARBOARD",
                  value: "2",
                },
                {
                  label: "CENTER",
                  value: "3",
                },
              ]}
            />
          </CustomFormItemPer3>
          <CustomFormItemPer3 label="Flow type">
            <Select
              placeholder="Choose asset"
              style={{ width: "100%" }}
              onChange={(e) =>
                setDeviceUniqueId((current) => ({ ...current, flowType: e }))
              }
              value={deviceUniqueId?.flowType}
              options={[
                {
                  label: "IN",
                  value: "1",
                },
                {
                  label: "OUT",
                  value: "2",
                },
              ]}
            />
          </CustomFormItemPer3>
        </Row>
      );
    } else if (currentDeviceType && ["rpm", "ae"].includes(currentDeviceType)) {
      return (
        <Row>
          <CustomFormItemPer3 label="Prefix">
            <Input value={prefix} disabled />
          </CustomFormItemPer3>
          <CustomFormItemPer3 label="Location">
            <Select
              value={deviceUniqueId?.location}
              placeholder="Choose asset"
              style={{ width: "100%" }}
              onChange={(e) =>
                setDeviceUniqueId((current) => ({ ...current, location: e }))
              }
              options={[
                {
                  label: "PORT",
                  value: "1",
                },
                {
                  label: "STARBOARD",
                  value: "2",
                },
                {
                  label: "CENTER",
                  value: "3",
                },
              ]}
            />
          </CustomFormItemPer3>
        </Row>
      );
    } else {
      return;
    }
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon="back"
        title={"Add Device"}
        subtitle={"Create new device"}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        <Form
          form={form}
          name="profileForm"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <SectionContent
            groupTitle="Information"
            subTitle="Detail information about device"
          >
            <Row>
              <CustomFormItemPer3
                label="Asset"
                name="assetId"
                rules={generateFormRules("Asset", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose asset"
                  style={{ width: "100%" }}
                  options={assetMapped}
                  onChange={handleChangeAsset}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Device Type"
                name="deviceType"
                rules={generateFormRules("Device Type", ["required"])}
              >
                <Select
                  showSearch
                  placeholder="Choose device type"
                  style={{ width: "100%" }}
                  options={deviceTypeMapped}
                  onChange={handleChangeDeviceType}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer3>
              <CustomFormItemPer3
                label="Device Name"
                name="name"
                rules={generateFormRules("Device Name", ["required"])}
              >
                <Input placeholder="Input device name" />
              </CustomFormItemPer3>
            </Row>
            <Row>
              <CustomFormItemPer1 label="Description" name="description">
                <Input placeholder="Input device description" />
              </CustomFormItemPer1>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle="Device Unique ID"
            subTitle="Detail information about device"
          >
            {renderDeviceUniqueID()}
            <Row>
              <CustomFormItemPer1>
                <Alert
                  message={`Result Device Unique ID: ${deviceUniqueIdCombined}`}
                />
              </CustomFormItemPer1>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle="These are device information, you can change anything"
          >
            <CustomFormItemPer1
              label="Status"
              name="status"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch defaultChecked className="custom-switch" />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default DeviceCreate;
