import {
  MinusOutlined,
  PlusOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { useState } from "react";
import { useMap, useMapEvent, useMapEvents } from "react-leaflet";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

interface Props {
  showMapSettings?: boolean;
  handleOpenModalSettings: () => void;
}

const MapControl: React.FC<Props> = (props) => {
  const map = useMap();
  const path = useLocation();

  const increaseMapZoom = () => {
    const currZoom = map.getZoom();
    map.setZoom(currZoom + 1);
  };

  const decreaseMapZoom = () => {
    const currZoom = map.getZoom();
    map.setZoom(currZoom - 1);
  };

  return (
    <MapControlContainer path={path.pathname}>
      {/* Map Settings */}
      {props.showMapSettings && (
        <BtnIconGroup>
          <BtnIcon
            title="Map Settings"
            onClick={() => props.handleOpenModalSettings()}
          >
            <SettingOutlined style={{ fontSize: 14 }} />
          </BtnIcon>
        </BtnIconGroup>
      )}

      {/* Zoom Control */}
      <BtnIconGroup>
        <BtnIcon title="Zoom In Map" onClick={increaseMapZoom}>
          {/* <img src="/images/icon-add.svg" /> */}
          <PlusOutlined />
        </BtnIcon>
        <BtnIcon title="Zoom Out Map" onClick={decreaseMapZoom}>
          {/* <img src="/images/icon-remove.svg" /> */}
          <MinusOutlined />
        </BtnIcon>
      </BtnIconGroup>
    </MapControlContainer>
  );
};

const MapControlContainer = styled.div<{ path?: any }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  position: absolute;
  z-index: 991;
  bottom: ${(props) => (props.path === "/home" ? "90px" : "50px")};
  right: 12px;
`;

const BtnIconGroup = styled.div`
  border-radius: 2px;
  background-color: #e8ecf3;
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0px 2px rgb(0 0 0 / 20%);
`;

const BtnIcon = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 16px;
  }
`;

export default MapControl;
