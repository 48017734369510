import dayjs, { Dayjs } from 'dayjs';

export enum EFormatCurrentDate {
  DEFAULT = 'DD/MM/YY, HH:mm',
  DEFAULT_FULL_MONTH = 'DD MMMM YYYY, HH:mm',
  DEFAULT_SHORT_MONTH = 'DD MMM YYYY, HH:mm',
  SHORT = 'DD/MM/YY',
  TIME = 'HH:mm',
}

function formatCurrentDate(
  date: Date | string | number,
  format: EFormatCurrentDate
) {
  return dayjs(date).format(format);
}

export { formatCurrentDate };
