import {
  BaseResponseProps,
  DetailItem,
  HeaderSection,
  IAppTableColumn,
} from '@qlibs/react-components';
import { DeviceTypeProperties, initialDeviceType } from './types/device.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate, useParams } from 'react-router-dom';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import React from 'react';
import { httpRequest } from '../../helpers/api';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from 'antd';
import SectionContent from '../../components/SectionContent';
import { generateAddress } from '../../helpers/address';
import { ColumnsType } from 'antd/lib/table';

const moment = require('moment');

interface ILocation {
  deviceTypeId: string;
}

interface ResponseProps
  extends BaseResponseProps<
    Omit<DeviceTypeProperties, 'createdAt' | 'updatedAt' | 'companies'>
  > {}

const DeviceDetail = () => {
  const navigate = useNavigate();
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const { deviceTypeId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DeviceTypeProperties>({
    ...initialDeviceType,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>(
          '/device-type/' + deviceTypeId
        );
        if (!res) {
          message.error('Something went wrong');
          return;
        }

        setData({
          ...res.data.payload,
        });

        let label = `Detail Device Type`;
        const bcDetails = [
          {
            field: 'deviceTypeId',
            value: deviceTypeId,
            label,
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, [deviceTypeId]);

  const handleClickEdit = () => {
    navigate('/device-type/' + deviceTypeId + '/edit');
  };

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={'Detail Device Type'}
        subtitle={`Manage your device type data`}
        rightAction={
          <Space>
            <Button
              disabled={data.deletedAt ? true : false}
              type='primary'
              onClick={handleClickEdit}
            >
              Edit Device Type
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card>
          {data?.deletedAt ? (
            <Alert
              className='mb-5'
              type='warning'
              message='This data has been archived and cannot be edited'
            />
          ) : (
            false
          )}
          <SectionContent
            groupTitle='Information'
            subTitle='General information about this device type'
          >
            <Row>
              <Col span={12}>
                <DetailItem label='Name' value={data.name} />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Created by'
                  value={data?.userCreator?.name || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Created at'
                  value={moment(data.createdAt).format('DD MMMM YYYY, HH:mm')}
                  // type="datetime"
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Updated at'
                  value={moment(data.updatedAt).format('DD MMMM YYYY, HH:mm')}
                  // type="datetime"
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Status'
                  value={data.status.toUpperCase()}
                  type='tag'
                  _tagProps={{
                    color: data.status === 'active' ? 'success' : 'error',
                  }}
                />
              </Col>
            </Row>
          </SectionContent>

          <Divider />
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default DeviceDetail;
