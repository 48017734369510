import { forwardRef, ReactNode, useEffect, useRef } from "react";
import { Marker } from "react-leaflet";
import "leaflet-rotatedmarker";

interface MarkerProps {
  children?: ReactNode;
  rotationAngle: number;
  rotationOrigin: string;
  icon: any;
  position: any;
  onClick: () => void;
}
export type Ref = any;

const Markers = forwardRef<Ref, MarkerProps>((props, forwardRef: any) => {
  const markerRef: any = useRef();

  const { rotationAngle, rotationOrigin } = props;
  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker?.setRotationAngle(rotationAngle);
      marker?.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin]);

  return (
    <Marker
      eventHandlers={{
        click: () => {
          props.onClick();
        },
      }}
      ref={(ref) => {
        markerRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...props}
    >
      {props.children}
    </Marker>
  );
});

export default Markers;
