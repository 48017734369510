import React, { useEffect, useState } from "react";
import {
  AttributionControl,
  CircleMarker,
  FeatureGroup,
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import IconVesselOnline from "../../../assets/icons/dashboard/icon-vessel-online-map.png";
import IconVesselOffline from "../../../assets/icons/dashboard/icon-vessel-offline-map.png";
import IconVesselWaiting from "../../../assets/icons/dashboard/icon-vessel-waiting-map.png";
import L from "leaflet";
import { Image } from "antd";
import { toNM } from "../../report/components/helperFunction";
import { EditControl } from "react-leaflet-draw";
import MapControl from "./MapControl";
import Markers from "./Markers";
import moment from "moment";
import MapEventCoordinate from "./MapEventCoordinate";
import CoordinatePopup from "./CoordinatePopup";
import useMapSetting from "../../../zustand/useMap";
import styled from "styled-components";
import MapSettings from "./MapSettings";
import MapVesselPopup from "./MapVesselPopup";
import useGroup from "../../../zustand/useGroup";
import {
  getStatusEngine,
  getVesselStatus,
  VesselStatus,
} from "../../../helpers/vessel";

type MapType = {
  width?: string;
  height?: string;
  asset?: string;
  lastDataLog?: any;
  dataLogs?: any[];
  lastDataRPM?: any;
  vesselStatus?: VesselStatus;
  isEngineOn?: boolean;
  isMultipleAssets?: boolean;
  isShowMapSettings?: boolean;
};

const Map: React.FC<MapType> = ({
  width = "100%",
  height = "100vh",
  asset,
  lastDataLog,
  dataLogs,
  lastDataRPM,
  vesselStatus = VesselStatus.OFFLINE,
  isEngineOn = false,
  isMultipleAssets,
  isShowMapSettings,
}) => {
  const minZoom = 8;
  const maxZoom = 15;

  const { filter, dataSource } = useGroup();

  const [zoomLevel, setZoomLevel] = useState<number>(8);
  const [showModalSettings, setShowModalSettings] = useState<boolean>(false);

  const [filteredLogs, setFilteredLogs] = useState<any[]>([]);

  const {
    showVesselOnline,
    showVesselOffline,
    showVesselWaiting,
    showVesselLabel,
    showVesselTrail,
    showDotTrail,
    trailWidth,
    trailOpacity,
    mapType,
  } = useMapSetting();

  const handleOpenModalSettings = () => {
    setShowModalSettings(!showModalSettings);
  };

  const latLng: any = [
    lastDataLog?.gps_latitude ? lastDataLog?.gps_latitude : -5.869903075110989,
    lastDataLog?.gps_longitude
      ? lastDataLog?.gps_longitude
      : 105.75813825058138,
  ];

  const _renderMarkup = (assetName: string, vesselStatus: VesselStatus) => {
    return `
      <div style="display: flex; align-items: center; flex-direction: column;">
        ${
          showVesselOnline && vesselStatus === VesselStatus.ONLINE
            ? `<img src="${IconVesselOnline}" alt="Vessel Online" width="35" height="35" />`
            : ""
        }
        ${
          showVesselOffline && vesselStatus === VesselStatus.OFFLINE
            ? `<img src="${IconVesselOffline}" alt="Vessel Offline" width="35" height="35" />`
            : ""
        }
        ${
          showVesselWaiting && vesselStatus === VesselStatus.WAITING_DATA
            ? `<img src="${IconVesselWaiting}" alt="Vessel Waiting" width="35" height="35" />`
            : ""
        }

        ${
          vesselStatus === VesselStatus.ONLINE
            ? `
        <div style="width: 35px; height: ${
          showVesselOnline ? "35px" : "70px"
        }"></div>`
            : ""
        }

        ${
          vesselStatus === VesselStatus.OFFLINE
            ? `
        <div style="width: 35px; height: ${
          showVesselOffline ? "35px" : "70px"
        }"></div>`
            : ""
        }

        ${
          vesselStatus === VesselStatus.WAITING_DATA
            ? `
        <div style="width: 35px; height: ${
          showVesselWaiting ? "35px" : "70px"
        }"></div>`
            : ""
        }

        ${
          showVesselLabel
            ? `<div style="padding: 2px; border-radius: 5px; background-color: ${
                vesselStatus === VesselStatus.ONLINE
                  ? "#55C95A"
                  : vesselStatus === VesselStatus.OFFLINE
                  ? "#FE4545"
                  : "#A4660A"
              }; margin-top: -25px; display: inline-block; width: 100px; color: #fff; font-weight: bold; text-align: center;">
              ${assetName}
            </div>`
            : ""
        }
      </div>
    `;
  };

  const handleOnCreatePolyline = (e: any) => {
    const layer = e.layer;
    var coords = layer.getLatLngs();
    var length = 0;
    for (var i = 0; i < coords.length - 1; i++) {
      length += coords[i].distanceTo(coords[i + 1]);
    }
    e.layer.bindTooltip(`Total Distance: ${toNM(length)} NM`);
    e.layer.openTooltip();
  };

  const renderIcon = (assetName: string, vesselStatus: VesselStatus) => {
    const vesselIcon = new L.DivIcon({
      html: _renderMarkup(assetName, vesselStatus),
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
    });

    return vesselIcon;
  };

  const MapEventHandler = () => {
    const map = useMapEvents({
      zoomend: () => {
        setZoomLevel(map.getZoom());
      },
    });
    return null;
  };

  const filterDataByZoom = (dataLogs: any, zoom: number) => {
    if (!dataLogs || dataLogs.length === 0) return [];

    if (zoom < minZoom || zoom > maxZoom) {
      return [dataLogs[0], dataLogs[dataLogs.length - 1]];
    }

    const reductionFactor = Math.ceil(dataLogs.length / Math.pow(2, zoom - 8));

    const filteredData = dataLogs.filter((_: any, index: number) => {
      if (index === 0 || index === dataLogs.length - 1) return true;

      return index % reductionFactor === 0;
    });

    return filteredData;
  };

  useEffect(() => {
    if (!isMultipleAssets) {
      const res = filterDataByZoom(dataLogs, zoomLevel);
      setFilteredLogs(res);
    }
  }, [zoomLevel]);

  return (
    <div
      style={{
        width,
        height,
      }}
    >
      <MapContainer
        center={latLng}
        maxZoom={maxZoom}
        minZoom={minZoom}
        zoom={zoomLevel}
        scrollWheelZoom={true}
        zoomControl={false}
        attributionControl={false}
        style={{
          width,
          height,
        }}
      >
        <TileLayer
          // attribution={`${
          //   mapType === "Satellite"
          //     ? 'Imagery © <a href="https://www.esri.com/">Esri</a>'
          //     : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          // }`}
          url={`${
            mapType === "Satellite"
              ? "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
              : "https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
          }`}
        />

        {/* Marker */}
        {!isMultipleAssets &&
          lastDataLog.gps_latitude !== null &&
          lastDataLog.gps_latitude !== undefined &&
          lastDataLog.gps_latitude !== "-" && (
            <>
              <Markers
                rotationOrigin="center"
                rotationAngle={0}
                position={latLng}
                icon={renderIcon(asset as string, vesselStatus)}
                onClick={() => {
                  // handleClickMarker(item)
                }}
              >
                <Popup offset={[0, -15]} className="custom-popup">
                  <MapVesselPopup
                    assetId={filter.assetId}
                    timestamp={lastDataLog?.timestamp}
                    latitude={lastDataLog?.gps_latitude}
                    longitude={lastDataLog?.gps_longitude}
                    altitude={lastDataLog?.gps_altitude}
                    speed={lastDataLog?.gps_speed}
                    vesselStatus={vesselStatus}
                    isEngineOn={isEngineOn}
                  />
                </Popup>
              </Markers>

              {dataLogs && showVesselTrail && (
                <Polyline
                  // smoothFactor={2}
                  pathOptions={{
                    color:
                      vesselStatus === VesselStatus.ONLINE
                        ? "#55C95A"
                        : vesselStatus === VesselStatus.OFFLINE
                        ? "#FE4545"
                        : "#A4660A",
                    weight: trailWidth,
                    fillOpacity: trailOpacity,
                    opacity: trailOpacity,
                  }}
                  positions={(dataLogs as any)?.map((coord: any) => ({
                    lat: coord.gps_latitude || "",
                    lng: coord.gps_longitude || "",
                  }))}
                />
              )}

              {filteredLogs &&
                showDotTrail &&
                filteredLogs?.map((value: any, index: number) => {
                  const vesselStatus = getVesselStatus(value, dataSource);

                  if (value?.gps_latitude && value?.gps_longitude) {
                    return (
                      <>
                        <CircleMarker
                          key={index}
                          center={{
                            lat: value.gps_latitude,
                            lng: value.gps_longitude,
                          }}
                          radius={5}
                          fill
                          fillOpacity={1}
                          fillColor={"#ffffff"}
                          pathOptions={{
                            color:
                              vesselStatus === VesselStatus.ONLINE
                                ? "#55C95A"
                                : vesselStatus === VesselStatus.OFFLINE
                                ? "#FE4545"
                                : "#A4660A",
                          }}
                        >
                          <Popup offset={[0, -15]} className="custom-popup">
                            <CoordinatePopup
                              speed={value.gps_speed}
                              asset={String(asset)}
                              longitude={value.gps_longitude}
                              latitude={value.gps_latitude}
                              altitude={value.gps_altitude}
                              heading={value.gps_heading}
                              timestamp={value.timestamp}
                              distance={value.gps_distance}
                            />
                          </Popup>
                        </CircleMarker>
                      </>
                    );
                  }
                })}
            </>
          )}

        {/* Multiple Assets */}
        {isMultipleAssets && (
          <>
            {/* Multiple Markers */}
            {dataLogs?.map((value, index) => {
              if (value && typeof value === "object") {
                return Object.entries(value).map(([key, val]) => {
                  const data: any = val;
                  const latestData: any = Object.entries(data.report_logs)
                    .sort((a: any, b: any) => b[0] - a[0])
                    .map(([key, val]) => val)
                    .shift();
                  const vesselStatus = getVesselStatus(latestData, dataSource);
                  const isEngineOn = getStatusEngine(
                    lastDataRPM.starboard,
                    dataSource
                  );

                  // console.log(latestData);

                  if (latestData?.latitude && latestData?.longitude) {
                    return (
                      <>
                        <Markers
                          key={index}
                          rotationOrigin="center"
                          rotationAngle={0}
                          position={[latestData.latitude, latestData.longitude]}
                          icon={renderIcon(key, vesselStatus)}
                          onClick={() => {
                            // handleClickMarker(item)
                          }}
                        >
                          <Popup offset={[0, -15]} className="custom-popup">
                            <MapVesselPopup
                              assetId={filter.assetId}
                              timestamp={latestData.timestamp}
                              latitude={latestData.latitude}
                              longitude={latestData.longitude}
                              altitude={latestData.altitude}
                              speed={latestData.speed}
                              vesselStatus={vesselStatus}
                              isEngineOn={isEngineOn}
                            />
                          </Popup>
                        </Markers>
                      </>
                    );
                  } else {
                    console.log("Invalid coordinates for marker");
                    return null;
                  }
                });
              }
              return null;
            })}

            {/* Vessel Trail and Dot */}
            {dataLogs?.map((value, index) => {
              if (value && typeof value === "object") {
                return Object.entries(value).map(([key, val]) => {
                  const data: any = val;
                  const dataGPS: any = Object.entries(data.report_logs).map(
                    ([key, val]) => val
                  );

                  const isValidGPSData = dataGPS.every(
                    (item: any) =>
                      item.latitude !== null &&
                      item.latitude !== undefined &&
                      item.longitude !== null &&
                      item.longitude !== undefined
                  );

                  if (isValidGPSData) {
                    const newDataDot = filterDataByZoom(dataGPS, zoomLevel);

                    const vesselStatus = getVesselStatus(
                      dataGPS[dataGPS.length - 1],
                      dataSource
                    );

                    return (
                      <>
                        {showVesselTrail && (
                          <Polyline
                            // smoothFactor={2}
                            pathOptions={{
                              color:
                                vesselStatus === VesselStatus.ONLINE
                                  ? "#55C95A"
                                  : vesselStatus === VesselStatus.OFFLINE
                                  ? "#FE4545"
                                  : "#A4660A",
                              weight: trailWidth,
                              fillOpacity: trailOpacity,
                              opacity: trailOpacity,
                            }}
                            positions={dataGPS?.map((coord: any) => ({
                              lat: coord.latitude,
                              lng: coord.longitude,
                            }))}
                          />
                        )}
                        {showDotTrail &&
                          newDataDot?.map((coord: any, index: number) => {
                            const vesselStatus = getVesselStatus(
                              coord,
                              dataSource
                            );

                            return (
                              <CircleMarker
                                key={index}
                                center={{
                                  lat: coord.latitude,
                                  lng: coord.longitude,
                                }}
                                radius={5}
                                fill
                                fillOpacity={1}
                                fillColor={"#ffffff"}
                                pathOptions={{
                                  color:
                                    vesselStatus === VesselStatus.ONLINE
                                      ? "#55C95A"
                                      : vesselStatus === VesselStatus.OFFLINE
                                      ? "#FE4545"
                                      : "#A4660A",
                                }}
                              >
                                <Popup
                                  offset={[0, -15]}
                                  className="custom-popup"
                                >
                                  <CoordinatePopup
                                    speed={coord.speed}
                                    asset={key}
                                    longitude={coord.longitude}
                                    latitude={coord.latitude}
                                    altitude={coord.altitude}
                                    heading={coord.heading}
                                    timestamp={coord.timestamp}
                                    distance={coord.distance}
                                  />
                                </Popup>
                              </CircleMarker>
                            );
                          })}
                      </>
                    );
                  }
                });
              }
              return null;
            })}
          </>
        )}

        {/* Map Control */}
        <FeatureGroup>
          <div>
            <EditControl
              draw={{
                polyline: {
                  shapeOptions: {
                    color: "yellow",
                  },
                },
                polygon: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
              onCreated={(e) => handleOnCreatePolyline(e)}
              position="topright"
            />
          </div>
        </FeatureGroup>

        <MapControl
          handleOpenModalSettings={handleOpenModalSettings}
          showMapSettings={isShowMapSettings}
        />

        {/* Map Attribution */}
        <AttributionControl
          position="bottomright"
          prefix={`© ${moment().year()} RAMUS 4.0 CMS`}
        />

        <MapEventCoordinate showSummary={false} />

        <MapEventHandler />
      </MapContainer>

      {/* Modal Map Setting */}
      {showModalSettings && (
        <ModalSettings>
          <TitleModal>Map Settings</TitleModal>
          <MapSettingsWrapper>
            <MapSettings />
          </MapSettingsWrapper>
        </ModalSettings>
      )}
    </div>
  );
};

const ModalSettings = styled.div`
  width: 320px;
  border-radius: 8px;
  position: absolute;
  z-index: 990;
  bottom: 50px;
  right: 60px;
  max-height: 820px;
  overflow-y: auto;
  background: white;
  height: 340px;
`;

const TitleModal = styled.div`
  background-color: #e8ecf3;
  padding: 0 15px;
  height: 50px;
  color: #3f4c5f;
  font-weight: 600;
  display: flex;
  font-size: 16px;
  align-items: center;
`;

const MapSettingsWrapper = styled.div`
  padding: 0 15px;
`;

export default Map;
