import { useState } from 'react';
import {
  ILogReport,
  ILogReportFilter,
} from '../screens/report/types/report-log.type';
import { httpRequest } from '../helpers/api';
import { generateQueryString, IHttpResponse } from '@qlibs/react-components';
import { createGlobalState } from 'react-hooks-global-state';

interface IReportLog {
  loadingLog: boolean;
  loadingDevice: boolean;
  showReportTableConfig: boolean;
  showReportDownloadConfig: boolean;
  checklistColumn: any[];
  dataCompletion: any[];
  logs: ILogReport;
}

const initialState: IReportLog = {
  loadingLog: false,
  loadingDevice: false,
  showReportTableConfig: false,
  showReportDownloadConfig: false,
  checklistColumn: [],
  dataCompletion: [],
  logs: {},
};

const { useGlobalState } = createGlobalState(initialState);

const useReportLog = () => {
  const [logs, setLogs] = useGlobalState('logs');
  const [loadingLog, setLoadingLog] = useGlobalState('loadingLog');
  const [loadingDevice, setLoadingDevice] = useGlobalState('loadingDevice');
  const [showReportTableConfig, setShowReportTableConfig] = useGlobalState(
    'showReportTableConfig'
  );
  const [showReportDownloadConfig, setShowReportDownloadConfig] =
    useGlobalState('showReportDownloadConfig');
  const [checklistColumn, setChecklistColumn] =
    useGlobalState('checklistColumn');
  const [dataCompletion, setDataCompletion] = useGlobalState('dataCompletion');

  return {
    logs,
    loadingLog,
    setLogs,
    setShowReportTableConfig,
    showReportTableConfig,
    checklistColumn,
    setChecklistColumn,
    setDataCompletion,
    dataCompletion,
    setLoadingLog,
    setShowReportDownloadConfig,
    showReportDownloadConfig,
    loadingDevice,
    setLoadingDevice,
  };
};

export default useReportLog;
