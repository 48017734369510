import { Spin } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useAuthApp from '../../../hooks/useAuthApp';
import {
  capitalizeFirstLetter,
  generateQueryString,
  IHttpResponse,
} from '@qlibs/react-components';
import { httpRequest } from '../../../helpers/api';
import useReportLog from '../../../hooks/useReportLog';
import {
  EReportDataInterval,
  ILogReport,
  ILogReportFilter,
} from '../types/report-log.type';
import { InfoOutlined } from '@ant-design/icons';
import SummaryTable from '../components/summaryTable';
import ChartRpmFuelSpeedRunning from '../components/charts/chartRpmFuelSpeedRunning';
import ChartFuelCons from '../components/charts/chartFuelCons';
import ChartAERunningTime from '../components/charts/chartAERunningTime';
import PageContent from '../components/dailyReport/pageContent';
import VesselTrackingStatic from '../components/vesselTrackingStatic';

type DailyReportType = {
  currentPath?: any;
};

const DailyReport: React.FC<DailyReportType> = ({ currentPath }) => {
  const { doLogin } = useAuthApp();

  const { setLoadingLog, setLogs, loadingLog, logs } = useReportLog();

  const getLogReport = async (params: ILogReportFilter) => {
    try {
      setLoadingLog(true);
      const res = await httpRequest.get<IHttpResponse<ILogReport>>(
        `report-log${generateQueryString(params)}`
      );

      if (res.data.payload) {
        setLogs(res.data.payload);
      }
    } catch (error) {
      console.error('failed get report log', error);
    } finally {
      setLoadingLog(false);
    }
  };

  const [deviceType, setDeviceType] = useState<string>('');
  const [assetId, setAssetId] = useState<string>('');
  const [interval, setInterval] = useState<string>('');
  const [startAt, setStartAt] = useState<string>();
  const [endAt, setEndAt] = useState<string>();
  const [currDataDownloaded, setCurrDataDownloaded] = useState<any>();

  async function generateContent() {
    const searchParams = new URLSearchParams(document.location.search);
    const scheduleReport = searchParams.get('scheduleReport');
    const user = searchParams.get('u');
    const pass = searchParams.get('p');
    const assetId = searchParams.get('assetId');
    const startAt = searchParams.get('startAt');
    const endAt = searchParams.get('endAt');
    const deviceType = searchParams.get('deviceType');
    const timezone = searchParams.get('timezone');
    const interval = searchParams.get('interval');
    const dataDownloaded = searchParams.get('dataDownloaded');

    if (dataDownloaded) {
      setCurrDataDownloaded(dataDownloaded);
    }
    if (
      scheduleReport &&
      scheduleReport === '1' &&
      assetId &&
      startAt &&
      endAt &&
      deviceType &&
      timezone &&
      interval
    ) {
      setDeviceType(deviceType);
      setAssetId(assetId);
      setInterval(interval);
      setStartAt(startAt);
      setEndAt(endAt);
      await getLogReport({
        ...{
          deviceType,
          assetId,
          interval,
          startAt: Number(startAt),
          endAt: Number(endAt),
          timezone: Number(timezone),
        },
        interval: EReportDataInterval.hour,
      });
    } else {
      console.log('something wrong');
    }
  }

  useEffect(() => {
    generateContent();
  }, []);

  const getFlowmeterChild = useMemo(() => {
    let data: string[] = [];

    if (currDataDownloaded) {
      data = currDataDownloaded
        .split(',')
        .filter((item: string) => item.includes('fuel_consumption_running'))
        .map((item: string) => {
          const key = item.split('_');
          return key[0];
        });
    }

    return data;
  }, [currDataDownloaded]);

  if (loadingLog) {
    return (
      <ContainerLoading>
        <LoadingWrapper>
          <Spin />
          <div>Loading data...</div>
        </LoadingWrapper>
      </ContainerLoading>
    );
  } else if (
    logs &&
    Object.keys(logs).length &&
    currDataDownloaded &&
    currDataDownloaded.length
  ) {
    return (
      <React.Fragment>
        {currDataDownloaded.includes('summary') && (
          <PageContent title='Summary'>
            <SummaryTable isReportSchedule={true} />
          </PageContent>
        )}

        {currDataDownloaded.includes('vessel_tracking') && (
          <PageContent title='Vessel Tracking'>
            <VesselTrackingStatic />
          </PageContent>
        )}

        {deviceType &&
          deviceType.split(',').map((item, idx) => {
            if (item === 'flowmeter') {
              return (
                <div key={idx}>
                  {currDataDownloaded.includes('rpm_fuel_speed_ae') && (
                    <PageContent title='RPM vs Fuel Cons. vs Speed vs AE Running Time'>
                      <ChartRpmFuelSpeedRunning
                        isReportSchedule={{
                          startAt: Number(startAt),
                          endAt: Number(endAt),
                          interval,
                          deviceType,
                          assetId,
                        }}
                      />
                    </PageContent>
                  )}

                  {currDataDownloaded.includes('me_fuel_consumption') && (
                    <PageContent title='ME Fuel Consumption'>
                      <ChartFuelCons
                        chartType='me'
                        isReportSchedule={{
                          startAt: Number(startAt),
                          endAt: Number(endAt),
                          interval,
                          deviceType,
                          assetId,
                        }}
                      />
                    </PageContent>
                  )}

                  {getFlowmeterChild.map((item, idx) => (
                    <PageContent
                      key={idx}
                      title={`${capitalizeFirstLetter(
                        item
                      )} Fuel Cons. vs Running
                            Time`}
                    >
                      <ChartFuelCons
                        chartType={item}
                        isReportSchedule={{
                          startAt: Number(startAt),
                          endAt: Number(endAt),
                          interval,
                          deviceType,
                          assetId,
                        }}
                      />
                    </PageContent>
                  ))}
                </div>
              );
            } else if (
              ['rpm'].includes(item) &&
              currDataDownloaded.includes('rpm_speed_ae')
            ) {
              return (
                <PageContent title='RPM vs Speed vs AE Running Time'>
                  <ChartRpmFuelSpeedRunning
                    isReportSchedule={{
                      startAt: Number(startAt),
                      endAt: Number(endAt),
                      interval,
                      deviceType,
                      assetId,
                    }}
                  />
                </PageContent>
              );
            }
          })}

        <PageContent title='AE Running Time'>
          <ChartAERunningTime
            isReportSchedule={{
              startAt: Number(startAt),
              endAt: Number(endAt),
              interval,
              deviceType,
              assetId,
            }}
          />
        </PageContent>
      </React.Fragment>
    );
  } else {
    return (
      <ContainerLoading>
        <LoadingWrapper>
          <InfoOutlined />
          <div>Something wrong</div>
        </LoadingWrapper>
      </ContainerLoading>
    );
  }
};

export const ContainerLoading = styled.div`
  background: ${(props) => props.theme.colors.ash300};
  height: calc(100vh);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;

export const TitleReport = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

export default DailyReport;
