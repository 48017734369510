import {
  BaseResponsePaginationProps,
  BaseResponseProps,
  HeaderSection,
  generateFormRules,
  generateQueryString,
  getErrorMessage,
  getStandardError,
} from '@qlibs/react-components';
import {
  Alert,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomFormItemPer1,
  CustomFormItemPer2,
} from '../../user/components/CustomFormItem';
import { httpRequest } from '../../../helpers/api';
import SectionContent from '../../../components/SectionContent';
import { AssetProperties } from '../../asset/types/asset.type';
import {
  ICreateReportEmailReceiver,
  ReportEmailReceiverProperties,
} from '../types/report.type';
import useMasterData from '../../../hooks/useMasterData';
import { UserProperties } from '../../../services/openapi';

interface ILocation {
  reportEmailReceiverId: string;
}

const MasterReportEmailReceiverEdit = () => {
  const navigate = useNavigate();
  const { reportEmailReceiverId } = useParams<keyof ILocation>() as ILocation;
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [data, setData] = useState<ReportEmailReceiverProperties>();
  const [users, setUsers] = useState<UserProperties[]>([]);
  const [companyId, setCompanyId] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [form] = Form.useForm();

  const { companies, getListCompanies } = useMasterData();

  async function updateReportEmailReceiver(values: ICreateReportEmailReceiver) {
    try {
      setIsLoadingAction(true);
      const formData = {
        userId,
        companyId: values.companyId,
        status: values.status ? 'active' : 'inactive',
      };

      if (reportEmailReceiverId) {
        await httpRequest.patch<
          BaseResponseProps<ReportEmailReceiverProperties>
        >('/report-email-receiver/' + reportEmailReceiverId, formData);
      } else {
        await httpRequest.post<
          BaseResponseProps<ReportEmailReceiverProperties>
        >('/report-email-receiver', formData);
      }

      message.success(`Success ${reportEmailReceiverId ? 'edit' : 'add'}`);
      form.resetFields();
      setData(undefined);
      // navigate("/master-report?activeTab=2");
      navigate('/master-report');
    } catch (error) {
      getStandardError(error, {
        triggerFormValidation: true,
        formRef: form,
        showToast: true,
      });
    } finally {
      setIsLoadingAction(false);
    }
  }

  const handleSubmit = async (values: any) => {
    if (data?.deletedAt) {
      message.warning('This data has been archived and cannot be edited');
    } else {
      updateReportEmailReceiver(values);
    }
  };

  async function fetchData(reportEmailReceiverId: string) {
    try {
      const response = await httpRequest.get<
        BaseResponseProps<ReportEmailReceiverProperties>
      >('/report-email-receiver/' + reportEmailReceiverId);
      if (response.data.payload) {
        const data = response.data.payload;

        setData(data);
        setUserId(data.userId);
        setCompanyId(data.companyId);

        const formData = {
          ...data,
          userId: data.user?.email,
          companyId: data.companyId,
          status: data.status === 'active' ? true : false,
        };
        form.setFieldsValue(formData);
      }
    } catch (error) {
      message.error(`Failed get report email receiver`);
      console.error(error);
    } finally {
      setIsLoadingAction(false);
    }
  }

  const companyMapped = React.useMemo(() => {
    return companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
  }, [companies]);

  const userMapped = React.useMemo(() => {
    return users.map((item) => ({
      value: item.userId,
      label: item.email,
    }));
  }, [users]);

  useEffect(() => {
    if (reportEmailReceiverId) {
      fetchData(reportEmailReceiverId);
    }
  }, [reportEmailReceiverId]);

  useEffect(() => {
    if (companyId) {
      const fetchDataUser = async () => {
        try {
          const params = {
            companyId,
          };

          const res = await httpRequest.get(
            `/users${generateQueryString(params)}`
          );

          if (res.data.payload.results) {
            setUsers(res.data.payload.results);
          }
        } catch (error) {
          console.error(getErrorMessage(error));
        }
      };

      fetchDataUser();
    }
  }, [companyId]);

  useEffect(() => {
    getListCompanies();
  }, []);

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={`${
          reportEmailReceiverId ? 'Update' : 'Add'
        } Master Report Email Receiver`}
        subtitle={`${
          reportEmailReceiverId ? 'Update' : 'Add'
        } current master report email receiver`}
        showCancelButton
        showSaveButton
        isLoading={isLoadingAction}
        onSave={() => form.submit()}
      />

      <Card>
        {data?.deletedAt ? (
          <Alert
            className='mb-5'
            type='warning'
            message='This data has been archived and cannot be edited'
          />
        ) : (
          false
        )}
        <Form
          form={form}
          name='profileForm'
          layout='vertical'
          onFinish={handleSubmit}
          autoComplete='off'
        >
          <SectionContent
            groupTitle='Information'
            subTitle='Detail information about email receiver'
          >
            <Row>
              <CustomFormItemPer2
                label='Company'
                name='companyId'
                rules={generateFormRules('Company', ['required'])}
              >
                <Select
                  showSearch
                  placeholder='Choose company'
                  style={{ width: '100%' }}
                  options={companyMapped}
                  onChange={(e) => {
                    setCompanyId(e);
                    form.setFieldValue('userId', undefined);
                    setUserId('');
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
              <CustomFormItemPer2
                label='User'
                name='userId'
                rules={generateFormRules('User', ['required'])}
              >
                <Select
                  showSearch
                  placeholder='Choose user'
                  style={{ width: '100%' }}
                  disabled={companyId ? false : true}
                  options={userMapped}
                  onChange={(e) => setUserId(e)}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </CustomFormItemPer2>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle={`Setting`}
            subTitle='These are device information, you can change anything'
          >
            <CustomFormItemPer1
              label='Status'
              name='status'
              valuePropName='checked'
              initialValue={true}
            >
              <Switch defaultChecked className='custom-switch' />
            </CustomFormItemPer1>
          </SectionContent>
        </Form>
      </Card>
    </React.Fragment>
  );
};

export default MasterReportEmailReceiverEdit;
