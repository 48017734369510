import { useMemo } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import useGroup from '../../../../zustand/useGroup';
import ReportChart from '../reportChart';
import { EReportDataInterval } from '../../types/report-log.type';
import {
  generateDataSeriesTable,
  generateTimestampData,
} from '../helperFunction';

interface IProps {
  chartType: string;
  isReportSchedule?: {
    startAt: number;
    endAt: number;
    interval: any;
    deviceType: string;
    assetId: string;
  };
}

const ChartFuelCons: React.FC<IProps> = ({ chartType, isReportSchedule }) => {
  const { logs } = useReportLog();
  const { filter } = useGroup();

  const schema = useMemo(() => {
    return [
      {
        logKey: 'flowmeter',
        dataKeys: ['deviceCalculation', 'portIn', 'fuelCons'],
        seriesOption: {
          name: 'Port Fuel Cons.',
          type: 'spline',
          yAxis: 1,
          zIndex: 1,
          marker: {
            enabled: false,
            fillColor: 'rgb(255, 196, 0)',
          },
          lineColor: 'rgb(255, 196, 0)',
          fillColor: 'rgba(255, 187, 0, 0.26)',
          tooltip: {
            valueSuffix: ' liter',
          },
        },
      },
      {
        logKey: 'flowmeter',
        dataKeys: ['deviceCalculation', 'starboardIn', 'fuelCons'],
        seriesOption: {
          name: 'Starboard Fuel Cons.',
          type: 'spline',
          yAxis: 1,
          zIndex: 1,
          marker: {
            enabled: false,
            fillColor: 'rgb(88, 148, 238)',
          },
          lineColor: 'rgb(88, 148, 238)',
          fillColor: 'rgba(0, 102, 255, 0.26)',
          tooltip: {
            valueSuffix: ' liter',
          },
        },
      },
      {
        logKey: 'rpm',
        dataKeys: ['port', 'rpm'],
        seriesOption: {
          name: 'Port RPM',
          type: 'spline',
          yAxis: 0,
          zIndex: 3,
          marker: {
            enabled: false,
            fillColor: 'rgb(111, 87, 233)',
          },
          lineColor: 'rgb(111, 87, 233)',
        },
      },
      {
        logKey: 'rpm',
        dataKeys: ['starboard', 'rpm'],
        seriesOption: {
          name: 'Starboard RPM',
          type: 'spline',
          yAxis: 0,
          zIndex: 3,
          marker: {
            enabled: false,
            fillColor: 'rgb(2, 204, 204)',
          },
          lineColor: 'rgb(2, 204, 204)',
        },
      },
      {
        logKey: 'gps',
        dataKeys: ['speed'],
        seriesOption: {
          name: 'Speed',
          type: 'spline',
          yAxis: 2,
          zIndex: 4,
          marker: {
            enabled: false,
            fillColor: 'rgb(243, 69, 132)',
          },
          lineColor: 'rgb(243, 69, 132)',
          tooltip: {
            valueSuffix: ' knot',
          },
        },
      },
    ]
      .filter((item) => {
        if (chartType !== 'me') {
          return item.seriesOption.name.toLocaleLowerCase().includes(chartType);
        } else {
          return item;
        }
      })
      .map((item) => item);
  }, [chartType]);

  const dataTable = useMemo(() => {
    const dataSeriesTable: any[] = [[...Array(24).keys()]];
    let intervalInSecond = 0;

    if (isReportSchedule?.interval === EReportDataInterval.minute) {
      intervalInSecond = 60;
    } else if (isReportSchedule?.interval === EReportDataInterval.hour) {
      intervalInSecond = 60 * 60;
    } else {
      intervalInSecond = 60 * 60 * 24;
    }

    const timestamps = generateTimestampData({
      startTime: isReportSchedule?.startAt!,
      endTime: isReportSchedule?.endAt!,
      interval: isReportSchedule?.interval,
      intervalInSecond,
    });

    schema.forEach((schema) => {
      if ((logs as any)[schema.logKey]) {
        const res = generateDataSeriesTable({
          timestamps,
          key: schema.dataKeys,
          fm: (logs as any)[schema.logKey],
        });

        dataSeriesTable.push(res);
      }
    });

    return dataSeriesTable;
  }, [schema]);

  const tableColumns = useMemo(() => {
    let res: any[] = ['Time'];
    schema.forEach((item) => {
      res.push(item.seriesOption.name);
    });

    return res;
  }, [schema]);

  return (
    <>
      <ReportChart
        logs={logs}
        filter={isReportSchedule || filter}
        yAxis={['fuel', 'rpm', 'time']}
        chartSchema={schema}
        isReportSchedule={
          isReportSchedule && isReportSchedule.deviceType ? true : false
        }
      />
      {isReportSchedule && tableColumns.length && (
        <table className='table-app'>
          <tbody>
            {dataTable.map((data, idx) => (
              <tr key={idx}>
                <td>{tableColumns[idx]}</td>
                {data.map((item: number | undefined, idx2: number) => (
                  <td key={idx2}>
                    {typeof item === 'undefined'
                      ? '-'
                      : idx === 0
                      ? item
                      : item?.toFixed(2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ChartFuelCons;
