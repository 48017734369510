import { useNavigate } from 'react-router-dom';
import usePermission from '../../hooks/usePermission';
import React, { useEffect } from 'react';
import {
  HeaderSection,
  AppTable,
  useFetchList,
  PAGE_SIZE_OPTIONS,
  IAppTableColumn,
} from '@qlibs/react-components';
import RowFilter from '@qlibs/react-components/dist/Table/RowFilter';
import { Button, Modal, Tag, message } from 'antd';
import { GroupProperties } from './types/group.type';
import { httpRequest } from '../../helpers/api';
import { EDateFormat, SUPER_ADMIN_ROLE_ID } from '../../const/config';
import LinkTable from '../../components/LinkTable';
import { useAuthUser } from 'react-auth-kit';
import useGroup from '../../zustand/useGroup';
import {
  formatCurrentDate,
  EFormatCurrentDate,
} from '../../helpers/formatCurrentDate';
const moment = require('moment');

const FEATURE = 'GROUP';

const Group = () => {
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();
  const auth = useAuthUser();
  const { group } = useGroup();

  const roleId = auth()?.role?.roleId;

  const handleCreateGroup = () => {
    navigate({ pathname: '/group/add' });
  };

  const [isModalDeleteOpen, setIsModalDeleteOpen] = React.useState(false);
  const [isModalRestoreOpen, setIsModalRestoreOpen] = React.useState(false);
  const [currentData, setCurrentData] = React.useState<GroupProperties>();
  const [forceDeleteId, setForceDeleteId] = React.useState<string>();

  const {
    isLoading,
    data,
    pagination,
    query,
    setData,
    setQuery,
    changePage,
    changeLimit,
    handleSearch,
    pageQueries,
    filterDropdown,
  } = useFetchList<GroupProperties>({
    httpRequest: httpRequest as any,
    endpoint: 'group',
    limit: +PAGE_SIZE_OPTIONS[1],
    initialQuery: {
      // groupIds: roleId !== SUPER_ADMIN_ROLE_ID ? [group?.groupId] : undefined,
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  async function deleteData() {
    try {
      if (currentData) {
        const forceDelete =
          forceDeleteId && forceDeleteId === currentData.groupId;
        await httpRequest.delete('/group/' + currentData.groupId, {
          params: {
            forceDelete: forceDelete || undefined,
          },
        });
        message.success(
          `Success ${forceDelete ? 'delete' : 'archive'} ${currentData.name}`
        );

        setQuery({
          limit: +PAGE_SIZE_OPTIONS[1],
        });
      } else {
        message.error('Current data not found');
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalDeleteOpen(false);
      setCurrentData(undefined);
      setForceDeleteId(undefined);
    }
  }

  async function restoreData() {
    try {
      if (currentData) {
        await httpRequest.patch('/group/' + currentData.groupId + '/restore');
        message.success(`Success restore ${currentData.name}`);

        setQuery({
          limit: +PAGE_SIZE_OPTIONS[1],
        });
      } else {
        message.error('Current data not found');
      }
    } catch (error: any) {
      message.error(error.data.message);
    } finally {
      setIsModalRestoreOpen(false);
      setCurrentData(undefined);
      setForceDeleteId(undefined);
    }
  }

  async function handleDeleteGroup(id: any, forced?: boolean) {
    setIsModalDeleteOpen(true);
    const found = data.find((item) => item.groupId === id);
    if (found) {
      setCurrentData(found);
    }

    if (forced) {
      setForceDeleteId(id);
    }
  }

  async function handleRestoreGroup(id: any) {
    setIsModalRestoreOpen(true);
    const found = data.find((item) => item.groupId === id);
    if (found) {
      setCurrentData(found);
    }
  }

  function handleCancelDelete() {
    setIsModalDeleteOpen(false);
    setIsModalRestoreOpen(false);
    setCurrentData(undefined);
  }

  function isArchived(id?: string) {
    if (id) {
      const found = data.find((item) => item.groupId === id && item.deletedAt);
      if (found) {
        return true;
      }
    }

    return false;
  }

  const columns: IAppTableColumn<GroupProperties>[] = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      type: 'detail',
      keyId: 'groupId',
      render: (_, records) => (
        <LinkTable onPress={() => navigate('/group/' + records.groupId)}>
          {records.deletedAt ? (
            <Tag color='#ff5500' className='mb-2'>
              Archived
            </Tag>
          ) : (
            false
          )}
          ({records.nickname}) {records.name}
        </LinkTable>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      render: (value, _) => (
        <Tag color={value === 'active' ? 'success' : 'error'}>
          {value.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'COMPANIES',
      dataIndex: 'companies',
      key: 'companies',
      render: (companies, _) => (companies ? companies.length : 0),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value, _) =>
        formatCurrentDate(value, EFormatCurrentDate.DEFAULT_SHORT_MONTH),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (value, _) =>
        formatCurrentDate(value, EFormatCurrentDate.DEFAULT_SHORT_MONTH),
    },
    {
      title: 'ACTION',
      key: 'action',
      type: 'actions',
      actions: [
        isUserHasPermission([FEATURE + '.DETAIL'])
          ? {
              key: 'detail',
              label: 'Detail',
              onPress: (id) => navigate('/group/' + id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.UPDATE'])
          ? {
              key: 'update',
              label: 'Update',
              onPress: (id) => navigate('/group/' + id + '/edit'),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.DELETE'])
          ? {
              key: 'archive',
              label: 'Delete',
              color: '#ff4d4f',
              disabled: (id) => isArchived(id),
              disabledTooltip: 'This data is already archived',
              onPress: (id) => handleDeleteGroup(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.RESTORE'])
          ? {
              key: 'restore',
              label: 'Restore',
              color: '#1890ff',
              disabled: (id) => !isArchived(id),
              disabledTooltip: 'This data not in archived',
              onPress: (id) => handleRestoreGroup(id),
            }
          : undefined,
        isUserHasPermission([FEATURE + '.FORCE_DELETE'])
          ? {
              key: 'delete',
              label: 'Force Delete',
              onPress: (id) => handleDeleteGroup(id, true),
            }
          : undefined,
      ],
    },
  ];

  const filterData: any = [
    {
      type: 'search',
      key: 'search',
      label: 'Search',
      placeholder: 'Search by group name',
      onChange: (value: any) => {
        handleSearch(value);
      },
      colSpan: isUserHasPermission([FEATURE + '.DELETE']) ? 12 : 18,
    },
    {
      type: 'select',
      key: 'status',
      label: 'Status',
      options: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        {
          value: 'inactive',
          label: 'Inactive',
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterStatus: value === 'all' ? '' : value,
        });
      },
      colSpan: 6,
    },
    {
      type: 'select',
      key: 'filterData',
      label: 'Filter Data',
      options: [
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'show',
          label: 'Include Archive Data',
        },
      ],
      onChange: (value: any) => {
        filterDropdown({
          filterArchived: value === 'default' ? '' : value,
        });
      },
      colSpan: 6,
    },
  ].filter((item) =>
    !isUserHasPermission([FEATURE + '.DELETE'])
      ? item.key !== 'filterData'
      : item
  );

  return (
    <React.Fragment>
      <HeaderSection
        title={'Group'}
        rightAction={[
          isUserHasPermission([FEATURE + '.CREATE']) ? (
            <Button type='primary' onClick={handleCreateGroup}>
              Add Group
            </Button>
          ) : (
            false
          ),
        ]}
        subtitle='Manage data group that consist of multiple companies'
      />

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || 'all',
          filterData: pageQueries.filterData || 'default',
        }}
        filters={[filterData]}
      />

      <AppTable
        isLoading={isLoading}
        keyId='groupId'
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />

      <Modal
        title={
          forceDeleteId ? 'Force Delete Confirmation' : 'Delete Confirmation'
        }
        open={isModalDeleteOpen}
        onOk={deleteData}
        okButtonProps={{
          style: {
            background: 'red',
          },
        }}
        onCancel={handleCancelDelete}
      >
        {forceDeleteId ? (
          <p>
            Are you sure want to permanently delete this data? This data will be
            deleted and only can't be undo.
          </p>
        ) : (
          <p>
            Are you sure want to delete this data? This data will be archived
            and only User with restore permission that can undo this action.
          </p>
        )}
      </Modal>

      <Modal
        title='Restore Confirmation'
        open={isModalRestoreOpen}
        onOk={restoreData}
        okButtonProps={{
          style: {
            background: 'blue',
          },
        }}
        onCancel={handleCancelDelete}
      >
        <p>Are you sure want to restore this data?.</p>
      </Modal>
    </React.Fragment>
  );
};
export default Group;
