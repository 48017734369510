import { Button, Image, Spin, Switch, Tag } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import {
  degreesToRadians,
  latitudeFormat,
  longitudeFormat,
} from '../../../helpers/map-util';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingWrapper } from '../../report/dailyReport';
import useMasterData from '../../../hooks/useMasterData';
import { AssetProperties, initialAsset } from '../../asset/types/asset.type';
import useGroup from '../../../zustand/useGroup';
import { VesselStatus } from '../../../helpers/vessel';
import { toKnot } from '../../report/components/helperFunction';

interface IProps {
  assetId?: string;
  timestamp: any;
  latitude: number;
  longitude: number;
  altitude: number;
  track?: string;
  speed: string;
  vesselStatus?: VesselStatus;
  isEngineOn?: boolean;
}

const MapVesselPopup: React.FC<IProps> = ({
  assetId,
  timestamp,
  latitude,
  longitude,
  altitude,
  track,
  speed,
  vesselStatus = VesselStatus.OFFLINE,
  isEngineOn = false,
}) => {
  const start = moment().startOf('day').valueOf();
  const end = moment().valueOf();
  const [tmpToggle, setTmpToggle] = useState<boolean>(false);
  const [asset, setAsset] = useState<any>(initialAsset);
  const [imageLink, setImageLink] = useState<string>('');

  const { filter, setFilter } = useGroup();
  const { loadingAsset, assets, getListAssets } = useMasterData();

  function onClickReport() {
    setFilter({
      ...filter,
      assetId: assetId as string,
    });

    window.open('/report-log', '_blank');
  }

  function onClickDashboard() {
    setFilter({
      ...filter,
      assetId: assetId as string,
    });

    window.open('/dashboard', '_blank');
  }

  const [reRender, setReRender] = useState<number>(0);

  // async function handleToggleTrail() {
  //   try {
  //     if (asset.massDevice) {
  //       const currDevcMassId = asset.massDevice.find(
  //         (item: any) => item.devcType === "gps"
  //       )?.devcMassId;

  //       if (currDevcMassId) {
  //         await fetchCurrentAssetTrail({
  //           massId: asset.massId,
  //           devcMassId: currDevcMassId,
  //           devcType: "gps",
  //         });

  //         setTmpToggle((old) => !old);
  //         setReRender((old) => old + 1);
  //       } else {
  //         console.log("data devcMassId not found");
  //       }
  //     }
  //   } catch (error) {
  //     console.error("failed show asset trail", error);
  //   }
  // }

  // useEffect untuk mengrerender ulang popup
  useEffect(() => {}, [reRender]);

  const convertTimestampToDate = (timestamp?: number) => {
    if (timestamp) {
      return moment(timestamp * 1000).format('DD MMM YYYY HH:mm');
    }

    return '-';
  };

  // useEffect(() => {
  //   setTmpToggle(asset.showTrail);
  // }, [asset]);

  useEffect(() => {
    getListAssets();
  }, []);

  useEffect(() => {
    if (assets) {
      const asset: any = assets.find((value) => value.assetId === assetId);
      setReRender((old) => old + 1);
      console.log(asset);
      setAsset(asset);
    }
  }, [assets]);

  return (
    <Container className='custom-popup'>
      {loadingAsset && !asset?.assetId ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        <>
          <Title>{asset?.name}</Title>

          <Image
            src={
              asset?.imageLink ? asset?.imageLink : '/images/empty-image.png'
            }
            style={{ width: '100%', objectFit: 'cover', borderRadius: 5 }}
          />
          <ContentWrapper>
            <FlexBetween>
              <Item>
                <div>Vessel Status</div>
                <Tag
                  color={
                    vesselStatus === VesselStatus.ONLINE
                      ? '#55C95A'
                      : vesselStatus === VesselStatus.OFFLINE
                      ? '#FE4545'
                      : '#A4660A'
                  }
                >
                  {vesselStatus.toUpperCase()}
                </Tag>
              </Item>
              <Item>
                <div>Engine Status</div>
                <Tag color={`${isEngineOn ? '#55C95A' : '#FE4545'}`}>
                  {isEngineOn ? 'ACTIVE' : 'INACTIVE'}
                </Tag>
              </Item>
            </FlexBetween>

            <div style={{ marginTop: 5 }}>
              <ItemContent
                label='Last Updated'
                value={convertTimestampToDate(timestamp)}
              />
            </div>

            <Separator />

            <GridContent>
              <ItemContent
                label='Latitude'
                value={latitudeFormat(latitude, longitude)}
              />
              <ItemContent
                label='Longitude'
                value={longitudeFormat(latitude, longitude)}
              />
            </GridContent>
            <GridContent>
              <ItemContent
                label='Altitude'
                value={altitude ? (altitude as number).toFixed(2) : '-'}
              />
              <ItemContent
                label='Speed'
                value={toKnot(Number(speed) ?? 0).toFixed(2) + '  Knot'}
              />
            </GridContent>
            <Separator />

            <GridSetting custom>
              <ItemSetting>
                <ItemContent label='Asset Trail' value='' />
                {/* <Switch
                  loading={asset.massId === loadingCurrAssetTrail}
                  onChange={handleToggleTrail}
                  checked={tmpToggle}
                /> */}
              </ItemSetting>
            </GridSetting>
            <AssetInfo>
              {`Last data trail: ${convertTimestampToDate(timestamp)}`}
            </AssetInfo>
            <ItemSetting>
              {/* <div>Dot Track</div> */}
              {/* <Switch
                  onChange={handleGPSDotTrail}
                  checked={gpsDotTrail === "active" ? true : false}
                /> */}
            </ItemSetting>
          </ContentWrapper>

          <ActionWrapper>
            <GridSetting>
              <Button
                onClick={onClickReport}
                size='small'
                type='primary'
                color='#0957C2'
              >
                View Report
              </Button>
              <Button
                onClick={onClickDashboard}
                size='small'
                type='primary'
                className='btn-accent'
                color='black'
              >
                Dashboard
              </Button>
            </GridSetting>
          </ActionWrapper>
        </>
      )}
    </Container>
  );
};

interface IItemProps {
  label: string;
  value: string;
}

const ItemContent: React.FC<IItemProps> = ({ label, value }) => {
  return (
    <Item>
      <div>{label}</div>
      <p>{value}</p>
    </Item>
  );
};

const AssetInfo = styled.div`
  font-size: 10px;
  opacity: 65%;
  margin-top: -5px;
`;

const Container = styled.div`
  border-radius: 4px;
  padding-top: 10px;
  width: auto;
  overflow: hidden;
  img {
    width: 100%;
    height: 172px;
    object-fit: cover;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  margin-top: 15px;
`;

const ActionWrapper = styled.div`
  // padding: 10px 20px 10px 20px;
  // background-color: #f4f6f9;
  padding-top: 10px;
`;

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const GridContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #e8ecf3;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Item = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  div {
    color: #768499;
    margin-bottom: 5px;
  }
  p {
    color: #000;
    margin: 0;
    font-size: 12px;
  }
`;

const GridSetting = styled.div<{ custom?: boolean }>`
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) => (props.custom ? '1fr' : '1fr 1fr')};
  gap: 20px;
`;

const ItemSetting = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default MapVesselPopup;
