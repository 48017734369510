import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  MenuProps,
  Row,
  Select,
  Tooltip,
} from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import useMasterData from '../hooks/useMasterData';
import useGroup from '../zustand/useGroup';
import {
  ELogFilterRangeType,
  EReportDataInterval,
  ILogReport,
  ILogReportFilter,
} from '../screens/report/types/report-log.type';
import DateRangePicker from './DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { EAssetType, initialAsset } from '../screens/asset/types/asset.type';
import { DownloadOutlined, SettingFilled } from '@ant-design/icons';
import useReportLog from '../hooks/useReportLog';
import { httpRequest } from '../helpers/api';
import {
  BaseResponsePaginationProps,
  generateQueryString,
  IHttpResponse,
} from '@qlibs/react-components';
import { DeviceProperties } from '../screens/device/types/device.type';
import PopupDownload from '../screens/report/components/downloadConfiguration';

type FilterReportType = {
  downloadButton?: any;
};

const FilterReport: React.FC<FilterReportType> = ({ downloadButton }) => {
  const [form] = Form.useForm();
  const {
    company,
    setCompany,
    asset,
    setAsset,
    filter,
    setFilter,
    setDevices,
  } = useGroup();
  const {
    getListCompanies,
    getListAssets,
    companies,
    assets,
    loadingCompany,
    loadingAsset,
  } = useMasterData();
  const {
    setShowReportTableConfig,
    setDataCompletion,
    setLoadingLog,
    setLogs,
    loadingLog,
    loadingDevice,
    setLoadingDevice,
  } = useReportLog();

  const filterAssetOptions = useMemo(() => {
    const data = assets.map((item) => ({
      value: item.assetId,
      label: item.name,
    }));
    return [...data];
  }, [assets]);

  const filterCompanyOptions = useMemo(() => {
    const data = companies.map((item) => ({
      value: item.companyId,
      label: item.name,
    }));
    return [...data];
  }, [companies]);

  // const calculateRange = (type: string) => {
  //   switch (type) {
  //     case ELogFilterRangeType.last_30_day:
  //       return [moment().subtract(30, 'days'), moment()];
  //     case ELogFilterRangeType.last_month:
  //       return [
  //         moment().subtract(1, 'months').startOf('month'),
  //         moment().subtract(1, 'months').endOf('month'),
  //       ];
  //     case ELogFilterRangeType.last_7_day:
  //       return [moment().subtract(7, 'days'), moment()];
  //     case ELogFilterRangeType.today:
  //       return [moment().startOf('day'), moment().endOf('day')];
  //     case ELogFilterRangeType.last_day:
  //       return [
  //         moment().subtract(1, 'days').startOf('day'),
  //         moment().subtract(1, 'days').endOf('day'),
  //       ];
  //     default:
  //       return undefined;
  //   }
  // };

  // const handleRangeTypeChange = (rangeType: string) => {
  //   const range = calculateRange(rangeType);
  //   form.setFieldsValue({
  //     rangeType,
  //     range,
  //   });
  // };

  useEffect(() => {
    getListCompanies();

    form.setFieldsValue({
      rangeType: ELogFilterRangeType.today,
      interval: filter.interval,
    });
  }, []);

  useEffect(() => {
    if (company.companyId) {
      getListAssets({ filterCompanyIds: company.companyId });
    }
  }, [company.companyId]);

  useEffect(() => {
    if (assets.length > 0) {
      const firstAsset = assets[0];
      form.setFieldsValue({
        asset: firstAsset.assetId,
      });
      setAsset(firstAsset);
    }
  }, [assets]);

  useEffect(() => {
    if (company) {
      form.setFieldsValue({
        company: !loadingCompany ? company.companyId : undefined,
      });
    }
    if (asset) {
      form.setFieldsValue({
        asset: !loadingAsset ? asset.assetId : undefined,
      });
    }
  }, [company, loadingCompany, asset, loadingAsset]);

  const actionDisabled = useMemo(() => {
    if (loadingAsset || loadingCompany || loadingLog || loadingDevice) {
      return true;
    }
    return false;
  }, [loadingCompany, loadingAsset, loadingLog, loadingDevice]);

  function handleOnChangeCompany(companyId: string) {
    const found = companies.find((item) => item.companyId === companyId);
    found && setCompany(found);
  }

  function handleOnChangeAsset(assetId: string) {
    const found = assets.find((item) => item.assetId === assetId);
    found && setAsset(found);
  }

  function handleChangeInterval(e: string) {
    setFilter({
      ...filter,
      interval: e,
    });
  }

  function handlOnChangeCustomTime(date: Dayjs, type: string) {
    let startAt = filter.startAt;
    let endAt = filter.endAt;

    if (type === 'start') {
      startAt = dayjs(startAt * 1000)
        .set('hour', date.get('hour'))
        .set('minute', date.get('minute'))
        .set('second', 0)
        .unix();
    }

    if (type === 'end') {
      endAt = dayjs(endAt * 1000)
        .set('hour', date.get('hour'))
        .set('minute', date.get('minute'))
        .set('second', 0)
        .unix();
    }

    setFilter({
      ...filter,
      startAt,
      endAt,
    });
  }

  function handleOnChangeCustomDateRange(dates: any) {
    let newFilter = { ...filter };

    if (dates[0]) {
      const date = dayjs(dates[0]).get('date');
      const month = dayjs(dates[0]).get('month');
      const year = dayjs(dates[0]).get('year');
      newFilter = {
        ...newFilter,
        startAt: dayjs(newFilter.startAt * 1000)
          .set('date', date)
          .set('month', month)
          .set('year', year)
          .set('second', 0)
          .unix(),
      };
    }

    if (dates[1]) {
      const date = dayjs(dates[1]).get('date');
      const month = dayjs(dates[1]).get('month');
      const year = dayjs(dates[1]).get('year');
      newFilter = {
        ...newFilter,
        endAt: dayjs(newFilter.endAt * 1000)
          .set('date', date)
          .set('month', month)
          .set('year', year)
          .set('second', 0)
          .unix(),
      };
    }

    setFilter(newFilter);
  }

  useEffect(() => {
    let ignore = false;
    async function fetchDevices(params: any) {
      try {
        setLoadingDevice(true);
        const res = await httpRequest.get<
          BaseResponsePaginationProps<DeviceProperties>
        >(`/device${generateQueryString(params)}`);

        if (!ignore && res.data.payload.results) {
          const result = res.data.payload.results;
          setDevices(result);
          const resDevices = result.map((item) =>
            item.deviceType.name.toLocaleLowerCase()
          );
          const currentDeviceTypes = [...new Set(resDevices)].join(',');
          setFilter({
            ...filter,
            deviceType: currentDeviceTypes,
          });
        }
      } catch (error) {
        console.error(`failed fetch devices`, error);
      } finally {
        setLoadingDevice(false);
      }
    }

    fetchDevices({
      filterAssetIds: filter.assetId,
      filterStatus: 'active',
    });

    return () => {
      ignore = true;
    };
  }, [filter.assetId]);

  const getLogReport = async () => {
    try {
      setLoadingLog(true);
      const res = await httpRequest.get<IHttpResponse<ILogReport>>(
        `report-log${generateQueryString(filter)}`
      );

      if (res.data.payload) {
        let currentDataCompletion = [];
        setLogs(res.data.payload);
        if (res.data.payload.flowmeter) {
          const obj = res.data.payload.flowmeter;
          currentDataCompletion.push({
            type: 'flowmeter',
            count: Object.keys(obj).length,
            lastTimestamp: Object.keys(obj)[Object.keys(obj).length - 1],
          });
        }
        if (res.data.payload.ae) {
          const obj = res.data.payload.ae;
          currentDataCompletion.push({
            type: 'ae',
            count: Object.keys(obj).length,
            lastTimestamp: Object.keys(obj)[Object.keys(obj).length - 1],
          });
          currentDataCompletion.push({
            type: 'power',
            count: Object.keys(obj).length,
            lastTimestamp: Object.keys(obj)[Object.keys(obj).length - 1],
          });
        }
        if (res.data.payload.rpm) {
          const obj = res.data.payload.rpm;
          currentDataCompletion.push({
            type: 'rpm',
            count: Object.keys(res.data.payload.rpm).length,
            lastTimestamp: Object.keys(obj)[Object.keys(obj).length - 1],
          });
        }
        if (res.data.payload.gps) {
          const obj = res.data.payload.gps;
          currentDataCompletion.push({
            type: 'gps',
            count: Object.keys(res.data.payload.gps).length,
            lastTimestamp: Object.keys(obj)[Object.keys(obj).length - 1],
          });
        }
        if (res.data.payload.gateway) {
          const obj = res.data.payload.gateway;
          currentDataCompletion.push({
            type: 'gateway',
            count: Object.keys(res.data.payload.gateway).length,
            lastTimestamp: Object.keys(obj)[Object.keys(obj).length - 1],
          });
        }
        setDataCompletion(currentDataCompletion);
      }
    } catch (error) {
      console.error('failed get report log', error);
    } finally {
      setLoadingLog(false);
    }
  };

  useEffect(() => {
    let ignore = false;

    if (filter.deviceType && asset.assetId && !ignore) {
      getLogReport();
    }

    return () => {
      ignore = true;
    };
  }, [filter.deviceType, asset.assetId]);

  return (
    <Form form={form} name='profileForm' layout='vertical' autoComplete='off'>
      <Row gutter={10}>
        <Col span={5}>
          <Form.Item label='Company' className='label-filter' name='company'>
            <Select
              disabled={loadingCompany}
              loading={loadingCompany}
              showSearch
              placeholder='Choose company'
              style={{ width: '100%' }}
              onChange={handleOnChangeCompany}
              options={filterCompanyOptions}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item className='label-filter' label='Asset' name='asset'>
            <Select
              disabled={loadingAsset || !filterAssetOptions.length}
              loading={loadingAsset}
              showSearch
              placeholder='Choose asset'
              style={{ width: '100%' }}
              onChange={handleOnChangeAsset}
              options={filterAssetOptions}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        {/* <Col span={3}>
          <Form.Item
            className='label-filter'
            label='Range Type'
            name='rangeType'
          >
            <Select
              showSearch
              placeholder='Choose range type'
              style={{ width: '100%' }}
              onChange={handleRangeTypeChange}
              options={[
                {
                  label: 'Last 30 Days',
                  value: ELogFilterRangeType.last_30_day,
                },
                {
                  label: 'Last Month',
                  value: ELogFilterRangeType.last_month,
                },
                {
                  label: 'Last 7 Days',
                  value: ELogFilterRangeType.last_7_day,
                },
                {
                  label: 'Today',
                  value: ELogFilterRangeType.today,
                },
                {
                  label: 'Last Day',
                  value: ELogFilterRangeType.last_day,
                },
                {
                  label: 'Custom',
                  value: ELogFilterRangeType.custom,
                },
              ]}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col> */}
        <Col span={3}>
          <Form.Item className='label-filter' label='Interval' name='interval'>
            <Select
              showSearch
              placeholder='Choose interval'
              style={{ width: '100%' }}
              onChange={handleChangeInterval}
              options={[
                {
                  label: 'Minutes',
                  value: EReportDataInterval.minute,
                },
                {
                  label: 'Hourly',
                  value: EReportDataInterval.hour,
                },
                // {
                //   label: 'Daily',
                //   value: EReportDataInterval.daily,
                // },
                // {
                //   label: 'Weekly',
                //   value: EReportDataInterval.weekly,
                // },
                // {
                //   label: 'Monthly',
                //   value: EReportDataInterval.monthly,
                // },
              ]}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item className='label-filter' label='Range' name='range'>
            <DateRangePicker
              // timezone={timezone}
              // constanta={constanta}
              startAt={filter.startAt}
              endAt={filter.endAt}
              custom={true}
              disabled={actionDisabled}
              onChange={(e) => console.log(e)}
              onChangeCustomTime={handlOnChangeCustomTime}
              onChangeCustomDateRange={handleOnChangeCustomDateRange}
            />
          </Form.Item>
        </Col>
        <Col
          span={5}
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            gap: 10,
          }}
        >
          <Button
            disabled={actionDisabled || (!filter.assetId && !filter.deviceType)}
            loading={actionDisabled}
            onClick={getLogReport}
            type='primary'
          >
            Apply
          </Button>
          {downloadButton && downloadButton}
        </Col>
      </Row>
    </Form>
  );
};

export default FilterReport;
