import { useMemo } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import useGroup from '../../../../zustand/useGroup';
import ReportChart from '../reportChart';
import styled from 'styled-components';
import { Table } from 'antd';
import {
  generateDataSeriesTable,
  generateTimestampData,
  toHHMM,
} from '../helperFunction';
import { EReportDataInterval } from '../../types/report-log.type';

interface IProps {
  isEMS?: boolean;
  isShowTitle?: boolean;
  isReportSchedule?: {
    startAt: number;
    endAt: number;
    interval: any;
    deviceType: string;
    assetId: string;
  };
}
const defaultYAxis = ['fuel', 'rpm', 'speed', 'time'];
const defaultScheme = [
  {
    logKey: 'flowmeter',
    dataKeys: ['totalFuelCons'],
    seriesOption: {
      name: 'Fuel Cons.',
      type: 'areaspline',
      yAxis: 1,
      zIndex: 1,
      marker: {
        enabled: false,
        fillColor: 'rgb(255, 196, 0)',
      },
      lineColor: 'rgb(255, 196, 0)',
      fillColor: 'rgba(255, 187, 0, 0.26)',
      tooltip: {
        valueSuffix: ' liter',
      },
    },
  },
  {
    logKey: 'rpm',
    dataKeys: ['port', 'rpm'],
    seriesOption: {
      name: 'Port RPM',
      type: 'spline',
      yAxis: 0,
      zIndex: 3,
      marker: {
        enabled: false,
        fillColor: 'rgb(111, 87, 233)',
      },
      lineColor: 'rgb(111, 87, 233)',
    },
  },
  {
    logKey: 'rpm',
    dataKeys: ['starboard', 'rpm'],
    seriesOption: {
      name: 'Starboard RPM',
      type: 'spline',
      yAxis: 0,
      zIndex: 3,
      marker: {
        enabled: false,
        fillColor: 'rgb(2, 204, 204)',
      },
      lineColor: 'rgb(2, 204, 204)',
    },
  },
  {
    logKey: 'gps',
    dataKeys: ['speed'],
    seriesOption: {
      name: 'Speed',
      type: 'spline',
      yAxis: 2,
      zIndex: 4,
      marker: {
        enabled: false,
        fillColor: 'rgb(243, 69, 132)',
      },
      lineColor: 'rgb(243, 69, 132)',
      tooltip: {
        valueSuffix: ' knot',
      },
    },
  },
  {
    logKey: 'ae',
    dataKeys: ['totalRunningTime'],
    seriesOption: {
      name: 'AE Running Time',
      type: 'spline',
      yAxis: 3,
      zIndex: 4,
      marker: {
        enabled: false,
        fillColor: 'rgb(18, 24, 44)',
      },
      lineColor: 'rgb(18, 24, 44)',
      tooltip: {
        valueSuffix: ' hour',
      },
    },
  },
];

const ChartRpmFuelSpeedRunning: React.FC<IProps> = ({
  isEMS,
  isShowTitle,
  isReportSchedule,
}) => {
  const { logs } = useReportLog();
  const { filter } = useGroup();

  const yAxis = useMemo(() => {
    if (isEMS) {
      return defaultYAxis.filter((item) => item !== 'fuel');
    }

    return defaultYAxis;
  }, [isEMS]);

  const schema = useMemo(() => {
    if (isEMS) {
      return defaultScheme.filter((item) => item.logKey !== 'flowmeter');
    }

    return defaultScheme;
  }, [isEMS]);

  const dataTable = useMemo(() => {
    const dataSeriesTable: any[] = [[...Array(24).keys()]];
    let intervalInSecond = 60;

    if (isReportSchedule?.interval === EReportDataInterval.minute) {
      intervalInSecond = 60;
    } else if (isReportSchedule?.interval === EReportDataInterval.hour) {
      intervalInSecond = 60 * 60;
    } else {
      intervalInSecond = 60 * 60 * 24;
    }

    const timestamps = generateTimestampData({
      startTime: isReportSchedule?.startAt!,
      endTime: isReportSchedule?.endAt!,
      interval: isReportSchedule?.interval,
      intervalInSecond,
    });

    schema.forEach((data) => {
      if ((logs as any)[data.logKey]) {
        const res = generateDataSeriesTable({
          timestamps,
          key: data.dataKeys,
          fm: (logs as any)[data.logKey],
        });

        if (data.logKey === 'ae') {
          dataSeriesTable.push(res.map((item) => toHHMM(item)));
        } else {
          dataSeriesTable.push(res);
        }
      }
    });

    return dataSeriesTable;
  }, [schema]);

  const tableColumns = useMemo(() => {
    let res: any[] = ['Time'];
    schema.forEach((item) => {
      res.push(item.seriesOption.name);
    });

    return res;
  }, [schema]);

  return (
    <>
      {isShowTitle && (
        <WrapperTitle>
          <Title>RPM vs Fuel Consumption vs Speed vs AE Running Time</Title>
          <Subtitle>
            Value of line chart with the dotted line is only estimated data
            because the data is not yet available
          </Subtitle>
        </WrapperTitle>
      )}
      <ReportChart
        logs={logs}
        filter={isReportSchedule || filter}
        yAxis={yAxis}
        chartSchema={schema}
        isReportSchedule={
          isReportSchedule && isReportSchedule.deviceType ? true : false
        }
      />
      {isReportSchedule && tableColumns.length && (
        <table className='table-app'>
          <tbody>
            {dataTable.map((data, idx) => (
              <tr key={idx}>
                <td>{tableColumns[idx]}</td>
                {data.map((item: number | undefined, idx2: number) => (
                  <td key={idx2}>
                    {typeof item === 'undefined'
                      ? '-'
                      : idx === 0 || idx === dataTable.length - 1
                      ? item
                      : item?.toFixed(2)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

const WrapperTitle = styled.div`
  margin-bottom: 20px;
  margin-left: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: #768499;
  font-size: 12;
  margin-top: 5px;
`;

export default ChartRpmFuelSpeedRunning;
