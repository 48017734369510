import { create, StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import {
  CompanyProperties,
  initialCompany,
} from '../screens/company/types/company.type';
import {
  GroupProperties,
  initialGroup,
} from '../screens/group/types/group.type';
import {
  AssetProperties,
  initialAsset,
} from '../screens/asset/types/asset.type';
import {
  ELogFilterRangeType,
  EReportDataInterval,
  ILogReportFilter,
} from '../screens/report/types/report-log.type';
import dayjs from 'dayjs';
import {
  DeviceProperties,
  initialDevice,
} from '../screens/device/types/device.type';

type CompanyProps = {
  group: GroupProperties;
  company: CompanyProperties;
  asset: AssetProperties;
  devices: DeviceProperties[];
  feature: string;
  dataSource: 'GSM' | 'SATELLITE';
  filter: ILogReportFilter;
  setGroup: (group: GroupProperties) => void;
  setCompany: (company: CompanyProperties) => void;
  setAsset: (asset: AssetProperties) => void;
  setDevices: (devices: DeviceProperties[]) => void;
  setFeature: (value: string) => void;
  setDataSource: (value: 'GSM' | 'SATELLITE') => void;
  setFilter: (value: ILogReportFilter) => void;
};

export const initialLogReportQuery: ILogReportFilter = {
  deviceType: '',
  assetId: '',
  interval: EReportDataInterval.minute,
  // startAt: dayjs().startOf('date').unix(),
  // endAt: dayjs().endOf('date').unix(),
  startAt: 1732813200,
  endAt: 1732820400,
  timezone: 7,
};

type MyPersist = (
  config: any,
  options: PersistOptions<CompanyProps>
) => StateCreator<CompanyProps>;

const useGroup = create<CompanyProps>(
  (persist as MyPersist)(
    (set: any) => ({
      group: initialGroup,
      company: initialCompany,
      asset: initialAsset,
      devices: [],
      feature: '',
      filter: { ...initialLogReportQuery },
      dataSource: 'GSM',
      setGroup: (group: GroupProperties) => {
        set({ group });
      },
      setCompany: (company: CompanyProperties) => {
        console.log('rex.setCompany');
        set({ company });
      },
      setAsset: (asset: AssetProperties) => {
        set({ asset });
        set((state: any) => ({
          filter: {
            ...state.filter,
            assetId: asset.assetId,
          },
        }));
      },
      setFilter: (filter: ILogReportFilter) => {
        set({ filter });
      },
      setFeature: (value: string) => {
        set({ feature: value });
      },
      setDevices: (devices: DeviceProperties[]) => {
        set({ devices });
      },
      setDataSource: (value: 'GSM' | 'SATELLITE') => {
        set({ dataSource: value });
      },
    }),
    {
      name: 'group-selected',
      getStorage: () => localStorage,
    }
  )
);

export default useGroup;
