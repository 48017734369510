import {
  BaseResponseProps,
  DetailItem,
  HeaderSection,
  IAppTableColumn,
} from '@qlibs/react-components';
import { GroupProperties, initialGroup } from './types/group.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate, useParams } from 'react-router-dom';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import React from 'react';
import { httpRequest } from '../../helpers/api';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from 'antd';
import SectionContent from '../../components/SectionContent';
import { generateAddress } from '../../helpers/address';
import { ColumnsType } from 'antd/lib/table';

const moment = require('moment');

interface ILocation {
  groupId: string;
}

interface ResponseProps
  extends BaseResponseProps<
    Omit<GroupProperties, 'createdAt' | 'updatedAt' | 'companies'>
  > {}

const GroupDetail = () => {
  const navigate = useNavigate();
  const { setBreadcrumbDetails } = useDetailBreadcrumbs();
  const { groupId } = useParams<keyof ILocation>() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<GroupProperties>({
    ...initialGroup,
  });

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        setIsLoading(true);

        const res = await httpRequest.get<ResponseProps>('/group/' + groupId);
        if (!res) {
          message.error('Something went wrong');
          return;
        }

        setData({
          ...res.data.payload,
        });

        let label = `Detail Group`;
        const bcDetails = [
          {
            field: 'groupId',
            value: groupId,
            label,
          },
        ];
        setBreadcrumbDetails(bcDetails);

        setIsLoading(false);
      } catch (error: any) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchUser();

    // eslint-disable-next-line
  }, [groupId]);

  const handleClickEdit = () => {
    navigate('/group/' + groupId + '/edit');
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Image',
      dataIndex: 'imageLink',
      key: 'imageLink',
      width: 70,
      render: (value: string, _: any) => (
        <Image
          width={60}
          height={60}
          style={{
            objectFit: 'contain',
            border: 'thin solid #ddd',
            overflow: 'hidden',
            background: '#dadada',
            borderRadius: 4,
          }}
          src={value}
          fallback='/images/flag-not-found.png'
        />
      ),
    },
    {
      title: 'Company Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'PIC Name',
      dataIndex: 'picName',
      key: 'picName',
    },
    {
      title: 'PIC Phone Number',
      dataIndex: 'picPhoneNumber',
      key: 'picPhoneNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value: string, _: any) => (
        <DetailItem
          label=''
          value={value.toUpperCase()}
          type='tag'
          _tagProps={{
            color: value === 'active' ? 'success' : 'error',
          }}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection
        icon='back'
        title={'Detail Group'}
        subtitle={`Manage your group data`}
        rightAction={
          <Space>
            <Button
              disabled={data.deletedAt ? true : false}
              type='primary'
              onClick={handleClickEdit}
            >
              Edit Group
            </Button>
          </Space>
        }
      />

      <Spin spinning={isLoading}>
        <Card>
          {data?.deletedAt ? (
            <Alert
              className='mb-5'
              type='warning'
              message='This data has been archived and cannot be edited'
            />
          ) : (
            false
          )}
          <SectionContent
            groupTitle='Information'
            subTitle='General information about this group'
          >
            <Row>
              <Col span={12}>
                <DetailItem label='Name' value={data.name} />
              </Col>
              <Col span={12}>
                <DetailItem label='Nick Name' value={data.nickname} />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Phone Number'
                  value={data.phoneNumber || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DetailItem label='Fax' value={data.fax || 'N/A'} />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Created by'
                  value={data?.userCreator?.name || 'N/A'}
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Created at'
                  value={moment(data.createdAt).format('DD MMMM YYYY, HH:mm')}
                  // type="datetime"
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Updated at'
                  value={moment(data.updatedAt).format('DD MMMM YYYY, HH:mm')}
                  // type="datetime"
                />
              </Col>
              <Col span={12}>
                <DetailItem
                  label='Status'
                  value={data.status.toUpperCase()}
                  type='tag'
                  _tagProps={{
                    color: data.status === 'active' ? 'success' : 'error',
                  }}
                />
              </Col>
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle='Address'
            subTitle='Information about group address'
          >
            <Row>
              <DetailItem
                label='Address'
                value={generateAddress(data.address)}
              />
            </Row>
          </SectionContent>

          <Divider />

          <SectionContent
            groupTitle='Companies'
            subTitle='List of companies that include in this group'
          >
            <Table
              columns={columns}
              dataSource={data.companies}
              pagination={false}
            />
          </SectionContent>
        </Card>
      </Spin>
    </React.Fragment>
  );
};

export default GroupDetail;
