import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Spin } from 'antd';

// import { LoadingWrapper } from "../../report";

// import { AssetType } from "../../../types/asset.type";
import { latitudeFormat, longitudeFormat } from '../../../helpers/map-util';
import { toKM, toKnot } from '../../report/components/helperFunction';

interface CoordinatePopupProps {
  speed: number;
  longitude: number;
  latitude: number;
  altitude: number;
  heading: number;
  timestamp: number;
  distance: number;
  asset: string;
}

const CoordinatePopup: React.FC<CoordinatePopupProps> = ({
  speed,
  longitude,
  latitude,
  altitude,
  timestamp,
  distance,
  asset,
}) => {
  const convertTimestampToDate = (timestamp?: number) => {
    if (timestamp) {
      return moment(timestamp * 1000).format('DD MMM YYYY HH:mm');
    }

    return '-';
  };
  return (
    <Container className='custom-popup'>
      {false ? (
        <LoadingWrapper>
          <Spin />
        </LoadingWrapper>
      ) : (
        <>
          <Title>{asset}</Title>
          <ContentWrapper>
            <GridContent>
              <ItemContent
                label='Latitude'
                value={latitudeFormat(latitude, longitude)}
              />
              <ItemContent
                label='Longitude'
                value={longitudeFormat(latitude, longitude)}
              />
              <ItemContent
                label='Altitude'
                value={altitude?.toFixed(2) || '-'}
              />
              <ItemContent
                label='Speed'
                value={toKnot(speed).toFixed(2) + ' Knot'}
              />
              <ItemContent
                label='Distance'
                value={toKM(distance).toFixed(2) + ' KM'}
              />
              <ItemContent
                label='Time'
                value={convertTimestampToDate(timestamp)}
              />
            </GridContent>
          </ContentWrapper>
        </>
      )}
    </Container>
  );
};

interface IItemProps {
  label: string;
  value: string;
}

const ItemContent: React.FC<IItemProps> = ({ label, value }) => {
  return (
    <Item>
      <div>{label}</div>
      <p>{value}</p>
    </Item>
  );
};

const Container = styled.div`
  border-radius: 4px;
  width: 330px;
  overflow: hidden;
  img {
    width: 100%;
    height: 172px;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div`
  background-color: #f4f6f9;
  padding: 12px 20px;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const ContentWrapper = styled.div`
  // padding: 10px;
  margin-top: 10px;
`;

const GridContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #768499;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Item = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  div {
    color: #768499;
    margin-bottom: 5px;
  }
  p {
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    font-size: 12px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 10px;
    opacity: 0.5;
    text-align: center;
  }
`;

export default CoordinatePopup;
