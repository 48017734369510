import { TableColumnsType } from 'antd';
import { EReportDataInterval, ILogReport } from '../types/report-log.type';
import { capitalizeFirstLetter } from '@qlibs/react-components';

const dataOptionTotalCruise = [
  {
    label: 'KM',
    value: 'KM',
  },
  {
    label: 'NM',
    value: 'NM',
  },
  {
    label: 'MILES',
    value: 'MILES',
  },
];

const dataOptionAverageSpeed = [
  {
    label: 'KNOT',
    value: 'KNOT',
  },
  {
    label: 'KM/H',
    value: 'KM/H',
  },
];

const columnGps = {
  title: 'GPS',
  key: 'gps',
  children: [
    {
      title: 'Coordinate',
      dataIndex: 'gps_coordinate',
      key: 'gps_coordinate',
      width: 180,
      align: 'center',
    },
    {
      title: 'Heading',
      dataIndex: 'gps_heading',
      key: 'gps_heading',
      width: 70,
      align: 'center',
    },
    {
      title: 'Speed',
      dataIndex: 'gps_speed',
      key: 'gps_speed',
      width: 50,
      align: 'center',
    },
    {
      title: 'Distance',
      dataIndex: 'gps_distance',
      key: 'gps_distance',
      width: 65,
      align: 'center',
    },
  ],
};

const columnChildrenFlowmeter = [
  {
    title: 'RPM',
    dataIndex: 'rpm',
    key: 'rpm',
    width: 80,
  },
  {
    title: 'Run. Time',
    dataIndex: 'runningTime',
    key: 'runningTime',
    width: 70,
  },
  {
    title: 'Fuel Cons. (Liter)',
    dataIndex: 'fuelCons',
    key: 'fuelCons',
    width: 80,
  },
  {
    title: 'Volume Total In (Liter)',
    dataIndex: 'volumeTotal',
    key: 'volumeTotal',
  },
  {
    title: 'In Flow (L/h)',
    dataIndex: 'inFlow',
    key: 'inFlow',
  },
  {
    title: 'In Temp. (°C)',
    dataIndex: 'temperature',
    key: 'temperature',
  },
  {
    title: 'In Density (g/m³)',
    dataIndex: 'inDensity',
    key: 'inDensity',
  },
];

const columnChildrenAE = [
  {
    title: 'Run. Time',
    dataIndex: 'runningTime',
    key: 'runningTime',
    width: 80,
  },
  {
    title: 'Fuel Cons.',
    dataIndex: 'fuelConsumption',
    key: 'fuelConsumption',
    width: 80,
  },
];

export const generateReportLogColumns = (logs: ILogReport) => {
  let columns: TableColumnsType<any> = [
    {
      title: 'Date & time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      width: 150,
      align: 'center',
      fixed: 'left',
    },
  ];

  if (Object.hasOwn(logs, 'gps') && logs.gps) {
    columns.push(columnGps);
  }

  if (Object.hasOwn(logs, 'flowmeter') && logs.flowmeter) {
    const firstKey = Object.keys(logs.flowmeter)[0];

    if (logs.flowmeter[firstKey]) {
      if (logs.flowmeter[firstKey].portIn) {
        columns.push({
          title: 'PORT',
          key: 'port',
          width: 400,
          children: columnChildrenFlowmeter.map((item) => {
            return {
              ...item,
              align: 'center',
              width: item.width || 100,
              dataIndex: 'port_' + capitalizeFirstLetter(item.dataIndex),
              key: 'port_' + capitalizeFirstLetter(item.key),
            };
          }),
        });
      }
      if (logs.flowmeter[firstKey].starboardIn) {
        columns.push({
          title: 'STARBOARD',
          key: 'starboard',
          children: columnChildrenFlowmeter.map((item) => {
            return {
              ...item,
              align: 'center',
              width: item.width || 100,
              dataIndex: 'starboard_' + capitalizeFirstLetter(item.dataIndex),
              key: 'starboard_' + capitalizeFirstLetter(item.key),
            };
          }),
        });
      }
    }
  }

  if (Object.hasOwn(logs, 'ae') && logs.ae) {
    const firstKey = Object.keys(logs.ae)[0];

    if (logs.ae[firstKey]) {
      Object.keys(logs.ae[firstKey]).forEach((key) => {
        if (key !== 'totalFuelConsumption' && key !== 'totalRunningTime') {
          columns.push({
            title: key.toLocaleUpperCase(),
            key: key,
            children: columnChildrenAE.map((item) => {
              return {
                ...item,
                align: 'center',
                width: item.width || 100,
                dataIndex: key + '_' + capitalizeFirstLetter(item.dataIndex),
                key: key + '_' + capitalizeFirstLetter(item.key),
              };
            }),
          });
        }
      });
    }
  }

  return columns;
};
