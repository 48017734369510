import {
  CompassOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  HistoryOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  LikeOutlined,
  MinusOutlined,
  PieChartOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Statistic,
  Card,
  Spin,
  TableProps,
  Table,
  Divider,
  Select,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import styled from 'styled-components';
import {
  calculateGpsSummary,
  generateTimestampData,
  toHHMMSS,
} from '../helperFunction';
import useGroup from '../../../../zustand/useGroup';
import { EAssetType } from '../../../asset/types/asset.type';
import { EEmailBodyParams } from '../../../masterReport/types/report.type';
import { EReportDataInterval } from '../../types/report-log.type';

interface IProps {
  defaultOpenBody?: string[];
  hideAccordion?: boolean;
  isReportSchedule?: boolean;
}

const SummaryTable: React.FC<IProps> = (props) => {
  const { loadingLog: loading, logs } = useReportLog();
  const { filter, asset } = useGroup();
  const [distanceUnit, setDistanceUnit] = useState<string>('km');
  const [speedUnit, setSpeedUnit] = useState<string>('km/h');
  const [showBody, setShowBody] = useState<{
    body1: boolean;
    body2: boolean;
    body3: boolean;
    body4: boolean;
  }>({
    body1: true,
    body2: true,
    body3: true,
    body4: true,
  });

  useEffect(() => {
    if (props.defaultOpenBody && props.defaultOpenBody.length) {
      props.defaultOpenBody.forEach((key) => {
        const currentShowBody: any = { ...showBody };
        if (currentShowBody.hasOwnProperty(key) && currentShowBody[key]) {
          setShowBody((current) => {
            return {
              ...current,
              [key]: false,
            };
          });
        }
      });
    }
  }, [props.defaultOpenBody]);

  const dataSource = useMemo(() => {
    let res: any = {
      totalEngineFuelCons: 0,
      totalAEFuelCons: 0,
      portRPM: 0,
      portRunningHour: 0,
      portTotalFuel: 0,
      starboardRPM: 0,
      starboardRunningHour: 0,
      starboardTotalFuel: 0,
      ae1RunningHour: 0,
      ae1TotalFuel: 0,
      ae2RunningHour: 0,
      ae2TotalFuel: 0,
      ae3RunningHour: 0,
      ae3TotalFuel: 0,
    };

    let intervalInSecond = 0;

    if (filter.interval === EReportDataInterval.minute) {
      intervalInSecond = 60;
    } else if (filter.interval === EReportDataInterval.hour) {
      intervalInSecond = 60 * 60;
    } else {
      intervalInSecond = 60 * 60 * 24;
    }

    const containerTimestamp = generateTimestampData({
      startTime: filter.startAt,
      endTime: filter.endAt,
      interval: filter.interval,
      intervalInSecond,
    });

    if (logs && Object.keys(logs).length) {
      const fm = logs['flowmeter'];
      const rpm = logs['rpm'];
      const ae = logs['ae'];
      if (fm && Object.keys(fm).length) {
        Object.keys(fm).forEach((key) => {
          if (
            fm &&
            fm[key] &&
            fm[key].deviceCalculation &&
            fm[key].deviceCalculation['portIn'] &&
            fm[key].deviceCalculation['starboardIn'] &&
            containerTimestamp.includes(Number(key))
          ) {
            res.portTotalFuel +=
              fm[key].deviceCalculation['portIn'].fuelCons || 0;
            res.starboardTotalFuel +=
              fm[key].deviceCalculation['starboardIn'].fuelCons || 0;
            res.totalEngineFuelCons +=
              fm[key].deviceCalculation['portIn'].fuelCons || 0;
            res.totalEngineFuelCons +=
              fm[key].deviceCalculation['starboardIn'].fuelCons || 0;
          }
        });
      }

      if (rpm && Object.keys(rpm).length) {
        Object.keys(rpm).forEach((key) => {
          if (
            rpm &&
            rpm[key] &&
            rpm[key]['port'] &&
            containerTimestamp.includes(Number(key))
          ) {
            res.portRPM += rpm[key]['port'].rpm || 0;
            res.starboardRPM += rpm[key]['starboard'].rpm || 0;
            res.portRunningHour += rpm[key]['port'].runningTime || 0;
            res.starboardRunningHour += rpm[key]['starboard'].runningTime || 0;
          }
        });
      }

      if (ae && Object.keys(ae).length) {
        Object.keys(ae).forEach((key) => {
          if (ae && ae[key]) {
            res.totalAEFuelCons += ae[key].totalFuelConsumption || 0;
          }

          if (ae && ae[key] && ae[key]['ae1']) {
            res.ae1RunningHour += ae[key]['ae1'].runningTime || 0;
            res.ae1TotalFuel += ae[key]['ae1'].fuelConsumption || 0;
          }
          if (ae && ae[key] && ae[key]['ae2']) {
            res.ae2RunningHour += ae[key]['ae2'].runningTime || 0;
            res.ae2TotalFuel += ae[key]['ae2'].fuelConsumption || 0;
          }
          if (ae && ae[key] && ae[key]['ae3']) {
            res.ae3RunningHour += ae[key]['ae3'].runningTime || 0;
            res.ae3TotalFuel += ae[key]['ae3'].fuelConsumption || 0;
          }
        });
      }
    }

    return {
      ...res,
      portRPM: res.portRPM / containerTimestamp.length,
      starboardRPM: res.starboardRPM / containerTimestamp.length,
    };
  }, [logs]);

  const gpsSummmary = useMemo(() => {
    return calculateGpsSummary(logs, {
      distanceUnit,
      speedUnit,
    });
  }, [logs, distanceUnit, speedUnit]);

  return (
    <div id='page-summary'>
      <Wrapper>
        <Table2>
          <Tr titleSection={true}>
            <Td colSpan={6}>Summary</Td>
          </Tr>
          <Tr>
            <Td>Total Cruise</Td>
            <Td id={EEmailBodyParams.gps_total_cruise}>
              {gpsSummmary.totalCruise}
            </Td>
            <Td>
              {!props.isReportSchedule ? (
                <Select
                  value={distanceUnit}
                  style={{ width: 120 }}
                  onChange={(e) => setDistanceUnit(e)}
                  options={[
                    { value: 'km', label: 'KM' },
                    { value: 'nm', label: 'NM' },
                    { value: 'miles', label: 'MILES' },
                  ]}
                />
              ) : (
                'KM'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Average Speed</Td>
            <Td id={EEmailBodyParams.gps_average_speed}>
              {gpsSummmary.avgSpeed}
            </Td>
            <Td>
              {!props.isReportSchedule ? (
                <Select
                  value={speedUnit}
                  style={{ width: 120 }}
                  onChange={(e) => setSpeedUnit(e)}
                  options={[
                    { value: 'km/h', label: 'KM/H' },
                    { value: 'knot', label: 'KNOT' },
                  ]}
                />
              ) : (
                'KM/H'
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Distance</Td>
            <Td id={EEmailBodyParams.gps_distance}>
              {gpsSummmary.totalDistance}
            </Td>
            <Td>
              {!props.isReportSchedule ? (
                <Select
                  value={distanceUnit}
                  style={{ width: 120 }}
                  onChange={(e) => setDistanceUnit(e)}
                  options={[
                    { value: 'km', label: 'KM' },
                    { value: 'nm', label: 'NM' },
                    { value: 'miles', label: 'MILES' },
                  ]}
                />
              ) : (
                'KM'
              )}
            </Td>
          </Tr>
        </Table2>

        <Table2>
          <Tr titleSection={true}>
            <Td colSpan={6}>Fuel Consumption Summary</Td>
          </Tr>
          <Tr>
            <Td>Engine Fuel Cons.</Td>
            <Td id={EEmailBodyParams.total_engine_fuel_cons}>
              {dataSource.totalEngineFuelCons.toFixed(2)}
            </Td>
            <Td>Liter</Td>
          </Tr>
          <Tr>
            <Td>AE Fuel Cons.</Td>
            <Td id={EEmailBodyParams.total_ae_fuel_cons}>
              {dataSource.totalAEFuelCons.toFixed(2)}
            </Td>
            <Td>Liter</Td>
          </Tr>
          <Tr>
            <Td>Total Fuel Cons.</Td>
            <Td id={EEmailBodyParams.total_fuel_cons}>
              {(
                dataSource.totalEngineFuelCons + dataSource.totalAEFuelCons
              ).toFixed(2)}
            </Td>
            <Td>Liter</Td>
          </Tr>
        </Table2>
      </Wrapper>

      {![EAssetType.EMS].includes(asset.assetType) && (
        <Wrapper column={3}>
          <Table2>
            <Tr titleSection={true}>
              <Td colSpan={6}>Engine Running Time</Td>
            </Tr>
            <Tr>
              <Td>Port</Td>
              <Td id={EEmailBodyParams.engine_port_running_time}>
                {toHHMMSS(dataSource.portRunningHour)}
              </Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td>Starboard</Td>
              <Td id={EEmailBodyParams.engine_starboard_running_time}>
                {toHHMMSS(dataSource.starboardRunningHour)}
              </Td>
              <Td></Td>
            </Tr>
          </Table2>

          <Table2>
            <Tr titleSection={true}>
              <Td colSpan={6}>Engine Fuel Consumption</Td>
            </Tr>
            <Tr>
              <Td>Port</Td>
              <Td id={EEmailBodyParams.engine_port_fuel_cons}>
                {dataSource.portTotalFuel.toFixed(2)}
              </Td>
              <Td>Liter</Td>
            </Tr>
            <Tr>
              <Td>Starboard</Td>
              <Td id={EEmailBodyParams.engine_starboard_fuel_cons}>
                {dataSource.starboardTotalFuel.toFixed(2)}
              </Td>
              <Td>Liter</Td>
            </Tr>
          </Table2>

          <Table2>
            <Tr titleSection={true}>
              <Td colSpan={6}>Engine Speed</Td>
            </Tr>
            <Tr>
              <Td>Port</Td>
              <Td id={EEmailBodyParams.engine_port_rpm}>
                {dataSource.portRPM.toFixed(2)}
              </Td>
              <Td>RPM</Td>
            </Tr>
            <Tr>
              <Td>Starboard</Td>
              <Td id={EEmailBodyParams.engine_starboard_rpm}>
                {dataSource.starboardRPM.toFixed(2)}
              </Td>
              <Td>RPM</Td>
            </Tr>
          </Table2>
        </Wrapper>
      )}

      <Wrapper>
        <Table2>
          <Tr titleSection={true}>
            <Td colSpan={6}>AE Running Time</Td>
          </Tr>
          <Tr>
            <Td>AE1</Td>
            <Td id={EEmailBodyParams.ae_1running_time}>
              {toHHMMSS(dataSource.ae1RunningHour)}
            </Td>
            <Td></Td>
          </Tr>
          <Tr>
            <Td>AE2</Td>
            <Td id={EEmailBodyParams.ae_2running_time}>
              {toHHMMSS(dataSource.ae2RunningHour)}
            </Td>
            <Td></Td>
          </Tr>
          <Tr>
            <Td>AE3</Td>
            <Td id={EEmailBodyParams.ae_3running_time}>
              {toHHMMSS(dataSource.ae3RunningHour)}
            </Td>
            <Td></Td>
          </Tr>
        </Table2>

        <Table2>
          <Tr titleSection={true}>
            <Td colSpan={6}>AE Fuel Consumption</Td>
          </Tr>
          <Tr>
            <Td>AE1</Td>
            <Td id={EEmailBodyParams.ae_1fuel_cons}>
              {dataSource.ae1TotalFuel.toFixed(2)}
            </Td>
            <Td>Liter</Td>
          </Tr>
          <Tr>
            <Td>AE2</Td>
            <Td id={EEmailBodyParams.ae_2fuel_cons}>
              {dataSource.ae2TotalFuel.toFixed(2)}
            </Td>
            <Td>Liter</Td>
          </Tr>
          <Tr>
            <Td>AE3</Td>
            <Td id={EEmailBodyParams.ae_3fuel_cons}>
              {dataSource.ae3TotalFuel.toFixed(2)}
            </Td>
            <Td>Liter</Td>
          </Tr>
        </Table2>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div<{ column?: number }>`
  display: flex;
  column-gap: 20px;
  grid-template-columns: ${({ column: gridColumn }) =>
    gridColumn ? `repeat(0,${gridColumn}fr)` : 'repeat(0, 2fr)'};
`;

const Table2 = styled.table`
  margin-top: 30px;
  width: 100%;
`;

const Tr = styled.tr<{ titleSection?: boolean }>`
  background-color: ${({ titleSection }) =>
    titleSection ? 'var(--gray-2)' : 'white'};
`;

const Td = styled.td`
  border-top: thin solid #ddd;
  border-bottom: thin solid #ddd;
  width: 10%;
  padding: 4px 8px;
`;

const CardInfo = styled(Card)`
  background: var(--gray-2);
  .ant-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;

    div {
      :first-child {
        opacity: 0.7;
      }
      :last-child {
        font-weight: bold;
      }
    }
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const Accordion = styled.div`
  margin-top: 15px;
  grid-column: 4;
`;

const CardInfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const CardInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  gap: 20px;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Value = styled.div`
  font-size: 14px;
`;

const Label = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

export default SummaryTable;
