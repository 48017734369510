import { Button, Checkbox, Col, Popover, Row, Spin, Tooltip } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useMemo, useState } from 'react';
import useGroup from '../../../zustand/useGroup';
import { EAssetType } from '../../asset/types/asset.type';
import { DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { httpRequest } from '../../../helpers/api';
import { CapturePdfParams } from '../types/report-log.type';
import {
  capitalizeFirstLetter,
  generateQueryString,
} from '@qlibs/react-components';
import { generateDeviceOptions } from './helperFunction';
import { EDeviceType } from '../../device/types/device.type';

const dateFormat = 'DD MMM YYYY HH-mm';

const PopupDownload: React.FC<{
  loading: boolean;
  isReportLog?: boolean;
  dataLogColumns?: any;
  dataSource?: any;
}> = (props) => {
  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);
  const { filter, asset, devices } = useGroup();
  const [loading, setLoading] = useState<boolean>(false);

  function handleChange(checkedValues: CheckboxValueType[]) {
    setSelectedItems(checkedValues);
  }

  async function onDownloadPDF(datas: string[]) {
    try {
      setLoading(true);
      const query: CapturePdfParams = {
        ...filter,
        interval: 'hour',
        assetName: asset.name.split(' ').join('_'),
        scheduleReport: 1,
        dataDownloaded: datas,
      };
      const res: any = await httpRequest.get(
        `/report-log/generate-report-pdf${generateQueryString(query)}`,
        {
          responseType: 'blob',
        }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const startAt = dayjs(filter.startAt * 1000).format(dateFormat);
      const endAt = dayjs(filter.endAt * 1000).format(dateFormat);
      const fileName = `Report ${asset.name} ${startAt} ${endAt}.pdf`;

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('failed generate report', error);
    } finally {
      setLoading(false);
    }
  }

  function handleDownload(all?: boolean) {
    if (!all) {
      onDownloadPDF(selectedItems as string[]);
    } else if (options && options.length) {
      onDownloadPDF(options.map((item) => item.key));
    }
  }

  const options = useMemo(() => {
    let res: { key: string; label: string; hide?: boolean }[] = [];
    if (asset && asset.assetType && devices) {
      const reportOptions = generateDeviceOptions(devices);

      res.push({
        key: 'summary',
        label: 'Summary',
      });

      reportOptions.forEach((item) => {
        if (item.type === EDeviceType.GPS) {
          res.push({
            key: 'vessel_tracking',
            label: 'Vessel Tracking',
          });
        } else if (item.type === EDeviceType.FLOWMETER) {
          res.push(
            {
              key:
                asset.assetType === EAssetType.EMS
                  ? 'rpm_speed_ae'
                  : 'rpm_fuel_speed_ae',
              label: 'RPM vs Fuel Cons. vs Speed vs AE Running Time',
            },
            {
              key: 'me_fuel_consumption',
              label: 'ME Fuel Consumption',
            }
          );
          item.devices.forEach((key) => {
            res.push({
              key: `${key}_fuel_consumption_running`,
              label: `${capitalizeFirstLetter(key)} Fuel Used vs Running Hours`,
            });
          });
        } else if (item.type === EDeviceType.AE) {
          res.push({
            key: 'ae_fuel_consumption',
            label: 'AE Fuel Consumption',
          });
        }
      });
    }

    return res;
  }, [asset.assetType, devices]);

  const CheckboxRender = () => {
    return (
      <>
        <div style={{ color: '#919191' }}>Choose report to download</div>
        {options && options.length && (
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={handleChange}
            value={selectedItems}
          >
            {options.map((item, idx) => (
              <Checkbox
                style={{
                  margin: 0,
                  width: '100%',
                  padding: '5px 0',
                  borderBottom: '1px solid #f1f1f1',
                }}
                key={idx}
                value={item.key}
              >
                {item.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        )}
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <Tooltip
            style={{ flex: 1 }}
            title='Select one or two report to download'
          >
            <Button
              style={{ flex: 1 }}
              onClick={() => handleDownload()}
              disabled={props.loading || !selectedItems.length}
              loading={props.loading}
              type='primary'
            >
              Download {selectedItems.length > 0 && `(${selectedItems.length})`}
            </Button>
          </Tooltip>
          <Button
            style={{ flex: 1 }}
            onClick={() => handleDownload(true)}
            disabled={props.loading}
            loading={props.loading}
            type='primary'
          >
            Download All
          </Button>
        </div>
      </>
    );
  };

  return (
    <Spin spinning={props.loading || loading}>
      <Popover
        content={<CheckboxRender />}
        trigger='hover'
        overlayInnerStyle={{
          width: 320,
        }}
        placement='bottomRight'
      >
        <Button
          disabled={props.loading || loading}
          loading={props.loading || loading}
          color='default'
          type='primary'
          icon={<DownloadOutlined />}
        >
          Download PDF
        </Button>
      </Popover>
    </Spin>
  );
};

export default PopupDownload;
