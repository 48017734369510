import { DeviceProperties, EDeviceType } from '../../device/types/device.type';
import {
  EReportDataInterval,
  ILogAE,
  ILogFlowmeter,
  ILogGPS,
  ILogReport,
  ILogRpm,
} from '../types/report-log.type';
import dayjs from 'dayjs';

export function convertData(data: any, convertToNull?: boolean) {
  if (typeof data === 'number' || data === 0) {
    return data.toFixed(2);
  } else {
    return '-';
    // return !convertToNull ? undefined : null;
  }
}

export function generateTimestampData(params: {
  startTime: number;
  endTime: number;
  intervalInSecond?: number;
  interval: EReportDataInterval;
}): number[] {
  const currInterval = params.intervalInSecond || 60; // in seconds
  const timestampArray: number[] = [];
  let currentTimestamp = params.startTime;

  while (currentTimestamp <= params.endTime) {
    timestampArray.push(currentTimestamp);
    currentTimestamp += currInterval;
  }
  return timestampArray;
}

export function generateTimestampDateData(params: {
  startTime: number;
  endTime: number;
  intervalInSecond?: number;
  interval: EReportDataInterval;
}): string[] {
  const currInterval = params.intervalInSecond || 60; // in seconds
  const timestampArray: string[] = [];
  let currentTimestamp = params.startTime;

  while (currentTimestamp <= params.endTime) {
    timestampArray.push(dayjs(currentTimestamp * 1000).format('D/MM/YY HH:mm'));
    currentTimestamp += currInterval;
  }
  return timestampArray;
}

export function toHHMMSS(seconds?: number | null) {
  if (!seconds) {
    return '00:00:00';
  }
  const SECONDS_PER_DAY = 86400;
  const HOURS_PER_DAY = 24;
  const days = Math.floor(seconds / SECONDS_PER_DAY);
  const remainderSeconds = seconds % SECONDS_PER_DAY;
  const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
  return hms.replace(/^(\d+)/, (h) =>
    `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0')
  );
}

export const toHHMM = (seconds: number) => {
  let hours: string | number = Math.floor(seconds / 3600);
  let minutes: string | number = Math.floor((seconds % 3600) / 60);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes;
};

function toDegreesMinutesAndSeconds(coordinate: any) {
  const absolute = Math.abs(coordinate);
  const degrees = Math.floor(absolute);
  const minutesNotTruncated = (absolute - degrees) * 60;
  const minutes = Math.floor(minutesNotTruncated);
  const seconds = Math.floor((minutesNotTruncated - minutes) * 60);

  return `${degrees}° ${minutes}' ${seconds}''`;
}

export const latToDMS = (value: any) => {
  if (!value) return '-';
  const cardinal = value > 0 ? 'N' : 'S';
  return `${toDegreesMinutesAndSeconds(value)} ${cardinal}`;
};

export const longToDMS = (value: any) => {
  if (!value) return '-';
  const cardinal = value > 0 ? 'E' : 'W';
  return `${toDegreesMinutesAndSeconds(value)} ${cardinal}`;
};

export const hChartConfig = {
  chart: {
    zoomType: 'xy',
    height: 480,
    width: null,
    reflow: true,
    animation: false,
  },
  title: {
    text: null,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      groupPadding: 0.2,
      pointPadding: 0,
      animation: false,
    },
  },
  tooltip: {
    shared: true,
  },
};

export const hColors = {
  orange: '#ffa216',
  lightOrange: '#FFC977',
  yellow: '#FFBC00',
  yellowOpacity: '#ffdc79',
  pink: '#F34584',
  red: '#FE4545',
  purple: '#6F57E9',
  purpleOpacity: 'rgb(111 87 233 / 80%)',
  teal: '#02CCCC',
  tealOpacity: 'rgb(2 204 204 / 80%)',
  green: '#35CD69',
  lightGreen: 'lime',
  darkBlue: '#163E8A',
  blue: '#1b71f3',
  lightBlue: '#6BA6FF',
  black: '#000000',
  lightBlack: 'rgba(0,0,0,.1)',
  gray: '#808080',
  limeYellow: '#e2e600',
};

export const zoneChartType = ['line', 'spline', 'areaspline', 'arealine'];

interface IDataZone {
  data: any[];
  color: string;
  type: string;
}

export function hexToRgba(hex: string, alpha: number) {
  let rgba = `rgba(0,0,0,${alpha})`;
  if (hex.includes('#')) {
    // Remove any leading '#' if present
    hex = hex.replace(/^#/, '');

    // Parse the hexadecimal values for Red, Green, and Blue
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Ensure alpha is a valid value between 0 and 1
    alpha = Math.min(1, Math.max(0, alpha));

    // Create the RGBA string
    rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else if (hex.includes('rgb')) {
    rgba = hex;
  }
  return rgba;
}

export function generateSeriesDataZone(params: IDataZone) {
  return params.data.reduce((nullIndices, currentValue, index) => {
    if (currentValue === null) {
      if (nullIndices.length === 0) {
        nullIndices.push({ value: index - 1 < 0 ? 0 : index - 1 });
      } else {
        if (index - nullIndices[nullIndices.length - 1].value > 1) {
          nullIndices.push({ value: index - 1 });
        }
      }

      nullIndices.push({
        value: index,
        color: params.color ? hexToRgba(params.color, 0.6) : '#000000',
        dashStyle: 'shortdot',
        fillColor:
          params.type.includes('area') && params.color
            ? hexToRgba(params.color, 0.2)
            : '#000000',
      });
    }
    return nullIndices;
  }, []);
}

export function fillNullValues(arr: any[]) {
  return arr.reduce((result, currentValue, index, array) => {
    if (currentValue === null) {
      // Find the previous and next non-null values
      let prev = null;
      let next = null;

      for (let j = index - 1; j >= 0; j--) {
        if (array[j] !== null) {
          prev = array[j];
          break;
        }
      }

      for (let j = index + 1; j < array.length; j++) {
        if (array[j] !== null) {
          next = array[j];
          break;
        }
      }

      // If null is in the last position, fill it from the previous non-null value
      if (next === null) {
        result.push(prev);
      } else if (prev === null) {
        // If null is in the first position, fill it from the next non-null value
        result.push(next);
      } else {
        // Calculate the average and fill the null with it
        result.push((prev + next) / 2);
      }
    } else {
      result.push(currentValue);
    }
    return result;
  }, []);
}

export const toKM = (value: number) => {
  if (!value) {
    return 0;
  }
  return value / 1000;
};

export const toMiles = (value: number) => {
  if (!value) {
    return 0;
  }
  return value * 0.00062137;
};

export const toNM = (value: number) => {
  if (!value) {
    return 0;
  }
  return value * 0.00053996;
};

export const toKnot = (value: number) => {
  if (!value) {
    return 0;
  }
  return value / 1.852;
};

export function generateDataSeries(params: {
  timestamps: number[];
  key: string | string[];
  seriesOption: {
    name: string;
    type: string;
    yAxis: number;
    zIndex: number;
    marker?: any;
    lineWidth?: number;
    color?: string;
    fillColor?: string;
    lineColor?: string;
    tooltip?: any;
    fillOpacity?: number;
  };
  fm?: ILogFlowmeter;
  rpm?: ILogRpm;
  gps?: ILogGPS;
  ae?: ILogAE;
}) {
  const data: any[] = [];
  let obj: any;

  const { timestamps, key, seriesOption, fm, rpm, gps, ae } = params;

  if (fm && Object.keys(fm).length) {
    obj = fm as ILogFlowmeter;
  } else if (rpm && Object.keys(rpm).length) {
    obj = rpm as ILogRpm;
  } else if (gps && Object.keys(gps).length) {
    obj = gps as ILogGPS;
  } else if (ae && Object.keys(ae).length) {
    obj = ae as ILogAE;
  }

  const getNestedValue = (obj: any, keyPath: string | string[]) => {
    if (typeof keyPath === 'string') {
      return obj[keyPath];
    }
    return keyPath.reduce((prev, curr) => (prev ? prev[curr] : null), obj);
  };

  timestamps.forEach((timestamp) => {
    const objTimestamp = Object.keys(obj).find(
      (item) => Number(item) === timestamp
    );
    if (objTimestamp && obj && key) {
      data.push(getNestedValue(obj[objTimestamp], key));
    }
  });

  return {
    ...seriesOption,
    fillColor: seriesOption.fillColor || undefined,
    data: data,
    marker: seriesOption.marker || {
      enabled: false,
    },
    lineWidth: seriesOption.lineWidth || 2,
  };
}

export function generateDataSeriesTable(params: {
  timestamps: number[];
  key: string | string[];
  fm?: ILogFlowmeter;
  rpm?: ILogRpm;
  gps?: ILogGPS;
  ae?: ILogAE;
}) {
  const data: any[] = [];
  let obj: any;

  const { timestamps, key, fm, rpm, gps, ae } = params;

  if (fm && Object.keys(fm).length) {
    obj = fm as ILogFlowmeter;
  } else if (rpm && Object.keys(rpm).length) {
    obj = rpm as ILogRpm;
  } else if (gps && Object.keys(gps).length) {
    obj = gps as ILogGPS;
  } else if (ae && Object.keys(ae).length) {
    obj = ae as ILogAE;
  }

  const getNestedValue = (obj: any, keyPath: string | string[]) => {
    if (typeof keyPath === 'string') {
      return obj[keyPath];
    }
    return keyPath.reduce((prev, curr) => (prev ? prev[curr] : null), obj);
  };

  timestamps.forEach((timestamp) => {
    const objTimestamp = Object.keys(obj).find(
      (item) => Number(item) === timestamp
    );
    if (objTimestamp && obj && key) {
      data.push(getNestedValue(obj[objTimestamp], key));
    } else {
      data.push(undefined);
    }
  });

  return data;
}

export function calculateGpsSummary(
  logs: ILogReport,
  units?: {
    distanceUnit: string;
    speedUnit: string;
  }
) {
  let totalSpeed = 0;
  let totalCruise = 0;
  let totalDistance = 0;
  let countData = 0;
  if (logs && logs.gps && Object.keys(logs.gps).length) {
    Object.values(logs.gps).forEach((item) => {
      countData += 1;
      totalSpeed += item.speed || 0;
      totalCruise += item.speed > 3 ? item.distance : 0;
      totalDistance += item.distance > 51.38 ? item.distance : 0;
    });

    if (!units) {
      totalCruise = toKM(totalCruise);
      totalDistance = toKM(totalDistance);
      totalSpeed = totalSpeed / countData;
    } else {
      if (units.distanceUnit === 'km') {
        totalCruise = toKM(totalCruise);
        totalDistance = toKM(totalDistance);
      } else if (units.distanceUnit === 'nm') {
        totalCruise = toNM(totalCruise);
        totalDistance = toNM(totalDistance);
      } else if (units.distanceUnit === 'miles') {
        totalCruise = toMiles(totalCruise);
        totalDistance = toMiles(totalDistance);
      }

      if (units.speedUnit === 'knot') {
        totalSpeed = toKnot(totalSpeed);
      } else {
        totalSpeed = totalSpeed;
      }
    }

    return {
      avgSpeed: totalSpeed.toFixed(2),
      totalCruise: totalCruise.toFixed(2),
      totalDistance: totalDistance.toFixed(2),
    };
  }

  return {
    avgSpeed: 0,
    totalCruise: 0,
    totalDistance: 0,
  };
}

export function generateDeviceOptions(devices: DeviceProperties[]) {
  const resDevices = devices.map((item) => ({
    uniqueId: item.uniqueId,
    deviceType: item.deviceType.name.toLocaleLowerCase(),
  }));

  let result: { type: EDeviceType; devices: string[] }[] = [];

  resDevices.forEach((item) => {
    if (item.deviceType === EDeviceType.FLOWMETER) {
      const found = result.find((item) => item.type === EDeviceType.FLOWMETER);
      if (item.uniqueId.includes('fm-1')) {
        if (!found) {
          result.push({
            type: EDeviceType.FLOWMETER,
            devices: ['port'],
          });
        } else {
          result = result.map((item) => {
            if (
              item.type === EDeviceType.FLOWMETER &&
              !item.devices.includes('port')
            ) {
              return {
                ...item,
                devices: [...item.devices, 'port'],
              };
            }
            return item;
          });
        }
      } else if (item.uniqueId.includes('fm-2')) {
        if (!found) {
          result.push({
            type: EDeviceType.FLOWMETER,
            devices: ['starboard'],
          });
        } else {
          result = result.map((item) => {
            if (
              item.type === EDeviceType.FLOWMETER &&
              !item.devices.includes('starboard')
            ) {
              return {
                ...item,
                devices: [...item.devices, 'starboard'],
              };
            }

            return item;
          });
        }
      } else if (item.uniqueId.includes('fm-3')) {
        if (!found) {
          result.push({
            type: EDeviceType.FLOWMETER,
            devices: ['center'],
          });
        } else {
          result = result.map((item) => {
            if (
              item.type === EDeviceType.FLOWMETER &&
              !item.devices.includes('center')
            ) {
              return {
                ...item,
                devices: [...item.devices, 'center'],
              };
            }

            return item;
          });
        }
      }
    } else if (item.deviceType === EDeviceType.AE) {
      let deviceId: string = '';
      const ids = item.uniqueId.split('-');
      if (ids && ids.length) {
        deviceId = ids[1] + '_' + ids[2];
      }
      const found = result.find((item) => item.type === EDeviceType.AE);
      if (deviceId) {
        if (!found) {
          result.push({
            type: EDeviceType.AE,
            devices: [deviceId],
          });
        } else {
          result = result.map((item) => {
            if (item.type === EDeviceType.FLOWMETER) {
              return {
                ...item,
                devices: [...item.devices, deviceId],
              };
            }

            return item;
          });
        }
      }
    } else if (item.deviceType === EDeviceType.RPM) {
      const found = result.find((item) => item.type === EDeviceType.RPM);
      if (item.uniqueId.includes('rpm-1')) {
        if (!found) {
          result.push({
            type: EDeviceType.RPM,
            devices: ['port'],
          });
        } else {
          result = result.map((item) => {
            if (item.type === EDeviceType.RPM) {
              return {
                ...item,
                devices: [...item.devices, 'port'],
              };
            }
            return item;
          });
        }
      } else if (item.uniqueId.includes('rpm-2')) {
        if (!found) {
          result.push({
            type: EDeviceType.RPM,
            devices: ['starboard'],
          });
        } else {
          result = result.map((item) => {
            if (item.type === EDeviceType.RPM) {
              return {
                ...item,
                devices: [...item.devices, 'starboard'],
              };
            }
            return item;
          });
        }
      }
    } else if (item.deviceType === EDeviceType.GPS) {
      result.push({
        type: EDeviceType.GPS,
        devices: ['gps'],
      });
    } else if (item.deviceType === EDeviceType.GATEWAY) {
      result.push({
        type: EDeviceType.GATEWAY,
        devices: ['gateway'],
      });
    }
  });

  return result;
}
