import { CompanyProperties } from '../../company/types/company.type';

export enum EAssetType {
  FMS = 'fms',
  EMS = 'ems',
  BUNKER = 'bunker',
  SINGLE_ENGINE = 'single_engine',
}

export enum EAssetCategory {
  SHIP = 'ship',
  CONTAINER = 'container',
  TUG_BOAT = 'tug_boat',
  FREIGHT = 'freight',
  TANK = 'tank',
}

export type AssetProperties = {
  assetId: string;
  name: string;
  status: string;
  assetType: EAssetType;
  assetCategory: EAssetCategory;
  picName?: string;
  picPhoneNumber?: string;
  gsmNumber?: string;
  imeiNumber?: string;
  satelliteId?: string;
  satelliteDeviceId?: string;
  satelliteOperator?: string;
  installationAt?: Date;
  companyId: string;
  createdByUserId: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  company?: CompanyProperties;
  imageLink?: string;
  devices?: any[];
  userCreator?: {
    name?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
  };
};

export const initialAsset: AssetProperties = {
  assetId: '',
  name: '',
  status: '',
  createdByUserId: '',
  companyId: '',
  assetType: EAssetType.BUNKER,
  assetCategory: EAssetCategory.SHIP,
};

export interface ICreateAsset {
  name: string;
  status: string;
  assetType: EAssetType;
  assetCategory: EAssetCategory;
  picName?: string;
  picPhoneNumber?: string;
  gsmNumber?: string;
  imeiNumber?: string;
  satelliteId?: string;
  satelliteDeviceId?: string;
  satelliteOperator?: string;
  installationAt?: Date;
  companyId: string;
  image: File[];
}
