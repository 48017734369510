import React, { useEffect, useState } from 'react';
import FilterReport from '../../../components/FilterReport';
import styled from 'styled-components';
import { Divider, Spin, Tabs, TabsProps, Tooltip } from 'antd';
import useGroup from '../../../zustand/useGroup';
import useReportLog from '../../../hooks/useReportLog';
import InfoDataCompletion from '../../../components/InfoDataCompletion';
import { EAssetType } from '../../asset/types/asset.type';
import ChartRpmFuelSpeedRunning from '../components/charts/chartRpmFuelSpeedRunning';
import ChartAERunningTime from '../components/charts/chartAERunningTime';
import ChartFuelCons from '../components/charts/chartFuelCons';
import PopupDownload from '../components/downloadConfiguration';
import TableReportLog from '../components/tableReportLog';
import SummaryTable from '../components/summaryTable';
import VesselTracking from '../components/vesselTracking';

const ReportLog = () => {
  const { asset } = useGroup();
  const { loadingLog: loading, logs } = useReportLog();
  const [dataTab, setDataTab] = useState<TabsProps['items']>([]);

  useEffect(() => {
    if (logs && Object.keys(logs).length) {
      let dataItems: TabsProps['items'] = [];
      const assetType = asset.assetType;
      if (assetType === EAssetType.FMS) {
        dataItems = [
          {
            key: '1',
            label: 'Summary',
            children: <SummaryTable />,
          },
          {
            key: '2',
            label: 'Data Log',
            children: <TableReportLog />,
          },
          {
            key: '3',
            label: 'Vessel Tracking',
            children: <VesselTracking />,
          },
          {
            key: '4',
            label:
              asset.assetType === EAssetType.EMS
                ? 'RPM Vs Speed Vs AE Running Time'
                : 'RPM Vs Fuel Cons. Vs Speed Vs AE Running Time',
            children: <ChartRpmFuelSpeedRunning />,
          },
          {
            key: '5',
            label: 'ME Fuel Cons.',
            children: <ChartFuelCons chartType='me' />,
            disabled: asset.assetType === EAssetType.EMS,
          },
          {
            key: '6',
            label: 'Port Fuel Cons. Vs Running Time',
            children: <ChartFuelCons chartType='port' />,
            disabled: asset.assetType === EAssetType.EMS,
          },
          {
            key: '7',
            label: 'Starboard Fuel Cons. Vs Running Time',
            children: <ChartFuelCons chartType='starboard' />,
            disabled: asset.assetType === EAssetType.EMS,
          },
          {
            key: '8',
            label: 'AE Fuel Cons',
            children: <ChartAERunningTime />,
          },
        ].filter((item) => !item.disabled);
      }

      setDataTab(dataItems);
    }
  }, [logs, asset]);

  const _renderDownloadButton = () => {
    return (
      <Tooltip title='Download report data as PDF'>
        <PopupDownload loading={loading} />
      </Tooltip>
    );
  };

  return (
    <ReportContainer>
      <InfoDataCompletion />
      <Divider style={{ marginTop: 0, marginBottom: 5 }} />
      <FilterReport downloadButton={_renderDownloadButton()} />
      <Spin
        spinning={loading}
        tip='Loading data report...'
        style={{ height: 700 }}
      >
        <Tabs
          style={{ marginTop: 10 }}
          type='card'
          size='small'
          defaultActiveKey='2'
          items={dataTab}
        />
      </Spin>
    </ReportContainer>
  );
};

export const ReportContainer = styled.div`
  /* background-color: var(--bg-gray); */
  padding: 15px;
  height: '100%';
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export default ReportLog;
