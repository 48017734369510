import {
  CompassOutlined,
  DatabaseOutlined,
  GlobalOutlined,
  HistoryOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  LikeOutlined,
  MinusOutlined,
  PieChartOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  Statistic,
  Card,
  Spin,
  TableProps,
  Table,
  Divider,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useReportLog from '../../../../hooks/useReportLog';
import styled from 'styled-components';
import {
  calculateGpsSummary,
  generateTimestampData,
  toHHMMSS,
} from '../helperFunction';
import useGroup from '../../../../zustand/useGroup';
import { EAssetType } from '../../../asset/types/asset.type';
import { EReportDataInterval } from '../../types/report-log.type';

interface IProps {
  defaultOpenBody?: string[];
  hideAccordion?: boolean;
}

const ReportSummary: React.FC<IProps> = (props) => {
  const { loadingLog: loading, logs } = useReportLog();
  const { filter, asset } = useGroup();
  const [showBody, setShowBody] = useState<{
    body1: boolean;
    body2: boolean;
    body3: boolean;
    body4: boolean;
  }>({
    body1: true,
    body2: true,
    body3: true,
    body4: true,
  });

  function handleShowBody(key: any) {
    const newShowBody: any = { ...showBody };
    newShowBody[key] = !newShowBody[key];
    setShowBody((current) => {
      return {
        ...current,
        ...newShowBody,
      };
    });
  }

  useEffect(() => {
    if (props.defaultOpenBody && props.defaultOpenBody.length) {
      props.defaultOpenBody.forEach((key) => {
        const currentShowBody: any = { ...showBody };
        if (currentShowBody.hasOwnProperty(key) && currentShowBody[key]) {
          setShowBody((current) => {
            return {
              ...current,
              [key]: false,
            };
          });
        }
      });
    }
  }, [props.defaultOpenBody]);

  const dataSource = useMemo(() => {
    let res: any = {
      totalEngineFuelCons: 0,
      totalAEFuelCons: 0,
      portRPM: 0,
      portRunningHour: 0,
      portTotalFuel: 0,
      starboardRPM: 0,
      starboardRunningHour: 0,
      starboardTotalFuel: 0,
      ae1RunningHour: 0,
      ae1TotalFuel: 0,
      ae2RunningHour: 0,
      ae2TotalFuel: 0,
      ae3RunningHour: 0,
      ae3TotalFuel: 0,
    };

    let intervalInSecond = 0;

    if (filter.interval === EReportDataInterval.minute) {
      intervalInSecond = 60;
    } else if (filter.interval === EReportDataInterval.hour) {
      intervalInSecond = 60 * 60;
    } else {
      intervalInSecond = 60 * 60 * 24;
    }

    const containerTimestamp = generateTimestampData({
      startTime: filter.startAt,
      endTime: filter.endAt,
      interval: filter.interval,
      intervalInSecond,
    });

    if (logs && Object.keys(logs).length) {
      const fm = logs['flowmeter'];
      const rpm = logs['rpm'];
      const ae = logs['ae'];
      if (fm && Object.keys(fm).length) {
        Object.keys(fm).forEach((key) => {
          if (
            fm &&
            fm[key] &&
            fm[key].deviceCalculation &&
            fm[key].deviceCalculation['portIn'] &&
            fm[key].deviceCalculation['starboardIn'] &&
            containerTimestamp.includes(Number(key))
          ) {
            res.portTotalFuel +=
              fm[key].deviceCalculation['portIn'].fuelCons || 0;
            res.starboardTotalFuel +=
              fm[key].deviceCalculation['starboardIn'].fuelCons || 0;
            res.totalEngineFuelCons +=
              fm[key].deviceCalculation['portIn'].fuelCons || 0;
            res.totalEngineFuelCons +=
              fm[key].deviceCalculation['starboardIn'].fuelCons || 0;
          }
        });
      }

      if (rpm && Object.keys(rpm).length) {
        Object.keys(rpm).forEach((key) => {
          if (
            rpm &&
            rpm[key] &&
            rpm[key]['port'] &&
            containerTimestamp.includes(Number(key))
          ) {
            res.portRPM += rpm[key]['port'].rpm || 0;
            res.starboardRPM += rpm[key]['starboard'].rpm || 0;
            res.portRunningHour += rpm[key]['port'].runningTime || 0;
            res.starboardRunningHour += rpm[key]['starboard'].runningTime || 0;
          }
        });
      }

      if (ae && Object.keys(ae).length) {
        Object.keys(ae).forEach((key) => {
          if (ae && ae[key]) {
            res.totalAEFuelCons += ae[key].totalFuelConsumption || 0;
          }

          if (ae && ae[key] && ae[key]['ae1']) {
            res.ae1RunningHour += ae[key]['ae1'].runningTime || 0;
            res.ae1TotalFuel += ae[key]['ae1'].fuelConsumption || 0;
          }
          if (ae && ae[key] && ae[key]['ae2']) {
            res.ae2RunningHour += ae[key]['ae2'].runningTime || 0;
            res.ae2TotalFuel += ae[key]['ae2'].fuelConsumption || 0;
          }
          if (ae && ae[key] && ae[key]['ae3']) {
            res.ae3RunningHour += ae[key]['ae3'].runningTime || 0;
            res.ae3TotalFuel += ae[key]['ae3'].fuelConsumption || 0;
          }
        });
      }
    }

    return {
      ...res,
      portRPM: res.portRPM / containerTimestamp.length,
      starboardRPM: res.starboardRPM / containerTimestamp.length,
    };
  }, [logs]);

  const generateSummary = () => {
    let currentSummary;
  };

  const gpsSummmary = useMemo(() => {
    return calculateGpsSummary(logs);
  }, [logs]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <CardInfoContainer
          onClick={() => handleShowBody('body1')}
          style={{ cursor: 'pointer' }}
        >
          <CardInfoTitle style={{ marginTop: 15 }}>
            <CompassOutlined style={{ fontSize: 12, marginRight: 5 }} /> GPS
            Summary
          </CardInfoTitle>
          {!props.hideAccordion && (
            <Accordion>
              {showBody.body1 ? <MinusOutlined /> : <PlusOutlined />}
            </Accordion>
          )}
        </CardInfoContainer>
        {showBody.body1 && (
          <CardInfoContainer>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Total Cruise</div>
                <div>{gpsSummmary.totalCruise} KM</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Average Speed</div>
                <div>{gpsSummmary.avgSpeed} KM/H</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Distance</div>
                <div>{gpsSummmary.totalDistance} KM</div>
              </CardInfo>
            </CardInfoWrapper>
          </CardInfoContainer>
        )}

        <Divider style={{ marginBottom: 0, marginTop: 15 }} />

        {![EAssetType.EMS].includes(asset.assetType) && (
          <React.Fragment>
            <CardInfoContainer
              style={{ cursor: 'pointer' }}
              onClick={() => handleShowBody('body2')}
            >
              <CardInfoTitle>
                <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />{' '}
                Fuel Consumption Summary
              </CardInfoTitle>
              {!props.hideAccordion && (
                <Accordion>
                  {showBody.body2 ? <MinusOutlined /> : <PlusOutlined />}
                </Accordion>
              )}
            </CardInfoContainer>
            {showBody.body2 && (
              <CardInfoContainer>
                <CardInfoWrapper>
                  <CardInfo size='small'>
                    <div>Total Engine Fuel Cons.</div>
                    <div>{dataSource.totalEngineFuelCons.toFixed(2)} Liter</div>
                  </CardInfo>
                </CardInfoWrapper>
                <CardInfoWrapper>
                  <CardInfo size='small'>
                    <div>Total AE Fuel Cons.</div>
                    <div>{dataSource.totalAEFuelCons.toFixed(2)} Liter</div>
                  </CardInfo>
                </CardInfoWrapper>
                <CardInfoWrapper>
                  <CardInfo size='small'>
                    <div>Total Fuel Cons.</div>
                    <div>
                      {(
                        dataSource.totalEngineFuelCons +
                        dataSource.totalAEFuelCons
                      ).toFixed(2)}{' '}
                      Liter
                    </div>
                  </CardInfo>
                </CardInfoWrapper>
              </CardInfoContainer>
            )}
            <Divider style={{ marginBottom: 0, marginTop: 15 }} />
          </React.Fragment>
        )}

        <CardInfoContainer
          style={{ cursor: 'pointer' }}
          onClick={() => handleShowBody('body3')}
        >
          <CardInfoTitle>
            <HistoryOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Engine Running Time
          </CardInfoTitle>
          <CardInfoTitle>
            <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Engine Fuel Consumption
          </CardInfoTitle>
          <CardInfoTitle>
            <GlobalOutlined style={{ fontSize: 12, marginRight: 5 }} />
            Engine Speed
          </CardInfoTitle>
          {!props.hideAccordion && (
            <Accordion>
              {showBody.body3 ? <MinusOutlined /> : <PlusOutlined />}
            </Accordion>
          )}
        </CardInfoContainer>
        {showBody.body3 && (
          <CardInfoContainer>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>{toHHMMSS(dataSource.portRunningHour)}</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>{toHHMMSS(dataSource.starboardRunningHour)}</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>{dataSource.portTotalFuel.toFixed(2)} Liter</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>{dataSource.starboardTotalFuel.toFixed(2)} Liter</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>Port</div>
                <div>{dataSource.portRPM.toFixed(2)} RPM</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>Starboard</div>
                <div>{dataSource.starboardRPM.toFixed(2)} RPM</div>
              </CardInfo>
            </CardInfoWrapper>
          </CardInfoContainer>
        )}

        <Divider style={{ marginBottom: 0, marginTop: 15 }} />

        <CardInfoContainer
          style={{ cursor: 'pointer' }}
          onClick={() => handleShowBody('body4')}
        >
          <CardInfoTitle>
            <HistoryOutlined style={{ fontSize: 12, marginRight: 5 }} />
            AE Running Time
          </CardInfoTitle>
          <CardInfoTitle>
            <DatabaseOutlined style={{ fontSize: 12, marginRight: 5 }} />
            AE Fuel Consumption
          </CardInfoTitle>
          {!props.hideAccordion && (
            <Accordion>
              {showBody.body4 ? <MinusOutlined /> : <PlusOutlined />}
            </Accordion>
          )}
        </CardInfoContainer>
        {showBody.body4 && (
          <CardInfoContainer>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>AE 1</div>
                <div>{toHHMMSS(dataSource.ae1RunningHour)}</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 2</div>
                <div>{toHHMMSS(dataSource.ae2RunningHour)}</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 3</div>
                <div>{toHHMMSS(dataSource.ae3RunningHour)}</div>
              </CardInfo>
            </CardInfoWrapper>
            <CardInfoWrapper>
              <CardInfo size='small'>
                <div>AE 1</div>
                <div>{dataSource.ae1TotalFuel.toFixed(2)} Liter</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 2</div>
                <div>{dataSource.ae2TotalFuel.toFixed(2)} Liter</div>
              </CardInfo>
              <CardInfo size='small'>
                <div>AE 3</div>
                <div>{dataSource.ae3TotalFuel.toFixed(2)} Liter</div>
              </CardInfo>
            </CardInfoWrapper>
          </CardInfoContainer>
        )}
      </Spin>
    </React.Fragment>
  );
};

const CardInfo = styled(Card)`
  background: var(--gray-2);
  .ant-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;

    div {
      :first-child {
        opacity: 0.7;
      }
      :last-child {
        font-weight: bold;
      }
    }
  }
  .ant-card-body::before,
  .ant-card-body::after {
    display: none;
  }
`;

const Accordion = styled.div`
  margin-top: 15px;
  grid-column: 4;
`;

const CardInfoTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
`;

const CardInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 40px;
  gap: 20px;
`;

const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Value = styled.div`
  font-size: 14px;
`;

const Label = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;

export default ReportSummary;
